import React from 'react';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

import styles from './styles.scss';

library.add(
  faCheckCircle
);


/**
 * A Component that displays a progress circle that
 * fills in a given percentage
 * @param {object} props The component props
 * @param {string} [props.emptyColor] The color of the empty ring
 * @param {string} [props.fillColor] The color of the filled-in progress ring
 * @param {number} [props.fontSize] The font size of the text that displays the % in the middle; defaults to 12
 * @param {number} props.percentage The percentage completed; defaults to 0
 * @param {number} [props.size] The size (width/height) of the circle; defaults to 200
 * @param {number} [props.strokeWidth] The width of the stroke of the circle; defaults to 10
 */
const ProgressCircle = props => {
  const { emptyColor, fillColor, fontSize, percentage, size, strokeWidth } = props;
  const center = size / 2;
  const radius = center - strokeWidth;
  const circumference = Math.PI * (radius * 2);

  // Calculate how much of the circumference still needs
  // to be filled in
  const amountLeftToFill = ((100 - percentage) / 100) * circumference;

  // Styles
  const progressCircleStyle = { strokeDashoffset: amountLeftToFill };
  const emptyCircleStyle = emptyColor ? { stroke: emptyColor } : null;
  if (fillColor) {
    progressCircleStyle.stroke = fillColor;
  }

  // Percentage text / completed check
  const text = percentage < 100 ? <span className={styles.percentageText} style={{fontSize: `${fontSize}px`}}>{percentage}%</span> : null;
  const check = percentage === 100 ? <FontAwesomeIcon icon={faCheckCircle} className={styles.check} style={{fontSize: `${size / 3}px`}} /> : null;

  return (
    <div className={styles.progressCircle} style={{height: `${size}px`, width: `${size}px`}}>
      <svg width={size} height={size} viewport={`0 0 ${center} ${center}`} version="1.1" xmlns="http://www.w3.org/2000/svg">
        <circle
          className={styles.circle}
          cx={center}
          cy={center}
          fill="transparent"
          r={radius}
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset="0"
          style={emptyCircleStyle}>
        </circle>
        <circle
          className={`${styles.circle} ${styles.progress}`}
          cx={center}
          cy={center}
          fill="transparent"
          r={radius}
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset="0"
          style={progressCircleStyle}>
        </circle>
      </svg>
      {text}
      {check}
    </div>
  );
};

ProgressCircle.defaultProps = {
  fontSize: 12,
  percentage: 0,
  radius: 90,
  size: 200,
  strokeWidth: 10
};

export default ProgressCircle;
