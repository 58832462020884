import React from 'react';
import ToolboxIcon from '../ToolboxIcon';

import style from './style.scss';

export default class Toolbox extends React.Component {
  render() {
    const { tools } = this.props;
    return <div className={style.ToolboxContainer}>
      {tools.map((t, idx) => <ToolboxIcon
        key={idx}
        icon={t.icon}
        label={t.label}
        onClick={t.onClick}
        spin={t.spin}
      />)}
    </div>
  }
}