import { authedPostRequest, switchHash } from '../utils/request';
import 'whatwg-fetch';

export const fetchSample = hash => {
  const body = {
    ...switchHash(hash)
  };

  return authedPostRequest('sandbox/sample/download', { body })
}

export const fetchCrashDump = (platform, hash) => {
  const body = {
    ...switchHash(hash),
    platform
  };

  return authedPostRequest('sandbox/report/crashdump', { body });
}
 
export const fetchDroppedFile = (hash, platform, dropped) => {
  const body = {
    ...switchHash(hash),
    platform,
    dropped
  };

  return authedPostRequest('sandbox/report/dropped', { body });
}

export const generateDroppedFileUrl = (hash, platform, dropped) => {
  const body = {
    ...switchHash(hash),
    platform,
    dropped
  };

  return authedPostRequest('generate/url', { body });
}