import React from 'react';
import { RedocStandalone } from 'redoc';
import yamlFile from '../../../doc/api-doc.yml'

class ApiDoc extends React.Component {
  render() {
    return (
      <RedocStandalone
        specUrl={yamlFile}
        options={{
          nativeScrollbars: true,
          theme: { colors: { primary: { main: '#AB3839' } } },
        }}
      />
    );
  }
}

export default ApiDoc;
