import React from 'react';

import {
  Card, Button
} from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import style from './style.scss';

export default class DownloadFile extends React.Component {
  render() {
    const {
      icon,
      color,
      downloading,
      label,
      onClick
    } = this.props;

    return <Card body outline color={color} className={style.cardDownload}>
    <FontAwesomeIcon icon={icon} className={style[color]} />
    <Button
      disabled={downloading}
      onClick={onClick}
      color={color}
    >
      { downloading ? <FontAwesomeIcon icon={faSpinner} spin /> : label }
    </Button>
  </Card>;
  }
}