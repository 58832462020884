import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  Container,
  Form,
  FormGroup,
  Label,
  Input } from 'reactstrap';

import { fetchGroupInfo } from '../../services/users';
import { handleError } from '../../services/errorHandler';

import {
  statusSuccess
  } from '../../constants/api';

class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      groupDescription: '',
      submissionsLimit: ''
    }
  }

  componentDidMount() {
    const { group_name } = this.props.auth;

    fetchGroupInfo(group_name).then(res => {
      if(res.status === statusSuccess && res.data[group_name]) {
        const { description, max_submissions } = res.data[group_name];
        this.setState({
          groupDescription: description,
          submissionsLimit: max_submissions
        })
      } else {
        handleError('Error', 'Error retrieving user information');
      }
    })
  }

  render() {
    const { name, email, group_name } = this.props.auth;
    const { groupDescription, submissionsLimit } = this.state;

    return (
      <Container>
      <h3>User profile</h3>
      <Form>
        <FormGroup>
          <Label for="userName">Name</Label>
          <Input type="text" name="userName" id="userName" disabled value={name} />
        </FormGroup>
        <FormGroup>
          <Label for="userEmail">Email</Label>
          <Input type="text" name="userEmail" id="userEmail" disabled value={email} />
        </FormGroup>
        <FormGroup>
          <Label for="userGroup">Group name</Label>
          <Input type="text" name="userGroup" id="userGroup" disabled value={group_name} />
        </FormGroup>
        <FormGroup>
          <Label for="groupDescription">Group description</Label>
          <Input type="text" name="groupDescription" id="groupDescription" disabled value={groupDescription} />
        </FormGroup>
        <FormGroup>
          <Label for="submissionLimit">Submissions rate limit (per hour)</Label>
          <Input type="text" name="submissionLimit" id="submissionLimit" disabled value={submissionsLimit === 0 ? 'unlimited' : submissionsLimit} />
        </FormGroup>
      </Form>
      </Container>
    );
  }
};


export default connect(
  state => ({
    auth: state.auth
  })
)(Profile);
