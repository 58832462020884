const { REACT_APP_DEEPVIZ_ENV } = process.env;

export let oktaConfig;

if (REACT_APP_DEEPVIZ_ENV === 'production') {
  oktaConfig = {
    backendEntryPoint: {
    login: 'https://api.prod.deepviz.com/login',
    logout: 'https://api.prod.deepviz.com/logout'
    }
  }
} else if (REACT_APP_DEEPVIZ_ENV === 'stage') {
  oktaConfig = {
    backendEntryPoint: {
    login: 'https://api.stage.deepviz.com/login',
    logout: 'https://api.stage.deepviz.com/logout'
    }
  }
} else {
  oktaConfig = {
    backendEntryPoint: {
    login: 'https://api.dev.deepviz.com/login',
    logout: 'https://api.dev.deepviz.com/logout'
    }
  }
}