import React from 'react';
import { Table } from 'reactstrap';
import CodeHighlighter from '../../../../../components/CodeHighlighter';

import FlagIcon from '../../../../../components/FlagIcon';
import ReportSection from '../../../../../components/ReportSection';

import style from '../../style.scss';

export default class WsfStatic10 extends React.Component {
  renderScript = (s, idx) => {
    const { language, script } = s;

    return <ReportSection title={language} key={idx}>
      {script.map((scr, idx) => {
        const { obfuscation, code, shellcode, exceptions, assembly_list } = scr;
        
        return <Table key={idx} size="sm" responsive bordered striped style={{tableLayout:"fixed", width: "100%"}}>
          <tbody>
          {!!obfuscation && <tr>
            <td className={style.title}>Obfuscation</td>
            <td><FlagIcon flag={obfuscation} /></td>
          </tr>}
          {!!code && <tr>
            <td className={style.title}>Code</td>
            <td><CodeHighlighter code={code} base64={true} /></td>
          </tr>}
          {!!shellcode && <tr>
            <td className={style.title}>Shellcode</td>
            <td><FlagIcon flag={shellcode} /></td>
          </tr>}
          {!!exceptions && !!exceptions.length && <tr>
            <td className={style.title}>Exceptions</td>
            <td>{exceptions.map((exception, idx) => {
              return <p key={idx} className="mb-0">{exception}</p>
            })}</td>
          </tr>}
          {!!assembly_list && !!assembly_list.length && <tr>
            <td className={style.title}>Assembly list</td>
            <td>{assembly_list.map((assembly, idx) => <CodeHighlighter key={idx} code={assembly} />)}</td>
          </tr>}
          </tbody>
        </Table>
      })}
    </ReportSection>
  }
      
  render() {
    return this.props.data.map(((script, idx) => this.renderScript(script, idx)));
  }
} 