export const completedStatus = 'completed';
export const pendingStatus = 'pending';
export const processingStatus = 'processing';
export const unsupportedStatus = 'unsupported';

export const submissionStatus = [
  completedStatus,
  pendingStatus,
  processingStatus,
  unsupportedStatus,
];

export const cleanStatus = 'clean';
export const maliciousStatus = 'malicious';
export const unknownStatus = 'unknown';

export const submissionResults = [
  maliciousStatus,
  cleanStatus,
  unknownStatus
];

export const supportedFormats = [
  'pe', 'ole', 'pdf', 'rtf', 'ooxml', 'ascii', 'data'
]

export const supportedExtensionsMapping = {
  pe: ['exe32', 'exe64', 'dll32', 'dll64'],
  ole: ['msi', 'doc', 'xls', 'ppt'],
  pdf: ['pdf'],
  rtf: ['rtf', 'js'],
  ooxml: ['xlsx', 'docx', 'pptx'],
  ascii: ['js', 'wsf'],
  data: ['rtf']
}

export const supportedExtensions = [
  'exe32', 'exe64', 'dll32', 'dll64', 'msi', 'wsf', 'js', 'rtf', 'doc', 'docm', 'xls', 'ppt', 'xlsx', 'docx', 'pptx', 'pdf', 'xlsm', 'xlsb'
]