import { createStore } from 'redux';
import { createBrowserHistory } from 'history';

import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web and AsyncStorage for react-native

import rootReducer from '../reducers/';

const persistConfig = {
  key: 'root',
  storage
}

export const history = createBrowserHistory();

const persistedReducer = persistReducer(persistConfig, rootReducer(history));

export default function configureStore(initialState) {
  const store = createStore(persistedReducer, initialState);
  let persistor = persistStore(store);

  return { store, persistor };
}
