import React from 'react';

import FullTitle from '../../../../../components/FullTitle';
import RtfStatic10 from './1.0';

export default class RtfStatic extends React.Component {
  render() {
    const { data, report_version } = this.props;

    if(data && (data.swf_list.length > 0 || data.ole_list.length > 0 || data.pe_list.length > 0)) {
      switch(report_version) {
        case '1.0':
        default:
          return <RtfStatic10 data={data} />;
      }
    } else {
      return <FullTitle title="No data available" />;
    }
  }
}