/* eslint-disable no-mixed-operators */
import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'underscore';
import download from 'downloadjs';
import classnames from 'classnames';
import prettyMs from 'pretty-ms';
import merge from 'lodash.merge';
import { scroller } from 'react-scroll'
import SparkMD5 from 'spark-md5';
import base64 from 'base-64';

import { Modal, ModalHeader, ModalBody, ModalFooter, Button,
    Table, TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';

import ActivityBadge from '../../../../../components/ActivityBadge';
import ConnectionEventIcon from '../../../../../components/ConnectionEventIcon';
import { processActivityToTag, NoStringsExtracted } from '../../../../../constants/sandbox';
import TitleBanner from '../../../../../components/TitleBanner';
import TCPConnectionRows from '../../../../../components/TCPConnectionRows_1.1';
import UDPConnectionRows from '../../../../../components/UDPConnectionRows_1.1';
import Registry from './registry';

import { fetchStrings, fetchDataConnection } from '../../../../../services/sandbox';
import { fetchDroppedFile } from '../../../../../services/download';
import { setInjectedProcess } from '../../../../../actions/sandbox';

import { faStopCircle } from '@fortawesome/free-regular-svg-icons';
import { faPowerOff,
  faAlignJustify,
  faPlusSquare,
  faMinusSquare,
  faNetworkWired,
  faSyringe,
  faIndent,
  faSpinner,
  faFileDownload,
  faListUl
 } from '@fortawesome/free-solid-svg-icons';

import style from '../../style.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { statusSuccess } from '../../../../../constants/api';
import { handleError } from '../../../../../services/errorHandler';
import StringBadge from '../../../../../components/StringBadge';
import { setTimeout } from 'timers';
import { isEmptyArray } from '../../../../../utils/objects';

class DynamicProcess extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: SparkMD5.hash(this.props.path + this.props.pid),
      enlighted: false,
      hidden: true,
      activeTab: 0,
      activeTabInjected: 0,
      connectionDataModal: false,
      stringsDataModal: false,
      parsedStringDataModal: false,
      stringsInBufferDataModal: false,
      connectionData: '',
      stringsData: '',
      parsedStringData: {},
      stringsInBufferData: [],
      injected_threads_activities: {},
      isDownloading: false
    }
  }

  downloadSample = dropped => {
    const { mainHash, platform } = this.props;

    this.setState({
      isDownloading: true
    }, () => {
      fetchDroppedFile(mainHash, platform, dropped).then(response => {
        return response.blob();
      }).then(myBlob => download(myBlob, `${mainHash}.deepviz`, 'application/octet-stream')).finally(() => {
        this.setState({
          isDownloading: false
        })
      })
    })
  }

  componentDidMount() {
    const { injected_threads_activities } = this.props;
    
    if(injected_threads_activities.length > 0) {
      this.setState({
        injected_threads_activities: merge(...injected_threads_activities)
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { name } = this.state;
    const { currentInjectedProcess } = this.props;
    const { currentInjectedProcess: prevInjectedProcess } = prevProps;

    if(currentInjectedProcess && prevInjectedProcess !== currentInjectedProcess && currentInjectedProcess === name) {
      this.setState({
        enlighted: true,
        hidden: false
      }, () => {
        setTimeout(() => {
          this.props.setInjectedProcess(null);
          this.setState({
            enlighted: false
          })
        }, 2000);
      })
    }
  }

  toggleConnectionDataModal = () => {
    this.setState(prevState => ({
      connectionDataModal: !prevState.connectionDataModal
    }));
  }

  toggleStringsModal = () => {
    this.setState(prevState => ({
      stringsDataModal: !prevState.stringsDataModal
    }));
  }

  toggleParsedStringModal = () => {
    this.setState(prevState => ({
      parsedStringDataModal: !prevState.parsedStringDataModal
    }));
  }

  toggleStringsInBufferModal = () => {
    this.setState(prevState => ({
      stringsInBufferDataModal: !prevState.stringsInBufferDataModal
    }));
  }

  renderConnectionDataModal = () => {
    const {connectionDataModal, connectionData} = this.state;

    return <Modal isOpen={connectionDataModal} toggle={this.toggleConnectionDataModal} size='lg'>
      <ModalHeader toggle={this.toggleConnectionDataModal}>Data Connection</ModalHeader>
      <ModalBody>
        <textarea readOnly className={style.stringsContainer} defaultValue={base64.decode(connectionData).replace(/\\n/g, '<br />')}></textarea>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={this.toggleConnectionDataModal}>Close</Button>
      </ModalFooter>
    </Modal>
  }

  renderStringsDataModal = () => {
    const {stringsDataModal, stringsData} = this.state;

    return <Modal isOpen={stringsDataModal} toggle={this.toggleStringsModal} size='xl'>
      <ModalHeader toggle={this.toggleStringsModal}>Strings</ModalHeader>
      <ModalBody>
        <textarea readOnly className={style.stringsContainer} defaultValue={base64.decode(stringsData).replace(/\\n/g, '<br />')}></textarea>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={this.toggleStringsModal}>Close</Button>
      </ModalFooter>
    </Modal>
  }

  renderParsedStringsDataModal = () => {
    const {parsedStringDataModal, parsedStringData} = this.state;

    return <Modal isOpen={parsedStringDataModal} toggle={this.toggleParsedStringModal} size='xl'>
      <ModalHeader toggle={this.toggleParsedStringModal}>Extracted strings</ModalHeader>
      <ModalBody className={style.parsedStringContainer}>
      {typeof parsedStringData === 'string'? <p className={style.extractedStringElement}>{parsedStringData}</p> :
        Object.entries(parsedStringData).map((strings, idx) => {
          return _.isEmpty(strings[1]) ? null :
          <div key={idx} className="mb-3">
            <p className={style.extractedStringTitle}>{strings[0]}</p>
            {strings[1].map((s, idx) => <p className={style.extractedStringElement} key={idx}>{s}</p>)}
          </div>
        })
      }
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={this.toggleParsedStringModal}>Close</Button>
      </ModalFooter>
    </Modal>
  }

  renderStringsInBufferDataModal = () => {
    const {stringsInBufferDataModal, stringsInBufferData} = this.state;

    return <Modal isOpen={stringsInBufferDataModal} toggle={this.toggleStringsInBufferModal} size='xl'>
      <ModalHeader toggle={this.toggleStringsInBufferModal}>Strings in Buffer</ModalHeader>
      <ModalBody className={style.parsedStringContainer}>
        <textarea readOnly className={style.stringsContainer} defaultValue={stringsInBufferData.join('\n')}></textarea>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={this.toggleStringsInBufferModal}>Close</Button>
      </ModalFooter>
    </Modal>
  }

  toggleTab = tab => {
    if (this.state.activeTab !== tab) {
      this.setState({
         activeTab: tab
      });
    }
  }

  toggleTabInjected = tab => {
    if (this.state.activeTabInjected !== tab) {
      this.setState({
        activeTabInjected: tab
      })
    }
  }

  toggleVisibility = () => {
    this.setState(prevState => ({
      hidden: !prevState.hidden
    }));
  }

  renderHashes = hash => <Table size="sm" responsive bordered striped>
    <tbody>
    {Object.entries(hash).map((h, idx) => <tr key={idx}>
      <td className={style.title}>{h[0]}</td>
      <td>{h[1]}</td>
    </tr>)}
    </tbody>
  </Table>

  renderProcessActivities = (activities, process_information = {}, isInjected = null) => {
    const activeTab = isInjected ? this.state.activeTabInjected : this.state.activeTab;
    const toggleTab = isInjected ? this.toggleTabInjected : this.toggleTab;
    const activitiesLength = activities ? Object.entries(activities).length : 0;
  
    // if there is injected_threads_activities and there is not injected_code info, we have to add the empty element to print the tab anyway
    // but only if we are not printing the injected stuff already!
    if (!_.isEmpty(this.state.injected_threads_activities) && !('injected_code' in process_information) && !isInjected) {
      process_information.injected_code = [];
    }

    return <div>
      {isInjected && <TitleBanner level='danger' icon={faSyringe} marginBottom={true}>Injected Thread activities</TitleBanner>}
      <Nav tabs className={isInjected ? style.injectedThreadActivitiesNavTabs : null}>
        {Object.entries(activities).map((activity, inner_idx) => {
          return <NavItem key={inner_idx}>
            <NavLink
              className={classnames(
                style.dynamicTabs,
                { 'font-weight-bold': activeTab === inner_idx, active: activeTab === inner_idx },
                isInjected && activeTab === inner_idx ? style.injectedThreadActivitiesNav : null
                )}
              onClick={() => { toggleTab(inner_idx); }}
            >
              {activity[0].replace(/_/g, " ")}
            </NavLink>
          </NavItem>
        })}
        {Object.entries(process_information).map((information, inner_idx) => {
          //We have to shift this tabs after the activities' ones
          inner_idx = inner_idx + activitiesLength;
          
          return <NavItem key={inner_idx + activitiesLength}>
            <NavLink
              className={classnames(
                style.dynamicTabs,
                { 'font-weight-bold': activeTab === inner_idx, active: activeTab === inner_idx },
                style.infoTabs
              )}
              onClick={() => { toggleTab(inner_idx); }}
            >
              {information[0].replace(/_/g, " ")}
            </NavLink>
          </NavItem>
        })}
      </Nav>
      <TabContent activeTab={activeTab}>
        {Object.entries(activities).map((activity, inner_idx) =>
          <TabPane tabId={inner_idx} key={inner_idx} className={classnames(style.dynamicTabsContainer, isInjected ? style.injectedThreadActivitiesContent : null)}>
            <Row>
              <Col sm="12">
                {this.renderProcessAcitivityContent(activity)}
              </Col>
            </Row>
          </TabPane>
        )}
        {Object.entries(process_information).map((information, inner_idx) =>
          <TabPane tabId={inner_idx + activitiesLength} key={inner_idx + activitiesLength} className={style.dynamicTabsContainer}>
            <Row>
              <Col sm="12">
                {this.renderProcessAcitivityContent(information)}
              </Col>
            </Row>
          </TabPane>
        )}
      </TabContent>
    </div>
  }
  

  renderProcessAcitivityContent = activity => {
    switch(activity[0]) {
      case 'network':
        return this.renderNetwork(activity[1]);
      case 'code_injection':
        return this.renderCodeInjection(activity[1]);
      case 'dynamic_imported_function':
        return this.renderDynamicImportedFunction(activity[1]);
      case 'registry':
        return this.renderRegistry(activity[1]);
      case 'mutex':
        return this.renderMutex(activity[1]);
      case 'hook':
        return this.renderHook(activity[1]);
      case 'process':
        return this.renderProcess(activity[1]);
      case 'file_system':
        return this.renderFileSystem(activity[1]);
      case 'load_image':
        return this.renderLoadImage(activity[1]);
      case 'shutdown':
        return this.renderShutdown(activity[1]);
      case 'sleep':
        return this.renderSleep(activity[1]);
      case 'raw_disk_access':
        return this.renderRawDiskAccess(activity[1]);
      case 'injected_code':
        return this.renderInjectedCode(activity[1]);
      case 'tid':
        return this.renderTID(activity[1]);
      default:
        return null;
    }
  }

  renderTID = tid => <Table size="sm" responsive bordered striped>
    <tbody>
      <tr>
        <td>Thread ID: {tid}</td>
      </tr>
    </tbody>
  </Table>;

  renderInjectedCode = injectedCode => <>
    {injectedCode.length > 0 && <Table size="sm" responsive bordered striped>
      <thead>
        <tr>
          <td className={style.title} style={{ width: '50px'}}>Strings</td>
          <td className={style.title}>Virtual Address</td>
          <td className={style.title}>Code Size</td>
        </tr>
      </thead>
      <tbody>
        {injectedCode.map((inj, idx) => <tr key={idx}>
          <td className="centeredCell">
            <FontAwesomeIcon
              title={inj.md5_strings ? 'Click to view strings' : 'No strings available'}
              className={inj.md5_strings ? 'cursorPointer' : 'cursorNotAllowed'}
              icon={faAlignJustify}
              onClick={inj.md5_strings ? () => { this.loadStrings(inj.md5_strings) } : () => {}}
            />
            {!!inj.md5_strings && !!this.props.strings[inj.md5_strings] && <FontAwesomeIcon
              title={'Click to view extracted strings'}
              className={style.extractedString}
              icon={faIndent}
              onClick={() => {this.unwrapExtractedString(this.props.strings[inj.md5_strings].extracted_strings)}}
            />}
          </td>
          <td>{inj.virtual_address}</td>
          <td>{inj.code_size}</td>
        </tr>)}
      </tbody>
    </Table>}
    {!_.isEmpty(this.state.injected_threads_activities) && this.renderProcessActivities(this.state.injected_threads_activities, {}, true)}
  </>

  renderNetwork = networks => {
    const { connections, local_services, dns_lookups } = networks;

    return <>
      {!!connections && this.renderNetworkConnections(connections)}
      {!!local_services && this.renderNetworkLocalServices(local_services)}
      {!!dns_lookups && this.renderNetworkDnsLookups(dns_lookups)}
    </>
  }

  renderNetworkDnsLookups = dnsLookups => {
    return <>
      <TitleBanner icon={faNetworkWired}>DNS Lookups</TitleBanner>
      <Table size="sm" responsive bordered striped>
        <thead>
          <tr>
            <td className={style.title}>Host</td>
            <td className={style.title}>Alias chain</td>
            <td className={style.title}>IP Addresses</td>
          </tr>
        </thead>
        <tbody>
          {dnsLookups.map((lookup, idx) => (
            <tr key={idx}>
              <td>{lookup.host}</td>
              <td>
                {lookup.alias_chain.map((alias, alias_idx) => <span key={`${idx}_${alias_idx}`} style={{display: 'block'}}>{alias}</span>)}
              </td>
              <td>
                {lookup.ips.map((ip, ip_idx) => <span key={`${idx}_${ip_idx}`} style={{display: 'block'}}>{ip}</span>)}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  }

  renderNetworkLocalServices = local_services => {
    const { udp, tcp } = local_services;
    return <>
      <TitleBanner icon={faNetworkWired}>Local Services</TitleBanner>
      <Table size="sm" responsive bordered striped>
        <thead>
          <tr>
            <td className={style.title}>Type</td>
            <td className={style.title}>Local address</td>
            <td className={style.title}>Remote address</td>
            <td className={style.title}>State</td>
          </tr>
        </thead>
        <tbody>
          {!!tcp && tcp.map((t, idx) => <tr key={idx}>
            <td>TCP</td>
            <td>{t.local_address} : {t.local_port}</td>
            <td>{t.remote_address} : {t.remote_port}</td>
            <td>{t.state}</td>
          </tr>)}
          {!!udp && udp.map((t, idx) => <tr key={idx}>
            <td>UDP</td>
            <td>{t.local_address} : {t.local_port}</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
          </tr>)}
        </tbody>
      </Table>
    </>
  }

  renderNetworkConnections = connections => {
    const { tcp, udp, icmp } = connections;
    return <>
      {!!tcp && this.renderNetworkTcp(tcp)}
      {!!udp && this.renderNetworkUdp(udp)}
      {!!icmp && this.renderNetworkIcmp(icmp)}
    </>
  }

  loadDataConnection = (protocol, conn_id, event_type, event_id = null) => {
    const { mainHash, platform } = this.props;
    fetchDataConnection(mainHash, platform, protocol, conn_id, event_type, event_id).then(res => {
      if(res.status === statusSuccess) {
        if(_.isEmpty(res.data)) {
          handleError('Info', 'Connection data is empty', 'info');
        } else {
          this.setState({
            connectionDataModal: true,
            connectionData: res.data
          });
        }
      } else {
        handleError('Error', 'Error downloading connection data');
      }
    })
  }

  loadStrings = block => {
    const { mainHash, platform } = this.props;
     fetchStrings(mainHash, platform, block).then(res => {
      if(res.status === statusSuccess) {

        if(res.data === "") {
          res.data = base64.encode(NoStringsExtracted)
        }

        this.setState({
          stringsDataModal: true,
          stringsData: res.data
        });
      } else {
        handleError('Error', 'Error downloading strings data');
      }
    });
  }

  renderNetworkIcmp = icmp => <>
    <TitleBanner icon={faNetworkWired}>ICMP</TitleBanner>
    <Table size="sm" responsive bordered striped>
      <thead>
        <tr>
          <td className={style.title} style={{width: '1%'}}>Data</td>
          <td className={style.title} style={{width: '50px'}}>Strings</td>
          <td className={style.title}>Source IP</td>
          <td className={style.title}>Destination IP</td>
          <td className={style.title}>Event type</td>
          <td className={style.title}>Type</td>
          <td className={style.title}>Code</td>
          <td className={style.title}>Size</td>
        </tr>
      </thead>
      <tbody>
        {icmp && icmp.map((conn, conn_idx) => {
          const { source_ip, destination_ip, conn_id, data } = conn;

          //TODO: put arrows for send and receive in event_type field
          return data.map((event, event_idx) => <tr key={`${conn_idx}_${event_idx}`}>
            <td className="text-center"><FontAwesomeIcon icon={faAlignJustify} className='cursorPointer' onClick={() => this.loadDataConnection('icmp', conn_id, event.event_type, event.event_id)}/></td>
            <td className='centeredCell'>
                {event.extracted_strings && !isEmptyArray(event.extracted_strings) && <FontAwesomeIcon
                  title={'Click to view extracted strings'}
                  className={style.extractedString}
                  icon={faIndent}
                  onClick={() => { this.unwrapExtractedString(event.extracted_strings) }}
                />}
                <FontAwesomeIcon title="Click to show strings in buffer" icon={faListUl} className='cursorPointer' onClick={() => { this.showStringsInBuffer(event.strings_in_buffer); }} />
            </td>
            <td>{source_ip}</td>
            <td>{destination_ip}</td>
            <td><ConnectionEventIcon type={event.event_type} /></td>
            <td>{event.type}</td>
            <td>{event.code}</td>
            <td>{event.data_size}</td>
          </tr>)
        })}
      </tbody>
    </Table>
  </>

  renderNetworkUdp = udp => <>
    <TitleBanner icon={faNetworkWired}>UDP Connections</TitleBanner>
    <Table size="sm" responsive bordered striped>
      <thead>
        <tr>
          <td className={style.title}>Data</td>
          <td className={style.title}>Type</td>
          <td className={style.title}>Source IP</td>
          <td className={style.title}>Destination IP</td>
          <td className={style.title}>Size</td>
        </tr>
      </thead>
      <tbody>
          {udp.map((u, idx) => (
            <UDPConnectionRows
              key={idx}
              connection={u}
              loadDataConnection={this.loadDataConnection}
              unwrapExtractedString={this.unwrapExtractedString}
              showStringsInBuffer={this.showStringsInBuffer}
            />
          ))}
      </tbody>
    </Table>
  </>

  renderNetworkTcp = tcp => <>
    <TitleBanner icon={faNetworkWired}>TCP Connections</TitleBanner>
    <Table size="sm" responsive bordered striped>
      <thead>
        <tr>
          <td className={style.title}>Data</td>
          <td className={style.title}>Type</td>
          <td className={style.title}>Source IP</td>
          <td className={style.title}>Destination IP</td>
          <td className={style.title}>URL / Host</td>
          <td className={style.title}>Referer</td>
          <td className={style.title}>User agent</td>
          <td className={style.title}>Size</td>
        </tr>
      </thead>
      <tbody>
          {tcp.map((t, idx) => (
            <TCPConnectionRows
              key={idx}
              connection={t}
              loadDataConnection={this.loadDataConnection}
              unwrapExtractedString={this.unwrapExtractedString}
              showStringsInBuffer={this.showStringsInBuffer}
            />
          ))}
      </tbody>
    </Table>
  </>

  renderCodeInjection = codeInjections => <>
    {!!codeInjections.length && (
      <Table size="sm" responsive bordered striped>
        <thead>
          <tr>
            <td className={style.title} style={{width: '50px'}}>Strings</td>
            <td className={style.title}>Target</td>
            <td className={style.title}>Virtual address</td>
            <td className={style.title}>Code size</td>
          </tr>
        </thead>
        <tbody>
        {codeInjections.map((cj, idx) => {
          const hashInjectedProcess = SparkMD5.hash(cj.target_process_path + cj.target_pid);
          return <tr key={idx}>
            <td className='centeredCell'>
              <FontAwesomeIcon
                title={cj.md5_strings ? 'Click to view strings' : 'No strings available'}
                className={cj.md5_strings ? 'cursorPointer' : 'cursorNotAllowed'}
                icon={faAlignJustify}
                onClick={cj.md5_strings ? () => { this.loadStrings(cj.md5_strings) } : () => {}}
              />
              {!!cj.md5_strings && !!this.props.strings[cj.md5_strings] && <FontAwesomeIcon
                title={'Click to view extracted strings'}
                className={style.extractedString}
                icon={faIndent}
                onClick={() => {this.unwrapExtractedString(this.props.strings[cj.md5_strings].extracted_strings)}}
              />}
            </td>
            <td>
              <Button
                className="fakeLink"
                color="link"
                size="sm"
                onClick={() => {
                  this.props.setInjectedProcess(hashInjectedProcess);
                  scroller.scrollTo(hashInjectedProcess, { offset: -50, smooth: true });
                }}
              >
                {cj.target_process_path}
                {(cj.target_process_version || cj.target_pid || cj.target_tid) && ' ('}
                {cj.target_process_version && ` v. ${cj.target_process_version}`}
                {cj.target_pid && ` | PID: ${cj.target_pid}`}
                {!!cj.target_tid && ` | TID: ${cj.target_pid}`}
                {(cj.target_process_version || cj.target_pid || cj.target_tid) && ')'}
              </Button>
            </td>
            <td>{cj.virtual_address}</td>
            <td>{cj.code_size}</td>
          </tr>})}
        </tbody>
      </Table>
    )}
  </>

  renderDynamicImportedFunction = functions => functions.map((func, idx) => <Table size="sm" responsive bordered striped key={idx}>
    <thead>
      <tr>
        <td className={style.title} style={{width: '250px'}}>Module</td>
        <td className={style.title}style={{width: 'auto', 'maxWidth': 'auto'}}>Api</td>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>{func.module}</td>
        <td>{func.functions.map((f, idx) => <p className='mb-0' key={idx}>
          {f.name ? `Name: ${f.name}` : `Ordinal: ${f.ordinal}`}  
        </p>)}
        </td>
      </tr>
    </tbody>
  </Table>);

  renderRegistry = registries =>
    Object.entries(registries).map((registry, idx) => <Registry data={registry} key={idx} />);
  

  renderMutex = mutexes => !!mutexes && <Table size="sm" responsive bordered striped>
    <tbody>
      {mutexes.map((mutex, idx) => <tr key={idx}>
        <td>{mutex.name}</td>
      </tr>)}
    </tbody>
  </Table>

  renderHook = hooks => !!hooks && <Table size="sm" responsive bordered striped>
    <thead>
      <tr>
        <td className={style.title}>Module</td>
        <td className={style.title}>Hooks</td>
      </tr>
    </thead>
    <tbody>
      {!!hooks.user && hooks.user.map((hook, idx) => <tr key={idx}>
        <td>{hook.module}</td>
        <td>
          {!!hook.api_list && <Table size="sm" responsive bordered>
            <thead>
              <tr>
                <td>API</td>
                <td>Destination module</td>
                <td>Type</td>
                <td>Address</td>
              </tr>
            </thead>
            <tbody>
              {hook.api_list.map((api, idx) => <tr key={idx}>
                <td>{api.name}</td>
                <td>{api.destination_module}</td>
                <td>{api.hook_type}</td>
                <td>{api.virtual_address}</td>
              </tr>)}
            </tbody>
          </Table>}
        </td>
      </tr>)}
    </tbody>
  </Table>

  renderProcess = processes => {
    const { terminate, create } = processes;
    return <>
      {!!create && <>
        <TitleBanner>Process creation</TitleBanner>
        <Table size="sm" responsive bordered striped>
          <thead>
            <tr>
              <td className={style.title}>Path</td>
              <td className={style.title}>Command line</td>
              <td className={style.title}>MD5</td>
            </tr>
          </thead>
          <tbody>
            {create.map((cr, idx) => <tr key={idx}>
              <td>{cr.path} {cr.version && `[v. ${cr.version}]`} {cr.pid && `(PID: ${cr.pid})`}</td>
              <td>{cr.command_line}</td>
              <td>{!!cr.hash && <Link to={`/sandbox/report/${cr.hash.sha512}/`}>{cr.hash.md5}</Link>}</td>
            </tr>)}
          </tbody>
        </Table>
      </>}
      {!!terminate && !!terminate.length &&
        <TitleBanner icon={faStopCircle} level='danger' marginBottom={true}>This process has terminated</TitleBanner>
      }
    </>
  }

  renderFileSystem = file_system => {
    const { create, write, read, delete: del, rename, hide } = file_system;

    return !!file_system && <Table size="sm" responsive bordered striped>
      <thead>
        <tr>
          <td className={style.title}>Type</td>
          <td className={style.title}>File type</td>
          <td className={style.title}>File path</td>
          <td className={style.title}>Hash</td>
          <td className={style.title} style={{width: '1%'}}>Download</td>
        </tr>
      </thead>
      <tbody>
        {!!create && create.map((fs, idx) => this.renderActivityRow(fs, 'CREATE', idx))}
        {!!write && write.map((fs, idx) => this.renderActivityRow(fs, 'WRITE', idx))}
        {!!read && read.map((fs, idx) => this.renderActivityRow(fs, 'READ', idx))}
        {!!del && del.map((fs, idx) => this.renderActivityRow(fs, 'DELETE', idx))}
        {!!rename && rename.map((fs, idx) => {          
          fs = {
            ...fs,
            file_path: <>
              <p className="mb-0"><strong>Old</strong>: {fs.file_path}</p>
              <p className="mb-0"><strong>New</strong>: {fs.new_file_path}</p>
            </>
          };
          return this.renderActivityRow(fs, 'RENAME', idx);
        })}
        {!!hide && hide.map((fs, idx) => this.renderActivityRow(fs, 'HIDE', idx))}
      </tbody>
    </Table>
  }

  renderLoadImage = loadImages => !!loadImages && <Table size="sm" responsive bordered striped>
    <thead>
      <tr>
        <td className={style.title}>Data</td>
        <td className={style.title}>Type</td>
        <td className={style.title}>File type</td>
        <td className={style.title}>File path</td>
        <td className={style.title}>Hash</td>
        <td className={style.title} style={{width: '1%'}}>Download</td>
      </tr>
    </thead>
    <tbody>
    {!!loadImages.kernel && loadImages.kernel.map((li, idx) => this.renderActivityRow(li, 'KERNEL', idx, true))}
    {!!loadImages.user && loadImages.user.map((li, idx) => this.renderActivityRow(li, 'USER', idx, true))}
    </tbody>
  </Table>

  renderSleep = sleeps => !!sleeps && <Table size="sm" responsive bordered striped>
    <thead>
      <tr>
        <td className={style.title}>Value</td>
      </tr>
    </thead>
    <tbody>
    {sleeps.map((sleep, idx) => 
      <tr key={idx}>
        <td>{prettyMs(sleep.value)}</td>
      </tr>
    )}
    </tbody>
  </Table>

  renderShutdown = shutdowns => !!shutdowns && <TitleBanner icon={faPowerOff} level='danger'>
    The process did {shutdowns.length} shutdown attempt{shutdowns.length > 1 ? 's' : ''}
  </TitleBanner>

  renderRawDiskAccess = accesses => <Table size="sm" responsive bordered striped>
    <thead>
      <tr>
        <td className={style.title}>Type</td>
        <td className={style.title}>Data</td>
        <td className={classnames(style.title, "hasToBeSingleLine")}>Size</td>
      </tr>
    </thead>
    <tbody>
    {!!accesses.mbr && accesses.mbr.map((access, idx) => 
      <tr key={idx}>
        <td><strong>MBR</strong></td>
        <td className="containsLongWords">{access.data}</td>
        <td className="hasToBeSingleLine">{access.data_size}</td>
      </tr>
    )}
    {!!accesses.vbr && accesses.vbr.map((access, idx) => 
      <tr key={idx}>
        <td><strong>VBR</strong></td>
        <td className="containsLongWords">{access.data}</td>
        <td className="hasToBeSingleLine">{access.data_size}</td>
      </tr>
    )}
    {!!accesses.generic_info && accesses.generic_info.map((access, idx) => 
      <tr key={idx}>
        <td><strong>GENERIC</strong></td>
        <td className="containsLongWords">{access.data}</td>
        <td className="hasToBeSingleLine">{access.data_size}</td>
      </tr>
    )}
    {!!accesses.disk_info && accesses.disk_info.map((access, idx) => 
      <tr key={idx}>
        <td><strong>Access to hard disk name</strong></td>
        <td className="containsLongWords">&nbsp;</td>
        <td className="hasToBeSingleLine">&nbsp;</td>
      </tr>
    )}
    </tbody>
  </Table>;

  renderActivityRow = (activity, type, idx, isloadImage = false) => {
    const isDropped = activity.hash && activity.hash.sha512 && _.findWhere(this.props.dropped, {sha512: activity.hash.sha512});
    const { isDownloading } = this.state;

    return <tr key={idx}>
      {isloadImage && <td style={{width: '1%'}} className='centeredCell'>
        <FontAwesomeIcon
          title={activity.md5_strings ? 'Click to view strings' : 'No strings available'}
          className={activity.md5_strings ? 'cursorPointer' : 'cursorNotAllowed'}
          icon={faAlignJustify}
          onClick={activity.md5_strings ? () => { this.loadStrings(activity.md5_strings) } : () => {}}
        />
      </td>}
      <td>{type}</td>
      <td>{activity.file_type}</td>
      <td>{activity.file_path}</td>
      <td>{activity.hash && activity.hash.md5}</td>
      <td className='text-center'>{isDropped ?
        isDownloading ? <FontAwesomeIcon icon={faSpinner} spin /> :
        <FontAwesomeIcon className='cursorPointer' icon={faFileDownload} onClick={() => { this.downloadSample(activity.hash.sha512) }}/> : null
      }</td>
    </tr>
  }

  renderActivityBadge = () => {
    const { process_activities, process_information } = this.props;
    const { injected_threads_activities } = this.state;

    // if there is no injected code in process info but we have activities from injected thread we have to add the badge anyway
    if (!_.isEmpty(injected_threads_activities) && !('injected_code' in process_information)) {
      process_information.injected_code = [];
    }
    const activitiesKeys = _.union(Object.keys(process_activities), Object.keys(injected_threads_activities));

    return <div className="float-right">
      {activitiesKeys.map((activity, idx) => <ActivityBadge value='unknown' label={processActivityToTag[activity]} key={idx} />)}
      {Object.entries(process_information).map((information, idx) => <ActivityBadge info={true} value='unknown' label={processActivityToTag[information[0]]} key={idx} />)}
    </div>
  }

  renderStringsBadge = (md5_strings, extracted_strings, emptyProcess) => <div className="float-right">
    <StringBadge onClick={() => this.loadStrings(md5_strings)} />
    { _.filter(extracted_strings, s => _.isArray(s) && !_.isEmpty(s)).length > 0 &&
      <StringBadge extracted={true} onClick={() => { this.unwrapExtractedString(extracted_strings) }} />}
    {!emptyProcess && <span className={style.badgeSeparator}></span>}
  </div>

  unwrapExtractedString = parsedStringData => {
    if(isEmptyArray(parsedStringData))
      parsedStringData = NoStringsExtracted;

    this.setState({
      parsedStringData,
      parsedStringDataModal: true
    })
  }

  showStringsInBuffer = strings => {
    this.setState({
      stringsInBufferData: strings,
      stringsInBufferDataModal: true
    })
  }

  render() {
    const { path, version, pid, hash, process_information, process_activities, md5_strings, strings } = this.props;
    const { hidden, injected_threads_activities, name, enlighted } = this.state;

    const emptyProcess = _.isEmpty(injected_threads_activities) && _.isEmpty(process_activities) && _.isEmpty(process_information);

    return <>
      <TitleBanner
        size='big'
        icon={hidden ? faPlusSquare : faMinusSquare}
        onClick={this.toggleVisibility}
        name={name}
        enlighted={enlighted}
      >
        <strong className="cursorPointer" onClick={this.toggleVisibility}>{path}</strong> <span style={{ zoom: '90%'}}>{version && `[v. ${version}]`} {pid && `(PID: ${pid})`}</span>
        {(!_.isEmpty(process_activities) || !_.isEmpty(process_information) || !_.isEmpty(injected_threads_activities)) && this.renderActivityBadge()}
        {!!md5_strings && this.renderStringsBadge(md5_strings, !!strings[md5_strings] && strings[md5_strings].extracted_strings, emptyProcess)}
      </TitleBanner>
      <div className={classnames({ 'd-none': hidden})}>
        {hash && this.renderHashes(hash)}
        {(!_.isEmpty(process_activities) || !_.isEmpty(process_information)) &&
            this.renderProcessActivities(process_activities, process_information, false)}
        { emptyProcess && <TitleBanner level='danger' marginBottom={true}>The process did not take any action</TitleBanner> }
      </div>
      <hr style={{ margin: '2em 0'}} />
      {this.renderConnectionDataModal()}
      {this.renderStringsDataModal()}
      {this.renderParsedStringsDataModal()}
      {this.renderStringsInBufferDataModal()}
    </>
  }
}

export default connect(
  state => ({
    currentInjectedProcess: state.sandbox.injectedProcess
  }),
  dispatch => ({
    setInjectedProcess: data => dispatch(setInjectedProcess(data))
  })
)(DynamicProcess);
