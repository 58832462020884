/**
 * The HTTP status codes from a request
 */
export const HttpStatusCodes = {

  // NOTE: Not all statuses are here, just some of
  // the common ones

  // Success
  OK: 200,
  Created: 201,
  Accepted: 202,
  MultiStatus: 208,

  // Redirect
  MultipleChoice: 300,
  MovedPermanently: 301,
  Found: 302,
  TemporaryRedirect: 307,
  PermanentRedirect: 308,

  // Client Errors
  BadRequest: 400,
  Unauthorized: 401,
  Forbidden: 403,
  NotFound: 404,
  MethodNotAllowed: 405,
  NotAcceptable: 406,
  RequestTimeout: 408,
  UnsupportedMediaType: 415,
  ImATeapot: 418,
  TooManyRequests: 429,

  // Server Errors
  InternalServerError: 500,
  NotImplemented: 501,
  BadGateway: 502,
  ServiceUnavailable: 503,
  GatewayTimeout: 504
};
