import React, { Component } from 'react';
import classnames from 'classnames';
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';

import { history } from '../../store/configureStore';
import FileUpload from '../../components/FileUpload';
import UrlUpload from '../../components/UrlUpload';
import { uploadNewSample } from '../../services/sandbox';


import style from './style.scss';
import { statusFailed, unauthorizedMessage } from '../../constants/api';

class SandboxHome extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);

    this.state = {
      activeTab: '1'
    }
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  onUploadError = err => {
    if(err.status === statusFailed && err.errmsg === unauthorizedMessage) {
      history.push('/auth/logout');
    }
  }

  render() {
    return <div className={style.sandboxHomeContainer}>
      <Row style={{margin: 'auto'}}>
        <Col sm={{ size: 8, offset: 2}} style={{ paddingTop: '1em'}}>
          <Nav tabs justified>
            <NavItem>
              <NavLink
                className={classnames(
                  { active: this.state.activeTab === '1' },
                  this.state.activeTab === '1' ? style.activeTab : '',
                  'cursorPointer',
                  style.tabSelector,
                  style.firstChild
                  )}
                onClick={() => { this.toggle('1'); }}
              >
                Upload a file
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames(
                  { active: this.state.activeTab === '2' },
                  this.state.activeTab === '2' ? style.activeTab : '',
                  'cursorPointer',
                  style.tabSelector,
                  style.lastChild
                  )}
                onClick={() => { this.toggle('2'); }}
              >
                Submit from URL
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={this.state.activeTab} className={style.homeContent}>
            <TabPane tabId="1">
              <Row>
                <Col>
                  <div style={{display: 'inline-flex'}}>
                    <FileUpload
                      ref={this.getFileUploadRef}
                      // accept=".xml"
                      chooseButtonText='Choose a Different File'
                      maxSize={64000000} // 64MB
                      onUpload={uploadNewSample}
                      onUploadError={this.onUploadError}
                    />
                  </div>
                  <div className={style.infoContainer}>
                    <p>Deepviz currently supports <strong>PE 32/64bit (.exe, .dll), PDF, OLE (.doc, .xls, .ppt), OOXML (.docx, .docm, .xlsx, .xlsm, .xlsb, .pptx), MSI, RTF, JavaScript, Windows Script file, Zip<span style={{color: 'red'}}> *</span></strong></p>
                    <p><span style={{color: 'red'}}>*</span>: ZIP files must contain only one file and it must be one of the supported file types. <br />
                    ZIP files can be password-protected with either "malware" or "infected" passwords.</p>
                  </div>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="2">
              <Row>
                <Col>
                  <UrlUpload />
                  <div className={style.infoContainer}>
                    <p>This allows you to submit a sample by providing an URL where the file is located. Deepviz downloads the sample from the provided URL and analyzes it.</p>
                  </div>
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </Col>
      </Row>
    </div>
  }
};

export default SandboxHome;
