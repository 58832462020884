import React, { Component } from 'react';
import { Button, InputGroup, Input, InputGroupAddon } from 'reactstrap';
import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { checkAnalysisInterval } from '../../constants/sandbox';
import { sampleReportStatus } from '../../services/sandbox';

import Badge from '../Badge';

import {
  statusSuccess,
  analysisCompleted,
  analysisFailed
 } from '../../constants/api';

import { sendUrl } from '../../services/sandbox';
import { pendingStatus } from '../../constants/submissions';

const INITIAL_STATE = {
  isUploading: false,
  inputValue: '',
  uploadError: null,
  hash: null,
  analysisStatus: false
}

export default class UrlUpload extends Component {
  constructor(props) {
    super(props);

    this.state = INITIAL_STATE;
    this.interval = null;

    this.resetComponent = this.resetComponent.bind();
  }

  componentWillUnmount() {
    if(this.interval)
      clearInterval(this.interval);
  }

  resetComponent = () => {
    if(this.interval)
      clearInterval(this.interval);
    
    this.setState(INITIAL_STATE);
  }

  checkInput = event => {
    if(event.key === 'Enter') this.sendUrl();
  }

  sendUrl = () => {
    const { inputValue: url, analysisStatus } = this.state;
    if(url) {
      this.setState({
        isUploading: true,
        inputValue: ''
      }, () => {
         sendUrl(url).then(res => {
          if(res.status === statusSuccess) {
            const hash = res.notes.sha512;

            this.setState({
              hash: hash,
              analysisStatus: pendingStatus,
              isUploading: false
            }, () => {
              this.interval = setInterval(() => {
                sampleReportStatus(hash).then(res => {
                  if(res.data) {
                    if(analysisStatus !== res.data.generic.info.analysis_status) {
                      this.setState({
                        analysisStatus: res.data.generic.info.analysis_status,
                      });
                    }
        
                    if(res.data.generic.info.analysis_status === analysisCompleted) {
                      clearInterval(this.interval);
                    }
                  } else {
                    this.setState({
                      analysisStatus: 'not found'
                    });
                  }
                });
              }, checkAnalysisInterval);
            });
          }
        }).finally(() => {
          this.setState({
            isUploading: false
          })
        })
      })
    }
  }

  updateInputValue = event => {
    this.setState({
      inputValue: event.target.value
    });
  }

  renderAnalysisStatus() {
    const { analysisStatus, hash } = this.state;

    return <div>
      <p>Upload is complete. Analysis status: <Badge value={analysisStatus} /></p>
      {
        [analysisCompleted, analysisFailed].indexOf(analysisStatus) > -1 ? 
        <p>You can find the report <strong><Link to={`/sandbox/report/${hash}/`}>here</Link></strong>.</p> :
        null
      }
      {
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <p>Do you want to upload a new sample? Click <a href="#" onClick={this.resetComponent}>here</a>.</p>
      }
    </div>;
  }

  render() {
    const { isUploading, analysisStatus } = this.state;

    return analysisStatus ? this.renderAnalysisStatus() : isUploading ? <div>
      <p>Sample is uploading, please wait...</p>
    </div> : <InputGroup style={{ alignItems: "center", width: '600px', margin: 'auto' }}>
    <Input
      placeholder="Insert an URL"
      onKeyUp={this.checkInput}
      onChange={evt => this.updateInputValue(evt)}
      value={this.state.inputValue}
    />
    <InputGroupAddon addonType="append">
      <Button color="secondary" onClick={this.sendUrl}>
        <FontAwesomeIcon icon={faCloudUploadAlt} />
      </Button>
    </InputGroupAddon>
  </InputGroup>
  }
}