import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import style from './style.scss';

export default class TitleBanner extends React.Component {
  render() {
    const { icon, level, size, children, onClick, marginBottom, textCenter, name, enlighted } = this.props;
    return (
      <div
        name={name}
        className={classnames({ 'text-center': textCenter, 'mb-3': marginBottom }, style.fullTitle, style[size], enlighted && style.enlighted)}
      >
        { icon &&
          <FontAwesomeIcon
            icon={icon}
            className={classnames(style.fullTitleIcon, style[level], onClick && 'cursorPointer')}
            onClick={onClick}
          />
        }
        {children}
      </div>
    );
  }
}