import React, { Component } from 'react';
import { Tooltip } from 'reactstrap';
import DBadge from '../../components/Badge';

export default class ResultRuleCounter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tooltipOpen: false
    }

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }

  render() {
    const { result, rules, idx } = this.props;
    return <>
      <DBadge
        type="result"
        value={result}
        label={rules.toString()}
        idx={`ResultRule_${idx}`}
        href="#"
        />
      <Tooltip
          placement="right"
          isOpen={this.state.tooltipOpen}
          target={`ResultRule_${idx}`}
          toggle={this.toggle}
          delay={{
            show: 0,
            hide: 50
          }}
        >
          <strong>Result:</strong> {result.charAt(0).toUpperCase() + result.slice(1)}<br />
          <strong>Rules triggered:</strong> {rules}
        </Tooltip>
    </>
  }
}