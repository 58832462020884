export let API_URL;
export let API_PATH;
export let API_PATH_NEW;
export let API_PATH_NETWORK;
export let API_VERSION;
export let API_VERSION_NEW;
export let API_VERSION_NETWORK;

const { REACT_APP_DEEPVIZ_ENV } = process.env;

if (REACT_APP_DEEPVIZ_ENV === 'production') {
  API_VERSION = '/v2';
  API_VERSION_NEW = '/v3';
  API_VERSION_NETWORK = '/v3';
  API_URL = 'https://api.prod.deepviz.com';

} else if (REACT_APP_DEEPVIZ_ENV === 'stage') {
  API_VERSION = '/v2';
  API_VERSION_NEW = '/v3';
  API_VERSION_NETWORK = '/v3';
  API_URL = 'https://api.stage.deepviz.com';
} else {
  API_VERSION = '/v2';
  API_VERSION_NEW = '/v3';
  API_VERSION_NETWORK = '/v3';
  API_URL = 'https://api.dev.deepviz.com';
}

API_PATH = API_URL + API_VERSION;
API_PATH_NEW = API_URL + API_VERSION_NETWORK;
API_PATH_NETWORK = API_URL + API_VERSION_NETWORK;

export const lastCheckTimeout = 60000; //milliseconds