export const apiWikiURI = 'https://github.mb-internal.com/Deepviz/Backend/wiki';

export const statusSuccess = 'success';
export const statusNotFound = 'not found';
export const statusProcessing = 'processing';
export const statusFailed = 'failed';

export const analysisCompleted = 'completed';
export const analysisFailed = 'failed';

export const unauthorizedMessage = 'Unauthorized request';

export const sampleSubmissionsListPageLenght = 15;