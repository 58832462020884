import React from 'react';
import HorizontalTimeline from 'react-horizontal-timeline';

import style from './style.scss';
import FullTitle from '../../../components/FullTitle';
import ReportSection from '../../../components/ReportSection';
import { getFriendlyTimeDelta } from '../../../utils/dates';

export default class Screenshots extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: 0,
      previous: 0
    };
  }

  render() {
    const { data } = this.props;
    const { value } = this.state;

    if(data && Array.isArray(data)) {
      return <ReportSection title="Screenshots">
        <div style={{ width: '90%', height: '100px', margin: '0 auto' }}>
          <HorizontalTimeline
            index={value}
            indexClick={(index) => {
              this.setState({ value: index, previous: value });
            }}
            values={data.map((s, i) => i)}
            getLabel={ index => getFriendlyTimeDelta(data[index].delta) }
            isKeyboardEnabled={true}
            styles={{
              background: '#ffffff',
              foreground: '#af3437',
              outline: '#f4f4f4'
            }}
            minEventPadding={20}
            maxEventPadding={40}
          />
        </div>
        <div className='text-center'>
          <img className={style.screenshotImage} alt="Screenshot" src={`data:image/jpeg;base64,${data[value].image}`} />
        </div>
      </ReportSection>
    } else {
      return <FullTitle title="No data available" />;
    }
  }
}