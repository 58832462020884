import { authedRequest, authedPostRequest, authedPutRequest, uploadFile, switchHash, authedRequestNoVersion } from '../utils/request';
import { cleanStatus } from '../constants/submissions';
import moment from 'moment';

const timezoneOffset = moment().format("Z");

export const getStatsCounters = timeWindow => {
  const body = {};

  if (timeWindow !== 'All') {
    body.window = timeWindow;
  }

  return authedPostRequest('stats/counters', { body });
}

export const getStatsCharts = timeWindow => {
  const body = {};

  body.offset = timezoneOffset;

  if (timeWindow !== 'All') {
    body.window = timeWindow;
  }

  return authedPostRequest('stats/graphs', { body } );
}

export const uploadNewSample = file => {
  const formData = new FormData();
  formData.append('file', file);
  return uploadFile('sandbox/sample/submit', formData);
}

export const sendUrl = (url, isTempUrl) => {
  let filename = '';

  if(isTempUrl) {
     filename = url.split('/').slice(-1)[0].split('?')[0];
  } else {
    filename = url.split('/').slice(-1)[0];
  }

  return authedPostRequest('sandbox/sample/submit', { body: { url, filename }})
}

export const reportIncorrectDetection = (hash, classification, comment) => {
  const body = {
    ...switchHash(hash),
    comment,
    classification
  }

  return authedPostRequest('sandbox/sample/notify', { body })
}

export const fixClassification = (hash, fixed, result, rescan) => {
  const body = {
    ...switchHash(hash),
    fixed,
    result: result === cleanStatus ? 0 : 1,
    rescan
  }

  return authedPutRequest('sandbox/classification', { body })
}

export const sendRescan = hash => {
  const body = {
    ...switchHash(hash)
  };

  return authedPostRequest('sandbox/sample/rescan', { body })
}

export const fetchDataConnection = (hash, platform, protocol, conn_id = null, event_type = null, event_id = null) => {
  const body = {
    ...switchHash(hash),
    platform
  };

  if(conn_id) body.conn_id = conn_id;
  if(event_type) body.event_type = event_type;
  if(event_id) body.event_id = event_id;
  if(protocol !== 'pcap') body.hex = true;

  return authedPostRequest(`sandbox/report/network/${protocol}`, { body }, true);
}

export const  fetchStrings = (hash, platform, block) => {
  const body = {
    ...switchHash(hash),
    platform,
    block
  };

  return authedPostRequest('sandbox/report/strings', { body });
}

export const sampleReportStatus = hash =>
  authedPostRequest(
    'sandbox/report',
    {
      body: {
        ...switchHash(hash),
        output_filters: ["info"]
      }
    }
  )
  
export function fetchServerStatus() {
  return new Promise((resolve, reject) => {
    authedRequest('sandbox/status').then(res => {
      const apiResponse = res.data;
      let report = [];
      let sandbox = [];
  
      Object.entries(apiResponse.report).map(r => {
        const reportServerName = r[0];
        const reportDockers = r[1];
  
        Object.entries(reportDockers).map(rD => {
          const reportDockerName = rD[0];
          const reportDockerAttributes = rD[1];
  
          report.push([reportServerName, reportDockerName, reportDockerAttributes]);
          return null;
        });
  
        return null;
      });
  
      Object.entries(apiResponse.sandbox).map(s => {
        const sandboxServerName = s[0];
        const sandboxDockers = s[1];
  
        Object.entries(sandboxDockers).map(sD => {
          const sandboxDockerName = sD[0];
          const sandboxDockerAttributes = sD[1];
  
          sandbox.push([sandboxServerName, sandboxDockerName, sandboxDockerAttributes]);
          return null;
        });
  
        return null;
      });
  
      resolve({
        status: res.status,
        data: {
          report,
          sandbox,
          vpnContainer: apiResponse.vpn_container,
          rabbitmq: apiResponse.rabbitmq
        }
      });
    });
  });
}

export const fetchSandboxVersion = () =>
  authedRequestNoVersion('check/deepviz', {})