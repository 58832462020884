import 'whatwg-fetch';
import { store, subscribeToStore } from '../store';
import { Observable } from './observables';
import { HttpStatusCodes } from '../constants/http';
import { API_URL, API_PATH, API_PATH_NEW } from '../constants/index';
import { history } from '../store/configureStore';
import { handleError } from '../services/errorHandler';
import { updateLastVisitedPage } from '../actions/tools';

let token;

// Subscribe to changes to the store's state to update
// the api and refresh tokens
subscribeToStore(({ auth }) => {
  if (auth.token) {
    token = auth.token;
  } else {
    token = null;
  }
});

export function switchHash(hash) {
  const body = {};
  
  switch(hash.length) {
    case 40:
      body.sha1 = hash;
      break;
    case 64:
      body.sha256 = hash;
      break;
    case 128:
      body.sha512 = hash;
      break;
    default:
    case 32:
      body.md5 = hash;
      break;
  }

  return body;
}

/**
 * check http status code of a network request
 * @param {object} res - response object
 * @returns {object|function} - if successful response, response object is returned,
 *   if not, function that returns promise that resolves with an error being thrown containing error messages
 */
function checkStatus(res) {
  const { status } = res;
  if (status >= 200 && status < 300) {
    return res;
  }

  if (status === HttpStatusCodes.Unauthorized) {
    store.dispatch(updateLastVisitedPage(history.location.pathname));
    history.push('/auth/logout');
    return;
  }

  return res.json()
    .then(err => {
      throw err;
    });
}


/**
 * function that wraps the native fetch api
 * @param {string} url url of route to fetch against
 * @param {object} options unnamed object to be destructured with the following optional properties:
 * @param {string} options.method HTTP verb request type
 * @param {string} options.mode the mode of the request (ie cors, no-cors)
 * @param {object} options.body body to be stringified and sent with request
 * @param {Headers} options.headers http headers object
 * @param {string} options.credentials the options for credential usage
 * @returns {Promise} promise to be resolved with json-parsed response, or just the response or throws an error if status code outside of 200-300
 */
export function simpleFetch(url, { method, mode, body, credentials, headers = new Headers() }) {
  if (body) {
    headers.append('Content-type', 'application/json');
  }

  const config = {
    method: method || 'GET',
    body: body ? JSON.stringify(body) : null,
    mode,
    headers,
    credentials
  };

  return fetch(url, config)
    .then(checkStatus)
    .then(response => {
      const contentType = response.headers.get('content-type');
      
      if (/^application\/json.*/.test(contentType)) {
        return response.json();
      } else {
        return Promise.resolve(response);
      }
    })
    .catch(err => {
      handleError('Error', err.errmsg ? err.errmsg : 'Error in the request');
      //check if err is an array
      if (Array.isArray(err)) {
        throw err;
      } else {
        //if not, make it an array since errorHandler expectes errors to be in an array
        throw new Array(err);
      }
    });
}

/**
 * construct Authorization header for request
 * @param {string} token - auth (jwt) token
 * @param {string} url - url to be requested
 * @param {object} options - options object containing any combination of
 * @param {string} options.method - http method for request
 * @param {object} options.body - body for request
 * @param {string} options.mode - mode for request
 * @param {Headers} options.headers - https headers object
 * @returns {function} - function that performs request
 */
export function authedRequest(url, options = {}, is_new_route = false) {
  if(!options.headers) {
    options.headers = new Headers({
      'Okta-token': token
    });
  }
  
  const URI = is_new_route ? API_PATH_NEW : API_PATH;

  return simpleFetch(`${URI}/${url}`, options);
}

export function authedPostRequest(url, options = {}, is_new_route = false) {
  options.method = 'POST';
  return authedRequest(url, options, is_new_route);
}

export function authedPutRequest(url, options = {}) {
  options.method = 'PUT';
  return authedRequest(url, options);
}

export function authedRequestNoVersion(url, options = {}) {
  if(!options.headers) {
    options.headers = new Headers({
      'Okta-token': token
    });
  }
  
  return simpleFetch(`${API_URL}/${url}`, options);
}


/**
 * Makes an authorized request to upload a file; returns
 * an `Observable` that can be subscribed to in order to
 * be notified of progress.
 *
 * A `fetch` cannot be used here because it doesn't have
 * any way to report back progress. We need a stream of
 * data here to report the progress instead of a `Promise`,
 * which is why we are using an `Observable`.
 *
 * NOTE: The upload will not start until the `Observable`
 * is subscribed to; it is a *cold* `Observable`
 * @param {string} url A URL to `POST` to
 * @param {File|FormData} fileOrFormData A `File` or `FormData` object with a file to upload
 * @returns {Observable} An `Observable`
 */
export function uploadFile(url, fileOrFormData) {
  const xhr = new XMLHttpRequest();
  let totalFileSize;

  return new Observable(observer => {
    // Handle the upload progress
    xhr.upload.addEventListener('progress', e => {
      if (e.lengthComputable) {
        const { loaded, total } = e;
        totalFileSize = total;
        const percentage = Math.round((loaded * 100) / total);
        observer.next({
          response: null,
          percentage,
          total
        });
      }
    });

    // Handle when the upload is complete (happens before
    // the responseText is returned in the readystatechange)
    xhr.upload.addEventListener('load', e => {
      observer.next({
        response: null,
        percentage: 100,
        total: e.total
      });
    });

    // Handle an error
    xhr.upload.addEventListener('error', e => {
      observer.error(e);
    });

    // Handle response data
    xhr.addEventListener('readystatechange', () => {
      if (xhr.readyState === 4) {
        const { responseText, status } = xhr;
        if (status >= HttpStatusCodes.OK && status <= HttpStatusCodes.MultiStatus) {
          // Success
          observer.next({
            response: JSON.parse(responseText),
            percentage: 100,
            total: totalFileSize
          });
        } else if (status >= HttpStatusCodes.BadRequest) {
          // Error
          let err;
          try {
            err = JSON.parse(responseText);
            if (!err instanceof Array) {
              err = [err];
            }
          } catch (ex) {
            err = responseText;
          }
          observer.error(err);
        }
      }
    });

    xhr.open('POST', `${API_PATH}/${url}`);
    xhr.setRequestHeader('Okta-token', token);
    xhr.send(fileOrFormData);
  });
}
