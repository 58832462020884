import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleUp, faArrowAltCircleDown } from '@fortawesome/free-solid-svg-icons';

import style from './style.scss';

export default class ConnectionEventIcon extends React.Component {
  render() {
    const { type } = this.props;
    const icon = type === 'send' ? faArrowAltCircleUp : faArrowAltCircleDown;

    return (
      <>
        <FontAwesomeIcon
          icon={icon}
          className={style.connectionEventIcon}
          title={`Event type: ${type}`}
        />
      </>
    );
  }
}