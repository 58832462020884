import React from 'react';

import FullTitle from '../../../../../components/FullTitle';
import OoXML10 from './1.0';
import _ from 'underscore';

export default class OoXMLStatic extends React.Component {
  render() {
    const { data, report_version } = this.props;

    if(!_.isEmpty(data)) {
      switch(report_version) {
        case '1.0':
        default:
          return <OoXML10 data={data} />;
      }
    } else {
      return <FullTitle title="No data available" />;
    }
  }
}