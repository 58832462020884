import React, { Fragment } from 'react';
import { Table } from 'reactstrap';
import _ from 'underscore';
import CodeHighlighter from '../../../../../components/CodeHighlighter';

import ReportSection from '../../../../../components/ReportSection';
import FlagIcon from '../../../../../components/FlagIcon';

import style from '../../style.scss';

export default class OleStatic10 extends React.Component {
  renderMacros(macros) {
    return !!macros.length && <ReportSection title="Macros">
      {macros.map((macro, idx) => <Fragment key={idx}>
        <Table size="sm" responsive bordered striped key={idx}>
          <tbody>
            <tr>
              <td className={style.title}>VBA filename</td>
              <td>{macro.vba_filename}</td>
            </tr>
            <tr>
              <td className={style.title}>Stream path</td>
              <td>{macro.stream_path}</td>
            </tr>
          </tbody>
        </Table>
        {!!macro.analyses.length && <>
          <p><strong>Analyses</strong></p>
          {macro.analyses.map((analysis, idx) => <Table size="sm" responsive bordered striped key={idx}>
            <tbody>
              <tr>
                <td className={style.title}>Code</td>
                <td><CodeHighlighter code={analysis.code} base64={true} /></td>
              </tr>
              {!!analysis.vba_expression_strings && <tr>
                <td className={style.title}>VBA Expression Strings</td>
                <td>{analysis.vba_expression_strings}</td>
              </tr>}
              <tr>
                <td className={style.title}>Shellcode</td>
                <td><FlagIcon flag={analysis.shellcode} /></td>
              </tr>
              <tr>
                <td className={style.title}>Obfuscation</td>
                <td><FlagIcon flag={analysis.obfuscation} /></td>
              </tr>
              {!!analysis.assembly_list.length && <tr>
                <td className={style.title}>Assembly list</td>
                <td>
                  {analysis.assembly_list.map((assembly, idx) =>
                    <p key={idx} className="mb-0"><CodeHighlighter code={assembly} /></p>
                  )}
                </td>
              </tr>}
              {!!analysis.exceptions.length && <tr>
                <td className={style.title}>Exceptions</td>
                <td>{analysis.exceptions.map((exc, idx) => <p key={idx} className="mb-0">{exc}</p>)}</td>
              </tr>}
            </tbody>
          </Table>)}
        </>}
      </Fragment>)}
    </ReportSection>
  }

  renderData = (title, data) => !_.isEmpty(data) && <ReportSection title={title}>
    <Table size="sm" responsive bordered striped>
      <tbody>
      {
        Object.entries(data).map((m, idx) => 
          <tr key={idx}>
            <td className={style.title}>{m[0].replace(/_/g, ' ')}</td>
            <td>{ typeof m[1] === 'boolean' ? <FlagIcon flag={m[1]} /> : m[1] }</td>
          </tr>
        )
      }
      </tbody>
    </Table>
  </ReportSection>;

  renderSwfList(swfList) {
    return !!swfList.length && <ReportSection title="SWF list">
      {swfList.map((swf, idx) => <Table size="sm" responsive bordered striped key={idx}>
      <tbody>
        <tr>
          <td className={style.title}>Shellcode</td>
          <td><FlagIcon flag={swf.shellcode} /></td>
        </tr>
        <tr>
          <td className={style.title}>Compressed</td>
          <td><FlagIcon flag={swf.compressed} /></td>
        </tr>
        <tr>
          <td className={style.title}>Code</td>
          <td><CodeHighlighter code={swf.code} /></td>
        </tr>
        {!!swf.exceptions.length && <tr>
          <td className={style.title}>Exceptions</td>
          <td>{swf.exceptions.map((exc, idx) => <p key={idx} className="mb-0">{exc}</p>)}</td>
        </tr>}
      </tbody>
      </Table>)}
    </ReportSection>
  }

  render() {
    const {info, macros, metadata, swf_list } = this.props.data;

    return <>
      {!!info && this.renderData("Information", info)}
      {!!metadata && this.renderData("Metadata", metadata)}
      {!!swf_list && this.renderSwfList(swf_list)}
      {!!macros && this.renderMacros(macros)}
      </>
  }
} 