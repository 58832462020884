import configureStore from './configureStore';

/** The `Redux` store */
const { store, persistor } = configureStore();

/** A list of subscriptions to the store changes */
const subscriptions = [];

// Subscribe to the store; whenever the state changes,
// call any handlers with the new state
store.subscribe(() => {
  const state = store.getState();
  subscriptions.forEach(handler => handler(state));
});

/**
 * Gets the redux store's `dispatch` function
 */
function getDispatch() {
  return store.dispatch;
}

/**
 * Adds a subscription handler to the redux store
 * so that whenever the state changes, the handler
 * is notified
 * @param {state => void} handler A function to handle state changes
 */
function subscribeToStore(handler) {
  subscriptions.push(handler);
}

export {
  getDispatch,
  store,
  subscribeToStore,
  persistor
};
