import React, { Fragment } from 'react';
import { Table } from 'reactstrap';
import CodeHighlighter from '../../../../../components/CodeHighlighter';

import FlagIcon from '../../../../../components/FlagIcon';
import ReportSection from '../../../../../components/ReportSection';
import style from '../../style.scss';

export default class OoXML10 extends React.Component {
  renderCoreProperties(coreProperties) {
    return <ReportSection title="Core Properties">
      <Table size="sm" responsive bordered striped>
        <tbody>
        {
          Object.entries(coreProperties).map((prop, idx) => prop[1] !== "" &&
          <tr key={idx}>
            <td className={style.title}>{prop[0].replace("_", " ").replace(/^\w/, c => c.toUpperCase())}</td>
            <td>{prop[1]}</td>
          </tr>)
        }
        </tbody>
      </Table>
    </ReportSection>
  }

  renderFeatures(features) {
    return <ReportSection title="Features">
      <Table size="sm" responsive bordered striped>
        <tbody>
        {
          Object.entries(features).map((feature, idx) => 
          <tr key={idx}>
            <td className={style.title}>{feature[0].replace("_", " ").replace(/^\w/, c => c.toUpperCase())}</td>
            <td>{feature[1].length ? feature[1].map((f, idx) => <p key={idx} className="mb-0">{f}</p>) : <p className="mb-0">No data available</p>}</td>
          </tr>)
        }
        </tbody>
      </Table>
    </ReportSection>
  }

  renderMacros(macros) {
    return !!macros.length && <ReportSection title="Macros">
      {macros.map((macro, idx) => <Fragment key={idx}>
        <Table size="sm" responsive bordered striped key={idx}>
          <tbody>
            <tr>
              <td className={style.title}>VBA filename</td>
              <td>{macro.vba_filename}</td>
            </tr>
            <tr>
              <td className={style.title}>Stream path</td>
              <td>{macro.stream_path}</td>
            </tr>
          </tbody>
        </Table>
        {!!macro.analyses.length && <>
          <p><strong>Analyses</strong></p>
          {macro.analyses.map((analysis, idx) => <Table size="sm" responsive bordered striped key={idx}>
            <tbody>
              <tr>
                <td className={style.title}>Code</td>
                <td><CodeHighlighter code={analysis.code} base64={true} /></td>
              </tr>
              {!!analysis.vba_expression_strings && <tr>
                <td className={style.title}>VBA Expression Strings</td>
                <td>{analysis.vba_expression_strings}</td>
              </tr>}
              <tr>
                <td className={style.title}>Shellcode</td>
                <td><FlagIcon flag={analysis.shellcode} /></td>
              </tr>
              <tr>
                <td className={style.title}>Obfuscation</td>
                <td><FlagIcon flag={analysis.obfuscation} /></td>
              </tr>
              {!!analysis.assembly_list.length && <tr>
                <td className={style.title}>Assembly list</td>
                <td>
                  {analysis.assembly_list.map((assembly, idx) =>
                    <span key={idx}><CodeHighlighter key={idx} code={assembly} /></span>
                  )}
                </td>
              </tr>}
              {!!analysis.exceptions.length && <tr>
                <td className={style.title}>Exceptions</td>
                <td>{analysis.exceptions.map((exc, idx) => <p key={idx} className="mb-0">{exc}</p>)}</td>
              </tr>}
            </tbody>
          </Table>)}
        </>}
      </Fragment>)}
    </ReportSection>
  }

  renderSwfList(swfList) {
    return !!swfList.length && <ReportSection title="SWF list">
      {swfList.map((swf, idx) => <Table size="sm" responsive bordered striped key={idx}>
      <tbody>
        <tr>
          <td className={style.title}>Shellcode</td>
          <td><FlagIcon flag={swf.shellcode} /></td>
        </tr>
        <tr>
          <td className={style.title}>Compressed</td>
          <td><FlagIcon flag={swf.compressed} /></td>
        </tr>
        <tr>
          <td className={style.title}>Code</td>
          <td><CodeHighlighter key={idx} code={swf.code} /></td>
        </tr>
        {!!swf.exceptions.length && <tr>
          <td className={style.title}>Exceptions</td>
          <td>{swf.exceptions.map((exc, idx) => <p key={idx} className="mb-0">{exc}</p>)}</td>
        </tr>}
      </tbody>
      </Table>)}
    </ReportSection>
  }

  render() {
    const { data } = this.props;
    const { core_properties, features, macros, swf_list} = data;

    return <>
      {!!core_properties && this.renderCoreProperties(data.core_properties)}
      {!!features && this.renderFeatures(data.features)}
      {!!macros && this.renderMacros(data.macros)}
      {!!swf_list && this.renderSwfList(data.swf_list)}
    </>;
  }
} 