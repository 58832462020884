import React, { Component } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import style from './style.scss';

export default class ToolbarIcon extends Component {
  render() {
    const {label, icon, section, selected} = this.props;

    return (
        <div className={style.container} onClick={() => { this.props.onClick(section) }}>
          <div className={classNames(style.iconContainer, selected ? style.selected : '')}>
            <FontAwesomeIcon icon={icon} />
          </div>
          <span className={style.labelContainer}>
            <p>{label}</p>
          </span>
        </div>
    );
  }
};