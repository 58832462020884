import {
  UPDATE_ADMIN_SUBMISSIONS_PAGE_SIZE,
  UPDATE_ADMIN_SUBMISSIONS_TABLE_PAGE,
  UPDATE_ADMIN_SUBMISSIONS_FILTERS,
  UPDATE_ADMIN_SUBMISSIONS_SORTING,
  UPDATE_SANDBOX_SUBMISSIONS_PAGE_SIZE,
  UPDATE_SANDBOX_SUBMISSIONS_TABLE_PAGE,
  UPDATE_SANDBOX_SUBMISSIONS_FILTERS,
  UPDATE_SANDBOX_SUBMISSIONS_SORTING,
  UPDATE_ADMIN_SAMPLES_PAGE_SIZE,
  UPDATE_ADMIN_SAMPLES_TABLE_PAGE,
  UPDATE_ADMIN_SAMPLES_FILTERS,
  UPDATE_ADMIN_SAMPLES_SORTING,
  UPDATE_SANDBOX_USERS_PAGE_SIZE,
  UPDATE_SANDBOX_USERS_TABLE_PAGE,
  UPDATE_SANDBOX_USERS_FILTERS,
  UPDATE_SANDBOX_USERS_SORTING,
  SET_INJECTED_PROCESS,
  UPDATE_SANDBOX_DATA_ON_LOGOUT
} from '../actions/sandbox';

const initialState = {
  submissionsPage: 1,
  submissionsPageSize: 50,
  submissionsFilters: [],
  submissionsSorting: [],
  usersPage: 1,
  usersPageSize: 50,
  usersFilters: [],
  usersSorting: [],
  adminSubmissionsPage: 1,
  adminSubmissionsPageSize: 50,
  adminSubmissionsFilters: [],
  adminSubmissionsSorting: [],
  adminSamplesPage: 1,
  adminSamplesPageSize: 50,
  adminSamplesFilters: [],
  adminSamplesSorting: [],
  injectedProcess: null
};

export default function sandbox(state = initialState, action) {
  switch (action.type) {
    case UPDATE_SANDBOX_DATA_ON_LOGOUT:
      return {
        ...initialState
      };
    case UPDATE_ADMIN_SUBMISSIONS_PAGE_SIZE:
      return {
        ...state,
        adminSubmissionsPageSize: action.size
      }
    case UPDATE_ADMIN_SUBMISSIONS_TABLE_PAGE:
      return {
        ...state,
        adminSubmissionsPage: action.page
      };
    case UPDATE_ADMIN_SUBMISSIONS_FILTERS:
      return {
        ...state,
        adminSubmissionsFilters: action.filters
      };
    case UPDATE_ADMIN_SUBMISSIONS_SORTING:
      return {
        ...state,
        adminSubmissionsSorting: action.sorting
      }
      case UPDATE_ADMIN_SAMPLES_PAGE_SIZE:
        return {
          ...state,
          adminSamplesPageSize: action.size
        }
      case UPDATE_ADMIN_SAMPLES_TABLE_PAGE:
        return {
          ...state,
          adminSamplesPage: action.page
        };
      case UPDATE_ADMIN_SAMPLES_FILTERS:
        return {
          ...state,
          adminSamplesFilters: action.filters
        };
      case UPDATE_ADMIN_SAMPLES_SORTING:
        return {
          ...state,
          adminSamplesSorting: action.sorting
        }
    case UPDATE_SANDBOX_SUBMISSIONS_PAGE_SIZE:
      return {
        ...state,
        submissionsPageSize: action.size
      };
    case UPDATE_SANDBOX_SUBMISSIONS_TABLE_PAGE:
      return {
        ...state,
        submissionsPage: action.page
      };
    case UPDATE_SANDBOX_SUBMISSIONS_FILTERS:
      return {
        ...state,
        submissionsFilters: action.filters
      }
    case UPDATE_SANDBOX_SUBMISSIONS_SORTING:
      return {
        ...state,
        submissionsSorting: action.filters
      }
    case UPDATE_SANDBOX_USERS_PAGE_SIZE:
      return {
        ...state,
        usersPageSize: action.size
      };
    case UPDATE_SANDBOX_USERS_TABLE_PAGE:
      return {
        ...state,
        usersPage: action.page
      };
    case UPDATE_SANDBOX_USERS_FILTERS:
      return {
        ...state,
        usersFilters: action.filters
      }
    case UPDATE_SANDBOX_USERS_SORTING:
      return {
        ...state,
        usersSorting: action.sorting
      }
    case SET_INJECTED_PROCESS:
      return {
        ...state,
        injectedProcess: action.hash
      }
    default: {
      return state;
    }
  }
}
