import moment from 'moment';

export function localizeDate(date) {
  const parseDate = moment.utc(date).local();
  if (parseDate.isValid()) {
    return parseDate.local().format('YYYY-MM-DD HH:mm:ss');
  } else {
    return '---';
  }
}

export const getFriendlyTimeDelta = timeDelta => timeDelta / 1000  + 's';