import React from 'react';
import classnames from 'classnames';
import { Tooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindows } from '@fortawesome/free-brands-svg-icons';

import style from './style.scss';

export default class PlatformStatus extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tooltipOpen: false
    }

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }

  render() {
    const {platform, status, md5} = this.props;
    return (
      <>
        <FontAwesomeIcon icon={faWindows} id={`PlatformStatus_${md5}_${platform}`} className={classnames(style.platformStatus, style[status.replace(" ", "")])} />
        <Tooltip
          placement="right"
          isOpen={this.state.tooltipOpen}
          target={`PlatformStatus_${md5}_${platform}`}
          toggle={this.toggle}
          delay={{
            show: 0,
            hide: 50
          }}
        >
          <strong>Platform:</strong> {platform.replace(/_/g, ' ')}<br />
          <strong>Status:</strong> {status.charAt(0).toUpperCase() + status.slice(1)}
        </Tooltip>
      </>
    );
  }
}