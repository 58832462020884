import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';

import base64 from 'base-64';

import UserMenu from './UserMenu';
import {
  Navbar, Nav, NavItem,
  InputGroup, Input, InputGroupAddon, Button
} from 'reactstrap';

import { serviceName } from '../../constants/names';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import style from './style.scss';

library.add(
  faSearch
);

const deepvizLogo = require('../../../images/deepviz-logo.png');


class MainNavBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      inputValue: ''
    };

    this.search = this.search.bind(this);
  }

  checkInput = event => {
    if(event.key === 'Enter') this.search();
  }

  search() {
    const { inputValue } = this.state;
    const { push } = this.props.history;
    const tempVal = inputValue;
    this.setState({
      inputValue: ''
    }, () => {
      push('/search/' + base64.encode(encodeURI(tempVal)));
    });
  }

  updateInputValue(evt) {
    this.setState({
      inputValue: evt.target.value
    });
  }

  render() {
    const { token, group_name } = this.props.auth;
    const { version } = this.props.tools;

    let statusPageLink = `//status.${window.location.hostname.split(".")[1]}.deepviz.com/`;

    return (
      <Navbar color="light" light expand="md" style={{ marginBottom: "1rem" }}>
        <Link className="navbar-brand" to="/">
          <img
            src={deepvizLogo.default}
            alt={`${serviceName} logo`}
            className={style.navbarLogo}
          />
          <p className={style.serviceNameContainer}>
            <span>{serviceName}</span>
            {version && <span className={style.serviceVersion}>v{version}</span>}
          </p>
        </Link>

        {token ?
          <>
          <Nav className="mr-auto" navbar>
          <NavItem>
            <Link className="nav-link" to="/sandbox/">Sandbox</Link>
          </NavItem>
          <NavItem>
            <Link className="nav-link" to="/search/">Search</Link>
          </NavItem>
          <NavItem>
            <Link className="nav-link" to="/apidoc/">API Docs</Link>
          </NavItem>
          <NavItem>
            <Link className={`nav-link`} target="_blank" to={statusPageLink}>Status Page</Link>
          </NavItem>
        </Nav>

        <Nav className="ml-auto" navbar>
          <InputGroup size="sm" style={{ alignItems: "center" }}>
            <Input
              placeholder="Search"
              onKeyUp={this.checkInput}
              onChange={evt => this.updateInputValue(evt)}
              value={this.state.inputValue}
              />
            <InputGroupAddon addonType="append">
              <Button color="secondary" onClick={this.search}>
                <FontAwesomeIcon icon={faSearch} />
              </Button>
            </InputGroupAddon>
          </InputGroup>
          <UserMenu group_name={group_name} />
        </Nav>
        </> : null
      }
      </Navbar>
    );
  }
}

export default withRouter(connect(
  store => ({
    auth: store.auth,
    tools: store.tools
  })
)(MainNavBar));