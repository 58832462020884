import React from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

import {
  Row, Col
} from 'reactstrap';

import style from './style.scss';

library.add(
  faCircleNotch
);


export default class Loading extends React.Component {
  render() {
    return (
      <Row>
        <Col className={style.loading}>
          <FontAwesomeIcon icon={faCircleNotch} spin size='4x' />
        </Col>
      </Row>
    );
  }
}