import React from 'react';
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker
} from "react-simple-maps";

import { TabContent, TabPane, Nav, NavItem, NavLink, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import download from 'downloadjs';
import base64 from 'base-64';
import FullTitle from '../../../components/FullTitle';
import IPContainer from '../../../components/IPContainer';
import DomainContainer from '../../../components/DomainContainer';
import { fetchDataConnection } from '../../../services/sandbox';

import world from '../../../../topo/world-110m.json';
import style from './style.scss';
import { faSpinner, faFileDownload } from '@fortawesome/free-solid-svg-icons';

export default class Network extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: 0,
      markers: [],
      isDownloadingPCAP: false
    }
  }

  componentDidMount() {
    const { data } = this.props;
  
    const ips = data ? data.ips : [];
    const markers = [];

    ips.forEach(ip => {
      if(ip.geo_info && ip.geo_info.location) {
        markers.push({
          name: ip.ip,
          coordinates: [ip.geo_info.location.lon, ip.geo_info.location.lat]
        })
      }
    });

    this.setState({
      markers
    });
  }

  toggleTab = tab => {
    if (this.state.activeTab !== tab) {
      this.setState({
         activeTab: tab
      });
    }
  }

  downloadPCAP = () => {
    const {platform, hash} = this.props;
    const {isDownloadingPCAP} = this.state;

    if(!isDownloadingPCAP) {
      this.setState({
        isDownloadingPCAP: true
      }, () => {
        setTimeout(() => {
          fetchDataConnection(hash, platform, 'pcap').then(res => {
            return base64.decode(res.data);
          }).then(data => {
            this.setState({
              isDownloadingPCAP: false
            }, () => {
              download(data, `${hash}.${platform}.pcap`, 'text/plain')
            })
          }).catch(() =>
            this.setState({
              isDownloadingPCAP: false
            })
        )}, 0);
      });
    }
  }

  render() {
    const { activeTab, markers, isDownloadingPCAP } = this.state;
    const { data } = this.props;

    const domainCount = !!data && data.domains.length;
    const ipCount = !!data && data.ips.length;

    const spin = isDownloadingPCAP;
    const icon = isDownloadingPCAP ? faSpinner : faFileDownload;
    return !!(domainCount + ipCount) ?
      <>
        <div className={style.downloadPCAP}>
          <Button onClick={this.downloadPCAP}>Click to download the PCAP file <FontAwesomeIcon icon={icon} spin={spin} /></Button>
        </div>
        <div className={style.networkMapContainer}>
          <ComposableMap style={{ width: "100%", height: '450px' }} projection="geoMercator">
            <Geographies geography={world}>
              {({geographies}, projection) => geographies.map(geo => (
                <Geography
                key={geo.rsmKey}
                  geography={geo}
                  projection={projection}
                  
                  style={{
                    default: {
                      fill: "#ECEFF1",
                      stroke: "#607D8B",
                      strokeWidth: 0.75,
                      outline: "none",
                    },
                    hover: {
                      fill: "#607D8B",
                      stroke: "#607D8B",
                      strokeWidth: 0.75,
                      outline: "none",
                    },
                    pressed: {
                      fill: "#FF5722",
                      stroke: "#607D8B",
                      strokeWidth: 0.75,
                      outline: "none",
                    },
                  }}
                  />
              ))}
            </Geographies>
            {markers.map(({name, coordinates}, i) => <Marker
              key={i}
              coordinates={coordinates}
              style={{
                default: { fill: "#FF5722" },
                hover: { fill: "#FFFFFF" },
                pressed: { fill: "#FF5722" },
              }}
              >
              <circle
                cx={0}
                cy={0}
                r={7}
                style={{
                  stroke: "#FF5722",
                  strokeWidth: 2,
                  opacity: 0.9,
                }}
              />
              <text
                textAnchor="middle"
                y={20}
                style={{
                  fontFamily: "Roboto, sans-serif",
                  fill: "black",
                  stroke: "white",
                  paintOrder: "stroke",
                  strokeWidth: "2px",
                  strokeLinecap: "butt",
                  strokeLinejoin: "miter",
                  fontWeight: "800",
                }}
                >
                {name}
              </text>
            </Marker>)}
          </ComposableMap>
        </div>

        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames(
                style.dynamicTabs,
                { 'font-weight-bold': activeTab === 0, active: activeTab === 0 }
                )}
              onClick={() => { this.toggleTab(0); }}
            >
              IPs [{ipCount}]
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames(
                style.dynamicTabs,
                { 'font-weight-bold': activeTab === 1, active: activeTab === 1 }
                )}
              onClick={() => { this.toggleTab(1); }}
            >
              Domains [{domainCount}]
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
        <TabPane tabId={0} className={style.dynamicTabsContainer}>
          {data.ips.map((ips, idx) => <IPContainer data={ips} key={idx} />)}
          {data.ips.length === 0 && <FullTitle title="No data available" />}
        </TabPane>
        <TabPane tabId={1} className={style.dynamicTabsContainer}>
          {data.domains.map((domain, idx) => <DomainContainer data={domain} key={idx} />)}
          {data.domains.length === 0 && <FullTitle title="No data available" />}
        </TabPane>
        </TabContent>
      </> : <FullTitle title="No data available" />;
  }
}