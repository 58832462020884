import React from 'react';

import FullTitle from '../../../../components/FullTitle';
import Toolbox from '../../../../components/Toolbox';
import DynamicAnalysis10 from './1.0';
import DynamicAnalysis11 from './1.1';
import { reportVersionMapping } from '../../../../constants/sandbox';

export default class DynamicAnalysis extends React.Component {
  render() {
    const { data, platform, hash, tools, dropped } = this.props; 

    if(data) {
      const componentVersion = reportVersionMapping.dynamic.windows[data.report_version];

      switch(componentVersion) {
        case '1.0':
        default:
          return <>
            {tools && <Toolbox tools={tools} />}
            <DynamicAnalysis10 data={data.data} platform={platform} hash={hash} dropped={dropped} />
          </>;
          case '1.1':
            return <>
              {tools && <Toolbox tools={tools} />}
              <DynamicAnalysis11 data={data.data} platform={platform} hash={hash} dropped={dropped} />
            </>;
      }
    } else {
      return <FullTitle title="No data available" />;
    }
  }
}