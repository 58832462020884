export const timeWindows = {
  '1h': '1 hour',
  '3h': '3 hours',
  '6h': '6 hours',
  '12h': '12 hours',
  '1d': '1 day',
  '7d': '7 days',
  '15d': '15 days',
  '30d': '30 days',
  'All': 'Always'
}

export const analysisStatus = [
  'pending',
  'processing',
  'failed',
  'success'
];

export const checkAnalysisInterval = 3000;

export const rulesLevel = {
  HIGH: 'high',
  MEDIUM: 'medium',
  LOW: 'low'
};

export const platformStatus = [
  'not started',
  'failed',
  'success'
];

export const processActivityToTag = {
  dynamic_imported_function: 'import',
  raw_disk_access: 'disk',
  code_injection: 'inject',
  file_system: 'fs',
  load_image: 'load img',
  shutdown: 'shutdown',
  registry: 'registry',
  network: 'network',
  process: 'process',
  mutex: 'mutex',
  sleep: 'sleep',
  hook: 'hook',
  injected_code: 'injected',
  tid: 'tid',
};

export const NoStringsExtracted = 'No strings extracted.';

export const reportVersionMapping = {
  info: {
    '1.0': '1.0'
  },
  static: {
    ooxml: {
      '1.0': '1.0'
    },
    js: {
      '1.0': '1.0'
    },
    ole: {
      '1.0': '1.0',
      '1.1': '1.0',
      '1.2': '1.0',
      '1.3': '1.0',
      '1.4': '1.0'
    },
    pdf: {
      '1.0': '1.0',
      '1.1': '1.0'
    },
    pe: {
      '1.0': '1.0',
      '1.1': '1.0'
    },
    rtf: {
      '1.0': '1.0',
      '1.1': '1.0'
    },
    wsf: {
      '1.0': '1.0',
      '1.1': '1.0'
    }
  },
  dynamic: {
    windows: {
      '1.0': '1.0',
      '1.1': '1.1',
      '1.2': '1.1',
      '1.3': '1.1',
      '1.4': '1.1'
    }
  }
}
export const maxCodeHighlightedLength = 200000;
export const maxTableElements = 10000;
