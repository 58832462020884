import React from 'react';
import style from './style.scss';
import { Table } from 'reactstrap';

import { Modal, ModalBody, ModalFooter, Button } from 'reactstrap';

import ReportSection from '../../../components/ReportSection';
import FullTitle from '../../../components/FullTitle';
import { fetchDroppedFile, generateDroppedFileUrl } from '../../../services/download';
import { sendUrl } from '../../../services/sandbox';
import download from 'downloadjs';
import { Tooltip } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileDownload, faSpinner, faCloudUploadAlt, faAlignJustify, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { handleError } from '../../../services/errorHandler';

export default class DroppedFiles extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tooltipOpen: false,
      isDownloading: false,
      isSubmitting: false,
      isViewing: false,
      droppedTextModal: false,
      droppedText: null
    }
  }

  toggleTooltip = () => this.setState({
    tooltipOpen: !this.state.tooltipOpen
  });

  downloadSample = dropped => {
    const { hash, platform } = this.props;

    this.setState({
      isDownloading: true
    }, () => {
      fetchDroppedFile(hash, platform, dropped).then(response => {
        return response.blob();
      }).then(myBlob => {
        this.setState({
          isDownloading: false
        }, () => {
          return download(myBlob, `${hash}.deepviz`, 'application/octet-stream');
        })
      })
    })
  }

  submitDroppedFiles = dropped => {
    const { hash, platform } = this.props;

    this.setState({
      isSubmitting: true
    }, () => {
      generateDroppedFileUrl(hash, platform, dropped).then(
        res => res.data,
        () => this.setState({
          isSubmitting: false
        })).then(tempUrl => {
          sendUrl(tempUrl, true).then(
            res => this.setState({
            isSubmitting: false
          }, () => {
            handleError('Success', 'File uploaded, you can check the analysis status in your submissions list', 'success')
          }),
          () => this.setState({
            isSubmitting: false
          }))
        })
    })
  }


  toggleDroppedTextModal = () => {
    this.setState(prevState => ({
      droppedTextModal: !prevState.droppedTextModal
    }));
  }
  

  renderDroppedTextModal = () => {
    const {droppedTextModal, droppedText} = this.state;

    return <Modal isOpen={droppedTextModal} toggle={this.toggleDroppedTextModal} size='xl'>
      <ModalBody>
        <textarea readOnly className={style.stringsContainer} defaultValue={droppedText ? droppedText.replace(/\\n/g, '<br />') : null}></textarea>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={this.toggleDroppedTextModal}>Close</Button>
      </ModalFooter>
    </Modal>
  }

  showDroppedText = dropped => {
    const { hash, platform } = this.props;

    this.setState({
      isViewing: true
    }, () => {
      fetchDroppedFile(hash, platform, dropped).then(res => {
        return res.blob()
      }, () => {
        this.setState({ isViewing : false })
      }).then(blob => {
        blob.text().then(content =>
          this.setState({
            droppedTextModal: true,
            droppedText: content,
            isViewing: false
          })
        )
      })
    })
  }
  
  render() {
    const { data, hash } = this.props;
    const { isDownloading, isSubmitting, isViewing, tooltipOpen } = this.state;

    if(data && data.length > 0) {
      return <>
        {this.renderDroppedTextModal()}
        <ReportSection title="Dropped files">
          <Table size="sm" responsive bordered striped>
            <thead>
              <tr>
                <td className={style.tableHeader}>Path list</td>
                <td className={style.tableHeader}>Information</td>
                <td className={style.tableHeader}>Size</td>
                <td className={style.tableHeader}>Actions</td>
              </tr>
            </thead>
            <tbody>
            {
              data.map((dropped, idx) => {
                let textIcon = null;

                if ((dropped.file_size.endsWith('KB') || dropped.file_size.endsWith(' B')) && // check for the right size
                dropped.file_type.indexOf('ASCII text') > -1) { // check for the right type
                  textIcon = isViewing ? <FontAwesomeIcon icon={faSpinner} spin /> :
                    <FontAwesomeIcon icon={faAlignJustify}
                      onClick={() => this.showDroppedText(dropped.sha512)}
                      title="View the file"
                    />;
                }

                return <tr key={idx}>
                  <td>{!!dropped.path_list && dropped.path_list.map((path, idx) => <p className="m-0" key={idx}>{path}</p>)}</td>
                  <td>
                    <p className="mb-0"><strong>Type:</strong> {dropped.file_type}</p>
                    {dropped.md5 && <p className="mb-0"><strong>MD5:</strong> {dropped.md5}</p>}
                  </td>
                  <td>{dropped.file_size}</td>
                  <td className={style.downloadDroppedFile}>
                    {
                      isDownloading ? <FontAwesomeIcon icon={faSpinner} spin /> :
                      <FontAwesomeIcon
                        icon={faFileDownload}
                        onClick={() => { this.downloadSample(dropped.sha512) }}
                        title="Download file"
                      />
                    }
                    {
                      hash === dropped.md5 ? <>
                        <FontAwesomeIcon
                          icon={faInfoCircle}
                          id={`InfoIcon_${dropped.md5}`}
                        />
                        <Tooltip
                          placement="left"
                          isOpen={tooltipOpen}
                          target={`InfoIcon_${dropped.md5}`}
                          toggle={this.toggleTooltip}
                          delay={{
                            show: 0,
                            hide: 50
                          }}
                        >
                          The sample dropped itself
                        </Tooltip>
                      </> : isSubmitting ? <FontAwesomeIcon icon={faSpinner} spin /> :
                      <FontAwesomeIcon
                        icon={faCloudUploadAlt}
                        onClick={() => { this.submitDroppedFiles(dropped.sha512) }}
                        title="Submit file for the analysis"
                      />
                    }
                    {textIcon}
                  </td>
                </tr>
              })
            }
            </tbody>
          </Table>
        </ReportSection>
      </>
    } else {
      return <FullTitle title="No data available" />;
    }
  }
}