import React from 'react';

import PeStatic from './Statics/pe/index.jsx';
import OleStatic from './Statics/ole/index.jsx';
import RtfStatic from './Statics/rtf/index.jsx';
import JsStatic from './Statics/js/index.jsx';
import WsfStatic from './Statics/wsf/index.jsx';
import OoXMLStatic from './Statics/ooxml/index.jsx';
import PdfStatic from './Statics/pdf/index.jsx';

import FullTitle from '../../../components/FullTitle';
import ReportSection from '../../../components/ReportSection';
import { reportVersionMapping } from '../../../constants/sandbox';

export default class Static extends React.Component {
  render() {
    const { data, sampleType, sampleExt } = this.props;

    if(data && sampleType && sampleExt) {
      const { data: reportData, report_version } = data;
      let staticAnalysisContent = null;

      const componentType = sampleType === 'ascii' ? sampleExt : sampleType;
      const componentVersion = reportVersionMapping.static[componentType][report_version];

      switch(sampleType) {
        case 'pe':
          staticAnalysisContent = <PeStatic data={reportData} report_version={componentVersion} />;
          break;
        case 'ole':
          staticAnalysisContent =  <OleStatic data={reportData} report_version={componentVersion} />;
          break;
        case 'pdf':
          staticAnalysisContent = <PdfStatic data={reportData} report_version={componentVersion} />;
          break;
        case 'ooxml':
          staticAnalysisContent =  <OoXMLStatic data={reportData} report_version={componentVersion} />;
          break;
        case 'rtf':
          staticAnalysisContent =  <RtfStatic data={reportData} report_version={componentVersion} />;
          break;
        case 'js':
          staticAnalysisContent = <JsStatic data={reportData} report_version={componentVersion} />;
          break;
        case 'wsf':
          staticAnalysisContent = <WsfStatic data={reportData} report_version={componentVersion} />;
          break;
        case 'ascii':
          switch(sampleExt) {
            case 'js': 
              staticAnalysisContent = <JsStatic data={reportData} report_version={componentVersion} />;
              break;
            case 'wsf':
              staticAnalysisContent = <WsfStatic data={reportData} report_version={componentVersion} />;
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }

      return <ReportSection title="Static Analysis">
        {staticAnalysisContent}
      </ReportSection>
    }

    return <FullTitle title="No relevant data available" />;
  }
}