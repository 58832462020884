import React from 'react';
import ReportSection from '../../../components/ReportSection';
import FullTitle from '../../../components/FullTitle';

import _ from 'underscore';
import { CardDeck, Table } from 'reactstrap';
import DownloadFile from '../../../components/DownloadFile';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

import { fetchCrashDump } from '../../../services/download';
import download from 'downloadjs';
import { localizeDate } from '../../../utils/dates';

export default class Diagnostics extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      crashDumpDownloading: false
    }
  }

  downloadCrashdump = platform => {
    const { hash } = this.props;
    this.setState({
      crashDumpDownloading: true
    }, () => {
      fetchCrashDump(platform, hash).then(response => {
        return response.blob();
      }).then(myBlob => {
        this.setState({
          crashDumpDownloading: false
        }, () => {
          return download(myBlob, `${hash}.${platform}.dmp`, 'application/octet-stream');
        });
      });
    });
  }

  renderInfo = (title, info) =>
  !!info.length && <ReportSection title={`Diagnostics level: ${title}`}>
    <Table size="sm" responsive bordered striped>
      <tbody>
        <tr>
          {title === "Info" && <th>Title</th>}
          <th>Message</th>
          <th>Platform</th>
        </tr>
      {info.map((i, idx) => <tr key={idx}>
        {title === "Info" && <td>{i.title.replace(/_/g, " ")}</td>}
        <td>
          {
            _.isString(i.message) ? 
            i.title === 'task_created_at' || i.title === 'task_completed_at' ? localizeDate(i.message) :
            i.message : 
            JSON.stringify(i.message)
          }
        </td>
        <td>{!!i.platform && i.platform.replace("_", " ").replace(/^\w/, c => c.toUpperCase())}</td>
        </tr>)}
      </tbody>
    </Table>
  </ReportSection>

  render() {
    const { data } = this.props;

    if(data) {
      const { crashdump, info, warnings, errors } = data;
      const { crashDumpDownloading } = this.state;

      return <>
      { this.renderInfo("Info", info) }
      { this.renderInfo("Warning", warnings) }
      { this.renderInfo("Error", errors) }
      {
        !!crashdump.length && <ReportSection title="Crashdump">
          <CardDeck>
            {crashdump.map(platform => {
              return <DownloadFile
                key={platform}
                icon={faExclamationTriangle}
                color="warning"
                downloading={crashDumpDownloading}
                label={`Download - ${platform}`}
                onClick={() => this.downloadCrashdump(platform)}
              />
            })}
          </CardDeck>
        </ReportSection>
      }
      {
        !crashdump.length && !info.length && !errors.length && !warnings.info && <FullTitle title="No data available" />
      }
      </>;
    } else {
      return null;
    }
  }
}