/* eslint-disable no-underscore-dangle, no-empty-function */

/**
 * A class that presents a stream of data that can be
 * subscribed to
 */
class Observable {
  /**
   * Creates a new instance of an `Observable` that
   * streams data
   * @param {object} subscribe An object with `next`, `error`, and `complete` functions
   */
  constructor(subscribe) {
    this._subscribe = subscribe;
  }

  /**
   * Informs the observable that it should start streaming
   * data to the given observer
   * @param {function} next A function to call when the next value streams
   * @param {function} error A function to call when the stream encounters an error
   * @param {function} complete A function to call when the stream is completed
   */
  subscribe(next, error, complete) {
    if (typeof next === 'function') {
      return this._subscribe({
        next,
        error: error || (() => { }),
        complete: complete || (() => { })
      });
    }
    return this._subscribe(next);
  }
}

export {
  Observable
};
