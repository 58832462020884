import _ from 'underscore';
import { authedPostRequest } from '../utils/request';
import moment from 'moment';

const timezoneOffset = moment().format("Z");

export const fetchSubmissions = (userId, sorted, filtered, extraSearch) => {
  const options = {};

  if(userId)
    options.user = parseInt(userId)

  const filename = _.findWhere(filtered, {id: 'filename'});
  if(filename) options.filename = filename.value;

  const md5 = _.findWhere(filtered, {id: 'md5'});
  if(md5) options.md5 = md5.value;

  const result = _.findWhere(filtered, {id: 'result'});
  if(result) options.result = [result.value];
  
  const format = _.findWhere(filtered, {id: 'format'});
  if(format && format.value) {
    const formatFilters = format.value.split("|");
    if(formatFilters[0]) options.format = [formatFilters[0]];
    if(formatFilters[1]) options.ext = [formatFilters[1]];
  }

  const status = _.findWhere(filtered, {id: 'status'});
  if(status) options.status = [status.value];

  const platform = _.findWhere(filtered, {id: 'platforms'});
  if(platform?.value) {
    const platformFilters = platform.value.split("|");
    if(platformFilters[0]) options.analysis = platformFilters[0];
    if(platformFilters[1]) options.platform = platformFilters[1];
  }

  const last_scan = _.findWhere(filtered, {id: 'last_scan'});
  if(last_scan?.value) {
    const lastScanFilters = last_scan.value.split("|");
    if(lastScanFilters[0] || lastScanFilters[1]) options.last_scan = { time_zone: timezoneOffset };
    if(lastScanFilters[0]) options.last_scan.gte = lastScanFilters[0] + ' 00:00:00';
    if(lastScanFilters[1]) options.last_scan.lte = lastScanFilters[1] + ' 23:59:59';
  }

  const timestamp = _.findWhere(filtered, {id: 'timestamp'});
  if(timestamp?.value) {
    const lastSumbmissionFilters = timestamp.value.split("|");
    if(lastSumbmissionFilters[0] || lastSumbmissionFilters[1]) options.timestamp = { time_zone: timezoneOffset };
    if(lastSumbmissionFilters[0]) options.timestamp.gte = lastSumbmissionFilters[0] + ' 00:00:00';
    if(lastSumbmissionFilters[1]) options.timestamp.lte = lastSumbmissionFilters[1] + ' 23:59:59';
  }

  if(sorted?.length) {
    options.sort_by = sorted[0].id;
    options.reverse = !sorted[0].desc;
  }

  if(extraSearch?.currentKey && extraSearch?.currentValue) {
    options.extra = {};
    options.extra[extraSearch.currentKey] = extraSearch.currentValue;
    // options.extra = extraSearch;
  }

  return authedPostRequest('sandbox/sample/submissions', { body: options }, true );
}

export const fetchSamplesList = (page_size, page, sorted, filtered, extraSearch) => {

  if (page_size && page_size < 25)
    page_size = 25;

  const options = {
    page,
    page_size
  };

  const filename = _.findWhere(filtered, {id: 'filename'});
  if(filename) options.filename = filename.value;

  const md5 = _.findWhere(filtered, {id: 'md5'});
  if(md5) options.md5 = md5.value;

  const result = _.findWhere(filtered, {id: 'result'});
  if(result) options.result = result.value;
  
  const format = _.findWhere(filtered, {id: 'format'});
  if(format && format.value) {
    const formatFilters = format.value.split("|");
    if(formatFilters[0]) options.format = formatFilters[0];
    if(formatFilters[1]) options.ext = formatFilters[1];
  }

  const status = _.findWhere(filtered, {id: 'status'});
  if(status) options.status = status.value;

  const platform = _.findWhere(filtered, {id: 'platforms'});
  if(platform?.value) {
    const platformFilters = platform.value.split("|");
    if(platformFilters[0]) options.analysis = platformFilters[0];
    if(platformFilters[1]) options.platform = platformFilters[1];
  }

  const last_scan = _.findWhere(filtered, {id: 'last_scan'});
  if(last_scan?.value) {
    const lastScanFilters = last_scan.value.split("|");
    if(lastScanFilters[0] || lastScanFilters[1]) options.last_scan = { time_zone: timezoneOffset };
    if(lastScanFilters[0]) options.last_scan.gte = lastScanFilters[0] + ' 00:00:00';
    if(lastScanFilters[1]) options.last_scan.lte = lastScanFilters[1] + ' 23:59:59';
  }

  const last_submission = _.findWhere(filtered, {id: 'last_submission'});
  if(last_submission?.value) {
    const lastSumbmissionFilters = last_submission.value.split("|");
    if(lastSumbmissionFilters[0] || lastSumbmissionFilters[1]) options.last_submission = { time_zone: timezoneOffset };
    if(lastSumbmissionFilters[0]) options.last_submission.gte = lastSumbmissionFilters[0] + ' 00:00:00';
    if(lastSumbmissionFilters[1]) options.last_submission.lte = lastSumbmissionFilters[1] + ' 23:59:59';
  }

  if(sorted?.length) {
    options.sort_by = sorted[0].id;
    options.reverse = !sorted[0].desc;
  }

  if(extraSearch?.currentKey && extraSearch?.currentValue) {
    options.extra = {};
    options.extra[extraSearch.currentKey] = extraSearch.currentValue;
    // options.extra = extraSearch;
  }

  return authedPostRequest('sandbox/sample/list', { body: options } );
}

export const fetchSampleSubmissionsList = (sha512) => {
  const body = {
    sha512
  };

  return authedPostRequest('sandbox/sample/submissions', { body }, true);
}
  