import React, { Component } from 'react';
import { connect } from 'react-redux';
import { handleError } from '../../services/errorHandler';
import { fetchApiKey, renewApiKey } from '../../services/users';
import { statusSuccess } from '../../constants/api';

import {
  Button,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from 'reactstrap';

class ProfileApiKey extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalRenew: false,
      modalLogout: false,
      user: {
        apiKey: ''
      }
    }

    this.getDetails = this.getDetails.bind(this);
    this.doRenewApikey = this.doRenewApikey.bind(this);
  }

  getDetails() {
    const { token } = this.props.auth;

    fetchApiKey(token)
    .then(res => {
      if(res.status === statusSuccess) {
        this.setState({
          user: {
            apiKey: res.data
          }
        })
      } else {
        handleError('Error', 'Error retrieving user information.');
      }
    })
    .catch(err => {
      handleError('Error', 'Error retrieving user information.');
    })
  }

  doRenewApikey() {
    const { user } = this.state;

    this.toggleRenew();

    renewApiKey().then(res => {
      this.setState({
        user: {
          ...user,
          apiKey: res.data
        }
      }, () => {
        this.toggleLogout();
      });
    })
    .catch(err => {
      handleError('Error', 'Error creating a new api key.');
    });
  }

  toggleRenew = e => {
    if(e) e.preventDefault();

    this.setState({
      modalRenew: !this.state.modalRenew
    });
  }

  toggleLogout = e => {
    if(e) e.preventDefault();

    this.setState({
      modalLogout: !this.state.modalLogout
    });
  }

  componentDidMount() {
    this.getDetails();
  }

  renderModalRenew = () => {
    return (
      <Modal isOpen={this.state.modalRenew} toggle={this.toggleRenew}>
        <ModalHeader toggle={this.toggleRenew}>Regenerate your Api key</ModalHeader>
        <ModalBody>
          Do you think your personal api key has been compromised?<br />
          Click the button below to create a new one.<br /><br />
          <strong>Please notice that this action will log you out.</strong>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.doRenewApikey}>Create a new Api key</Button>
        </ModalFooter>
      </Modal>
    );
  }

  renderModalLogout = () => {
    const { push } = this.props.history;
    const { apiKey } = this.state.user;

    return (
      <Modal isOpen={this.state.modalLogout} toggle={() => { push('/auth/logout') }} size="lg">
        <ModalHeader toggle={() => { push('/auth/logout') }}>Your Api key as been regenerated</ModalHeader>
        <ModalBody>
          Your new Api key is <strong>{apiKey}</strong>.<br />
          You have to login again to continue using our services.
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => { push('/auth/logout') }}>Continue</Button>
        </ModalFooter>
      </Modal>
    );
  }

  render() {
    return (
      <Container>
        {this.renderModalRenew()}
        {this.renderModalLogout()}
        <h3>Manage Api key</h3>
        <Form>
          <FormGroup>
            <Label for="exampleEmail">Api key</Label>
            <Input type="text" name="userApikey" id="userApikey" disabled value={this.state.user.apiKey} />
          </FormGroup>
          <Button color="warning" onClick={this.toggleRenew}>Click here if you want to regenerate your Api key</Button>
        </Form>
      </Container>
    );
  }
};

export default connect(
  state => ({
    auth: state.auth
  })
)(ProfileApiKey);
