import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Badge } from 'reactstrap';

import style from './style.scss';

export default class ActivityBadge extends React.Component {
  render() {
    const { idx, value, info } = this.props;
    let { label } = this.props;

    label = label !== undefined ? label : value;

    return (
      <Badge
        className={classnames(style.activityBadge, info && style.info)}
        id={idx}
      >
        {label}
      </Badge>
    );
  }
}

ActivityBadge.propTypes = {
  idx: PropTypes.string,
  label: PropTypes.string,
  info: PropTypes.bool
};