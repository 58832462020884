import React, { Component } from 'react';
import { connect } from 'react-redux';

import { updateUserDataOnLogin } from '../../actions/auth';
import { updateToolsDataOnLogin } from '../../actions/tools';

import { fetchUser } from '../../services/users';
import { fetchSandboxVersion } from '../../services/sandbox';
import { statusSuccess } from '../../constants/api';
import { handleError } from '../../services/errorHandler';
import Loading from '../Loading';

class Login extends Component {
  componentDidMount() {
    const newToken = this.props.match.params.token;
    const { updateUserDataOnLogin,  updateToolsDataOnLogin } = this.props;

    if(newToken) {
      fetchUser(newToken).then(res => {
      if(res.status === statusSuccess) {
        res = {
          ...res.data,
          token: newToken
        }
        updateUserDataOnLogin(res);
      } else {
        handleError('Error', 'Error retrieving user information');
      }
      }).then(() => {
        fetchSandboxVersion().then(res =>
          res.text().then(response => updateToolsDataOnLogin(response))
        )
      }).then(() => {
        const { lastVisitedPage } = this.props.tools;

        if(lastVisitedPage && lastVisitedPage.search('logout') === -1) {
          this.props.history.push(lastVisitedPage)
        } else {
          this.props.history.push('/')
        }
      });
    } else {
      this.props.history.push('/');
    }
  }

  render() {
    return <Loading />;
  }
};

export default connect(
  store => ({
    auth: store.auth,
    tools: store.tools
  }),
  dispatch => ({
    updateUserDataOnLogin: data => dispatch(updateUserDataOnLogin(data)),
    updateToolsDataOnLogin: data => dispatch( updateToolsDataOnLogin(data))
  })
)(Login);