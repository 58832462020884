import base64 from 'base-64';

export const parseStats = stats =>
  Object.entries(stats).filter(el => el[1] > 0).map(el => ({ name: el[0], value: el[1]}))

  export const isEmptyArray = array => {
    let emptyArray = true;

    Object.entries(array).map((strings) => {
      if(strings[1].length > 0) {
        emptyArray = false;
      }
      return null;
    })

    return emptyArray;
  }
export const parseCode = (code, toDebase = false) => {
  if(toDebase) {
    code = base64.decode(code);
  }
  return code.replace(/\\r\\n/g, '\r\n').replace(/\\n/g, '\r\n').replace(/\\r/g, '\r\n').replace(/\\t/g, '  ');
}
