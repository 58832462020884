import React from 'react';
import { connect } from 'react-redux';

import { setInjectedProcess } from '../../../../actions/sandbox';
import DynamicProcess from './Process/1.0';
import ReportSection from '../../../../components/ReportSection';
import FullTitle from '../../../../components/FullTitle';

class DynamicAnalysis10 extends React.Component {
  renderProcesses = (processes, strings) => <ReportSection title="Processes">
    { processes.map((process, idx) => {
      const { platform, hash: mainHash, dropped } = this.props;
      const {
        path,
        pid, hash,
        version, process_activities,
        process_information,
        injected_threads_activities,
        md5_strings
      } = process;

      return <DynamicProcess
        strings={strings}
        md5_strings={md5_strings}
        hash={hash}
        mainHash={mainHash}
        platform={platform}
        path={path}
        pid={pid}
        version={version}
        process_activities={process_activities}
        process_information={process_information}
        key={idx}
        idx={idx}
        injected_threads_activities={injected_threads_activities}
        dropped={dropped}
      />;
      })
    }
  </ReportSection>

  componentDidMount() {
    this.props.setInjectedProcess(null)
  }

  render() {
    const { processes, strings } = this.props.data;

    return <>
      {!!processes && !!processes.length ? this.renderProcesses(processes, strings) : <FullTitle title="No relevant data available" />}
    </>
  }
} 


export default connect(
  state => ({
    currentInjectedProcess: state.sandbox.injectedProcess
  }),
  dispatch => ({
    setInjectedProcess: data => dispatch(setInjectedProcess(data))
  })
)(DynamicAnalysis10);
