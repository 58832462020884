import React, { Component } from 'react';
import SparkMD5 from 'spark-md5';
import ChunkedFileReader from 'chunked-file-reader';
import { Link } from 'react-router-dom';

import {
  Button
} from 'reactstrap';

import Badge from '../Badge';

import { sampleReportStatus } from '../../services/sandbox';
import { checkAnalysisInterval } from '../../constants/sandbox';
import {
  statusSuccess,
  analysisCompleted,
  analysisFailed,
  statusProcessing
 } from '../../constants/api';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle, faFileUpload } from '@fortawesome/free-solid-svg-icons';

import ProgressCircle from '../ProgressCircle';
import styles from './styles.scss';
import { handleError } from '../../services/errorHandler';

function preventDefault(e) {
  e.preventDefault();
}

const INITIAL_STATE = {
  file: null,
  fileError: null,
  isDragActive: false,
  isUploading: false,
  isUploadComplete: false,
  percentage: 0,
  uploadError: null,
  currentMd5: false,
  analysisStatus: false
};

/**
 * A Component that provides a target to drag/drop or click to
 * select a file to upload
 */
export default class FileUpload extends Component {
  constructor(props) {
    super(props);

    this.state = INITIAL_STATE;
    this.interval = null;

    this.resetComponent = this.resetComponent.bind();
  }

  getInputRef = el => this.inputRef = el;
  getTargetRef = el => this.targetRef = el;

  componentDidMount() {
    if (!this.props.allowDocumentDrop) {
      document.addEventListener('dragover', preventDefault);
      document.addEventListener('drop', this.onDocumentDrop);
    }
  }

  componentWillUnmount() {
    if (!this.props.allowDocumentDrop) {
      document.removeEventListener('dragover', preventDefault);
      document.removeEventListener('drop', this.onDocumentDrop);
    }

    if(this.interval)
      clearInterval(this.interval);
  }

  resetComponent = () => {
    if(this.interval)
      clearInterval(this.interval);
    
    this.setState(INITIAL_STATE);
  }

  render() {
    const { analysisStatus } = this.state;
    return (
      <div className={styles.container}>
      { analysisStatus ?
        <>{ this.renderAnalysisStatus() }</> : 
        <>{this.renderFileTarget()} {this.renderFileInfo()}</>
      }
      </div>
    );
  }
  
  renderAnalysisStatus() {
    const { currentMd5, analysisStatus } = this.state;

    return <div>
      <p>Upload is complete. Analysis status: <Badge value={analysisStatus} /></p>
      {
        [analysisCompleted, analysisFailed].indexOf(analysisStatus) > -1 ? 
        <p>You can find the report <strong><Link to={`/sandbox/report/${currentMd5}/`}>here</Link></strong>.</p> :
        null
      }
      {
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <p>Do you want to upload a new sample? Click <a href="#" onClick={this.resetComponent}>here</a>.</p>
      }
    </div>;
  }
  
  renderFileTarget() {
    const { props, state } = this;
    const { accept, disabled, name } = props;
    const { file, isDragActive, isUploading, isUploadComplete } = state;
    const dropTargetClasses = [styles.dropTarget];
    if (isDragActive) dropTargetClasses.push(styles.active);

    let acceptedFileTypes = accept;
    if (accept instanceof Array) {
      acceptedFileTypes = accept.join(',');
    }

    const buttonText = file ? 'Choose A Different File' : 'Choose a File';

    return (
      <div className={styles.dropTargetContainer}>
        <div
          className={dropTargetClasses.join(' ')}
          onClick={this.onTargetClick}
          onDragEnter={this.onTargetDragEnter}
          onDragOver={this.onTargetDragOver}
          onDragLeave={this.onTargetDragLeave}
          onDrop={this.onDrop}
          ref={this.getTargetRef}
        >
          <div className={styles.topAndBottomBorders}></div>
          <div className={styles.leftAndRightBorders}></div>
          {this.renderDropTargetContent()}
        </div>
        <input
          accept={acceptedFileTypes}
          className={styles.input}
          disabled={disabled}
          multiple={false}
          name={name}
          onChange={this.onDrop}
          ref={this.getInputRef}
          type="file"
        />
        {!isUploading && !isUploadComplete && <Button onClick={this.onTargetClick}>{buttonText}</Button>}
      </div>
    );
  }

  renderDropTargetContent() {
    const { file, fileError } = this.state;
    
    const fileName = file && file.name && file.name.length > 25 ? `${file.name.slice(0, 25)}...` :
      file && file.name ? file.name : null;

    if (fileError && file) {
      return (
        <div className={styles.dropTargetContent}>
          <FontAwesomeIcon icon={faExclamationCircle} />
          <br />
          <span>{fileName} is invalid</span>
          <span className={styles.uploadText}>Drag a Valid File to Upload</span>
        </div>
      );
    } else if (file) {
      const fileSize = this.getFileSize(file.size);
      return (
        <div className={styles.dropTargetContent}>
          <FontAwesomeIcon icon={faFileUpload} />
          <br />
          <span className={styles.uploadText}>{fileName}</span><br />
          <span>{fileSize}</span>
        </div>
      );
    } else {
      return (
        <div className={styles.dropTargetContent}>
          <FontAwesomeIcon icon={faFileUpload} />
          <br />
          <span className={styles.uploadText}>Drag a File to Upload</span>
        </div>
      );
    }
  }

  renderFileInfo() {
    const { file, fileError, uploadError } = this.state;
    const { /*accept, */ maxSize } = this.props;

    // Create a comma delimited string of file types
    // let acceptedFileTypes = accept;
    // if (accept) {
    //   if (accept instanceof Array) {
    //     acceptedFileTypes = accept.map(fileType => fileType.replace(/\.|\/\*/g, '')).join(', ');
    //   } else {
    //     acceptedFileTypes = accept.replace(/\.|\/\*/g, '');
    //   }
    // } else {
    //   acceptedFileTypes = 'Any';
    // }
    // const fileTypeRequirement = (
    //   <div className={styles.fileReqSection}>
    //     <span className={`${styles.fileInfoLabel} ${styles.fileTypeLabel}`}>File Type:</span>
    //     <span className={styles.fileType}>{acceptedFileTypes}</span>
    //   </div>
    // );

    // File Size
    const fileSizeRequirement = maxSize && maxSize !== Infinity ? (
      <div className={styles.fileReqSection}>
        <span className={`${styles.fileInfoLabel} ${styles.fileTypeLabel}`}>File Size:</span>
        <span className={styles.fileSizeReq}>{this.getFileSize(maxSize)} (maximum)</span>
      </div>
    ) : null;

    const selectedFileText = file && (fileError || uploadError) ? 'File Error' : 'Selected File';

    return (
      <div className={styles.informationContainer}>
        {fileSizeRequirement}
        {
          file ?
            (
              <>
                <div className={styles.fileSectionSeparator}></div>
                <h5 className={styles.fileInfoLabel}>{selectedFileText}:</h5>
                <div className={styles.fileActionSection}>
                  {this.renderFileProgress()}
                </div>
              </>
            ) : null
        }
      </div>
    );
  }

  renderFileProgress() {
    const { file, fileError, isUploadComplete, percentage, uploadError } = this.state;
    const { uploadButtonText } = this.props;
    
    const fileName = file && file.name && file.name.length > 25 ? `${file.name.slice(0, 25)}...` :
    file && file.name ? file.name : null;

    if (percentage) {
      return (
        <>
          <div className={styles.progressContainer}>
            <ProgressCircle percentage={percentage} strokeWidth={4} size={36} fontSize={10} />
          </div>
          {
            percentage === 100 && isUploadComplete ?
              <span>{fileName} has been uploaded successfully.<br />Wait a moment...</span> :
              <span>{fileName} is uploading</span>
          }
        </>
      );
    } else if (fileError) {
      return (
        <>
          <span>
            <FontAwesomeIcon icon={faExclamationCircle} />
            {fileError}
          </span>
          <div className={styles.buttonPane}>
            <Button onClick={this.onClearFile}>Clear</Button>
          </div>
        </>
      );
    } else if (uploadError) {
      return (
        <>
          <span>
            <FontAwesomeIcon icon={faExclamationCircle} />
            {uploadError}
          </span>
          <div className={styles.buttonPane}>
            <Button onClick={this.onClearFile}>Clear</Button>
          </div>
        </>
      );
    } else {
      return (
        <>
          <span>{fileName}</span>
          <div className={styles.buttonPane}>
            <Button onClick={this.onClearFile}>Clear</Button>
            <Button color="primary" onClick={this.onUploadClick}>{uploadButtonText || 'Upload'}</Button>
          </div>
        </>
      );
    }
  }

  /**
   * Handles an event to clear a file
   */
  onClearFile = () => {
    this.inputRef.value = null;
    this.setState({
      file: null
    });
  }

  /**
   * Handles an event when a file is dropped on the
   * document
   * @param {Event} e The event
   */
  onDocumentDrop = e => {
    const { target } = e;
    e.preventDefault();
    if (this.targetRef === target || this.targetRef.contains(target)) {
      return;
    }
  }

  /**
   * Handles a click event for the target; programmatically
   * opens the file browser
   * @param {Event} e The event
   */
  onTargetClick = e => {
    e.preventDefault();
    e.stopPropagation();

    setTimeout(() => this.openFileBrowser());
  }

  /**
   * Handles a dragenter event for the target; starts
   * the drag/drop states
   */
  onTargetDragEnter = () => {
    this.setState({
      isDragActive: true
    });
  }

  /**
   * Handles a dragleave event for the target, stops
   * the drag/drop states
   */
  onTargetDragLeave = e => {
    e.preventDefault();
    this.setState({
      isDragActive: false
    });
  }

  /**
   * Handles a dragover event for the target; sets the
   * drop effect
   * @param {Event} e The event
   */
  onTargetDragOver = e => {
    e.preventDefault();
    e.stopPropagation();

    try {
      e.dataTransfer.dropEffect = 'copy';
    } catch (err) {
      // Continue
    }

    return false;
  }

  /**
   * Handles a drop event; stores the file if it is valid
   * or shows an error if it is not
   * @param {Event} e The event
   */
  onDrop = e => {
    e.preventDefault();
    let fileError = null;
    let file = this.getFile(e);
    if (file) {
      if (!this.isFileValid(file)) {
        fileError = `Invalid file type (${file.type})`;
      } else if (!this.isFileAppropriateSize(file)) {
        fileError = `The file's size (${this.getFileSize(file.size)}) exceeds the maximum limit`;
      }
    }

    this.setState({
      file,
      fileError,
      isDragActive: false,
      uploadError: null
    });
  }

  /**
   * Handles a click event for the button to upload the
   * selected file
   */
  onUploadClick = () => {
    const { file } = this.state;
    const { onUpload, onUploadError } = this.props;

    const spark  = new SparkMD5.ArrayBuffer();
    const reader = new ChunkedFileReader({ maxChunkSize: 2*1024*1024 });

    reader.subscribe('chunk', function (e) {
      spark.append(e.chunk);
    });

    reader.subscribe('end', () => {
      const currentMd5 = spark.end();

      if (onUpload) {
        this.setState({
          isUploading: true,
          percentage: 1,
          currentMd5
        }, () => {
          // Wait for the state to set to show the progress,
          // then start the upload
          onUpload(file).subscribe(
            ({ percentage, response }) => {
              if (percentage === 100 && response) {
                this.setState({
                  fileError: null,
                  isUploading: false,
                  isUploadComplete: true,
                  percentage: 100,
                  uploadError: null
                });

                this.onUploadComplete(response);
              } else {
                this.setState({
                  percentage: percentage || 0,
                  uploadError: null
                });
              }
            },
            err => {
              this.setState({
                isUploading: false,
                percentage: 0,
                uploadError: err.errmsg || 'An error occurred when uploading'
              });
              if (onUploadError) {
                onUploadError(err);
              }
            }
          );
        });
      }
    });

    reader.readChunks(file);
  }

  onUploadComplete(response) {
    if(response.status === statusSuccess || response.status === statusProcessing) {
      const { analysisStatus } = this.state;

      const { sha512 } = response.notes;
      this.interval = setInterval(() => {
        sampleReportStatus(sha512).then(res => {
          if(res.data) {
            if(analysisStatus !== res.data.generic.info.analysis_status) {
              this.setState({
                analysisStatus: res.data.generic.info.analysis_status,
                currentMd5: res.data.generic.info.md5,
              });
            }

            if(res.data.generic.info.analysis_status === analysisCompleted) {
              clearInterval(this.interval);
            }
          } else {
            this.setState({
              analysisStatus: 'not found'
            });
          }
        });
      }, checkAnalysisInterval);
    } else {
      handleError('Error', 'Error uploading file');
    }

  }

  /**
   * Gets a single file out of a given event
   * @param {Event} e The event
   * @returns {File} A file
   */
  getFile(e) {
    const { dataTransfer, target } = e;
    let file;
    if (dataTransfer) {
      const files = dataTransfer.files || dataTransfer.items;
      if (files && files.length) {
        file = files[0];
      }
    } else if (target && target.files) {
      file = target.files[0];
    }
    return file;
  }

  /**
   * Gets a readable format in KB or MB for a file's
   * size
   * @param {number} size A file's size
   * @returns {string} A readable file size
   */
  getFileSize(size) {
    let fileSize = Math.round(size / 1000);
    let units = 'KB';
    if (fileSize > 1500) {
      fileSize = Math.round((fileSize / 1000) * 100) / 100;
      units = 'MB';
    }
    return `${fileSize}${units}`;
  }

  /**
   * Checks if a file is within the min and max files sizes
   * @param {File} file A file
   * @returns {boolean} True if acceptable, false if not
   */
  isFileAppropriateSize(file) {
    const { maxSize, minSize } = this.props;
    const fileSize = file.size;
    return fileSize >= minSize && fileSize <= maxSize;
  }

  /**
   * Checks if a file is valid based on its type
   * @param {File} file A file
   */
  isFileValid(file) {
    const { accept } = this.props;

    // If there is no accept prop, assume all files
    // are valid types
    if (!accept) return true;

    // Split accepted file types into an array so they
    // can be checked individually
    let acceptedFileTypes = accept;
    if (typeof accept === 'string') {
      acceptedFileTypes = accept.split(',');
    }

    // Check the file against each acceptable file types
    let retVal = false;
    const fileType = file.type || '';
    const mimeType = fileType.replace(/\/.*&/, '');
    const totalTypes = acceptedFileTypes.length;
    for (let i = 0; i < totalTypes; i++) {
      const type = acceptedFileTypes[i];
      if (type.charAt(0) === '.') {
        // Example: .xml
        retVal = file.name.toLowerCase().endsWith(type.toLowerCase());
      } else if (type.endsWith('/*')) {
        // Example: image/*
        // Check for just the first part (ie: image)
        retVal = mimeType.split('/')[0] === type.split('/')[0];
      } else {
        // Needs exact match
        retVal = mimeType === type;
      }

      // If it meets at least one valid file type, stop checking
      if (retVal === true) {
        break;
      }
    }

    return retVal;
  }

  /**
   * Programatically clicks the file browser input so the
   * OS file explorer opens
   */
  openFileBrowser() {
    this.inputRef.value = null;
    this.inputRef.click();

    // If a file was already uploaded successfully and
    // they click to open the file browser again, clear
    // out the display of the uploaded file
    if (this.state.percentage === 100) {
      this.setState({
        percentage: 0,
        file: null,
        fileError: null,
        isUploadComplete: false,
        uploadError: null
      });
    }
  }

  /**
   * Resets the component
   */
  reset() {
    this.inputRef.value = null;
    this.setState(INITIAL_STATE);
  }
}

FileUpload.defaultProps = {
  allowDocumentDrop: false,
  chooseButtonText: null,
  disabled: false,
  maxSize: Infinity, // in bytes
  minSize: 0, // in bytes
  uploadButtonText: 'Upload'
};
