import React, { Fragment } from 'react';
import _ from 'underscore';
import classnames from 'classnames';
import CodeHighlighter from '../../../../../components/CodeHighlighter';
import { Table, TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';

import FlagIcon from '../../../../../components/FlagIcon';

import style from '../../style.scss';

const PeStaticSections = [
  'info',
  'versioninfo',
  'digital_signature',
  'imports',
  'exports',
  'sections',
  'resources'
];

export default class PeStatic10 extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);

    this.state = {
      activeTab: 0
    }
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
         activeTab: tab
      });
    }
  }


  renderInfo = (title, info) =>
  !!info.length && <Table size="sm" responsive bordered striped>
    <tbody>
      {info.map((i, idx) =>
        <tr key={idx}>
          <td className={style.title}>{i.key}</td>
          <td>
          {
              typeof i.value === 'boolean' ? <FlagIcon flag={i.value} /> : 
              i.key === 'FirstInstructions' ? <CodeHighlighter code={i.value} /> :
              i.value
          }
          </td>
        </tr>
      )}
    </tbody>
  </Table>

  renderExports = exports =>
  !!exports.length && <Table size="sm" responsive bordered striped>
    <thead>
      <tr>
        <td className={style.title}><strong>Name</strong></td>
        <td className={style.title}><strong>Address</strong></td>
        <td className={style.title}><strong>Ordinal</strong></td>
      </tr>
    </thead>
    <tbody>
      {exports.map((ex, idx) => 
        <tr key={idx}>
          <td>{ex.name}</td>
          <td>{ex.address}</td>
          <td>{ex.ordinal}</td>
        </tr>
      )}
    </tbody>
  </Table>

  renderSection = (sections) => !!sections.length && sections.map((section, idx) => 
    <Table size="sm" responsive bordered striped key={idx}>
      <tbody>
      { Object.entries(section).map((s, idx) => <tr key={idx}>
        <td className={style.title}>{s[0].replace(/_/g, " ")}</td>
        <td>{s[1]}</td>
      </tr> )}
    </tbody>
  </Table>)

  renderImports = (imports, imphash) => !!imports.length && <>
    {!!imphash && <p><strong>Imphash</strong>: {imphash}</p>}
    {imports.map((imp, idx) => <Table size="sm" responsive bordered striped key={idx}>
      <tbody>
        <tr>
          <td className={style.title}>Module name</td>
          <td>{imp.module_name}</td>
        </tr>
        <tr>
          <td className={style.title}>Api list</td>
          <td>
          <Table size="sm" responsive bordered striped>
            <tbody>
              <tr>
              <td><strong>Name</strong></td>
              <td><strong>Address</strong></td>
              <td><strong>Ordinal</strong></td>
              </tr>
              {imp.api_list.map((api, idx) =>
                <tr key={idx}>
                  <td>{api.name}</td>
                  <td>{api.address}</td>
                  <td>{api.ordinal}</td>
                </tr> )}
            </tbody>
          </Table>
          </td>
        </tr>
      </tbody>
    </Table>)}
  </>

  renderChain = chain => chain.map((chain, idx) => <Table size="sm" responsive bordered striped key={idx}>
    <tbody>
      <tr>
        <td className={style.title}>Serial number</td>
        <td>{chain.serial_number}</td>
      </tr>
      <tr>
        <td className={style.title}>Subject</td>
        <td>{chain.subject}</td>
      </tr>
      <tr>
        <td className={style.title}>Issuer</td>
        <td>{chain.issuer}</td>
      </tr>
      <tr>
        <td className={style.title}>Version</td>
        <td>{chain.version}</td>
      </tr>
      <tr>
        <td className={style.title}>Validity</td>
        <td>
          <p className='mb-0'>Not after: {chain.validity.not_after}</p>
          <p className='mb-0'>Not before: {chain.validity.not_before}</p>
        </td>
      </tr>
    </tbody>
  </Table>)

  renderDigitalSignature = digitalSignature => <>
    {digitalSignature.error && <p><b>Error: </b>{digitalSignature.error}</p>}
    {digitalSignature.signed_data_list && digitalSignature.signed_data_list.map((signed_data, idx) =>
      <Fragment key={idx}>
        <Table size="sm" responsive bordered striped>
          <tbody>
            <tr>
              <td className={style.title}>Signature</td>
              <td>{signed_data.signature}</td>
            </tr>
            <tr>
              <td className={style.title}>Signing time</td>
              <td>{signed_data.signing_time}</td>
            </tr>
            <tr>
              <td className={style.title}>Signature chain validity</td>
              <td>{signed_data.signature_chain_validity}</td>
            </tr>
            <tr>
              <td className={style.title}>Countersignature chain validity</td>
              <td>{signed_data.countersignature_chain_validity}</td>
            </tr>
          </tbody>
        </Table>
        {
          !!signed_data.signature_chain && !!signed_data.signature_chain.length > 0 && <>
            <p className={style.sectionSubtitle}>Signature Chain</p>
            { this.renderChain(signed_data.signature_chain) }
            <hr />
            </>
        }
        {
          !!signed_data.countersignature_chain && !!signed_data.countersignature_chain.length > 0 && <>
          <p className={style.sectionSubtitle}>Countersignature Chain</p>
            { this.renderChain(signed_data.countersignature_chain) }
            <hr />
            </>
        }
      </Fragment>)}
  </>

  switchSection = section => {
    const { info, versioninfo, imphash, imports, exports, sections, resources, digital_signature } = this.props.data;
    
    switch(section) {
      default:
      case 'versioninfo':
        return versioninfo && this.renderInfo("Version Info", versioninfo);
      case 'info':
        return info && this.renderInfo("Information", info);
      case 'digital_signature':
        return _.isEmpty(digital_signature) ? <p>No data available</p> : this.renderDigitalSignature(digital_signature);
      case 'imports':
        return imports && this.renderImports(imports, imphash);
      case 'exports':
        return exports && this.renderExports(exports);
      case 'sections':
        return sections && this.renderSection(sections);
      case 'resources':
        return resources && this.renderSection(resources);
    }
  }

  render() {
    const { activeTab } = this.state;

    return <>
      <Nav tabs>
        {PeStaticSections.map((section, idx) => this.props.data[section] &&
          ((Array.isArray(this.props.data[section]) && this.props.data[section].length > 0) || (_.isObject(this.props.data[section]) && !_.isEmpty(this.props.data[section]))) &&
          <NavItem key={idx}>
            <NavLink
              className={classnames(style.dynamicTabs, { 'font-weight-bold': activeTab === idx, active: activeTab === idx })}
              onClick={() => { this.toggle(idx); }}
            >
              {section.replace(/_/g, " ")}
            </NavLink>
          </NavItem>)}
      </Nav>
      <TabContent activeTab={activeTab}>
        {
          PeStaticSections.map((section, idx) => this.props.data[section] && <TabPane tabId={idx} key={idx} className={style.dynamicTabsContainer}>
          <Row>
            <Col sm="12">
              {this.switchSection(section)}
            </Col>
          </Row>
        </TabPane>)
        }
      </TabContent>
    </>;
  }
} 