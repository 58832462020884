import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';
import classnames from 'classnames';

export default class DBadge extends React.Component {
  render() {
    const { idx, value, className, onClick } = this.props;
    let { label } = this.props;

    label = label !== undefined ? label : value;

    return (
      <Badge
        className={classnames(value.replace(/\s/g, ''), className)}
        id={idx}
        onClick={onClick}
      >
        {label}
      </Badge>
    );
  }
}

DBadge.propTypes = {
  idx: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func
};