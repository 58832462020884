import {
  UPDATE_TOOLS_ON_LOGIN,
  UPDATE_TOOLS_ON_LOGOUT,
  UPDATE_LAST_VISITED_PAGE
} from '../actions/tools';

const initialState = {
  version: null,
  lastVisitedPage: null
};

export default function tools(state = initialState, action) {
  switch (action.type) {
    case UPDATE_LAST_VISITED_PAGE:
      return {
        ...state,
        lastVisitedPage: action.page
      }
    case UPDATE_TOOLS_ON_LOGIN:
      return {
        ...state,
        version: action.data
      };
    case UPDATE_TOOLS_ON_LOGOUT:
      return {
        ...initialState
      };
    default: {
      return state;
    }
  }
}
