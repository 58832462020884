import React, {Component} from 'react';
import classnames from 'classnames';
import _ from 'underscore';
import TitleBanner from '../TitleBanner';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { faPlusSquare, faMinusSquare, faListAlt } from '@fortawesome/free-regular-svg-icons';
import DBadge from '../Badge';

import { Table } from 'reactstrap';

import style from './style.scss';

export default class IPContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hidden: true
    }
  }

  toggleVisibility = () => {
    this.setState(prevState => ({
      hidden: !prevState.hidden
    }));
  }

  render() {
    const { hidden } = this.state;
    const { data } = this.props;
    const { ip, found_in, geo_info, rules, whois_info } = data;

    return <>
      <TitleBanner size='big' icon={hidden ? faPlusSquare : faMinusSquare} onClick={this.toggleVisibility}>
      {ip}
      </TitleBanner>
      <div className={classnames({ 'd-none': hidden})}>
        <p className='mb-1'>
          <span className={style.sectionTitle}>Found in:</span>
          {found_in.map((found, idx) => <DBadge className='ml-1' key={idx} label={found} type="result" value="unknown" />)}
        </p>

        {!_.isEmpty(rules) && <p className='mb-1'>
        <span className={style.sectionTitle}>Rules:</span>
          {rules.map((rule, idx) => <DBadge className='ml-1' key={idx} label={rule} type="result" value="unknown" />)}
        </p>}

        {!_.isEmpty(whois_info) && <>
          <TitleBanner icon={faListAlt}>Whois information</TitleBanner>
          <Table size="sm" responsive bordered striped>
            <tbody>
            {Object.entries(whois_info).map((info, idx) => {
              return !!info[1] && <tr key={idx}>
                <td className={style.title}>{info[0].replace(/_/g, ' ')}</td>
                <td>{ _.isString(info[1]) ? info[1] : JSON.stringify(info[1], null, 2) }</td>
              </tr>
            })}
            </tbody>
          </Table>
        </>}

        {!_.isEmpty(geo_info) && <>
          <TitleBanner icon={faGlobe}>Geo information</TitleBanner>
          <Table size="sm" responsive bordered striped>
            <tbody>
            {Object.entries(geo_info).map((info, idx) => {
              return !!info[1] && <tr key={idx}>
                <td className={style.title}>{info[0].replace(/_/g, ' ')}</td>
                <td>{ _.isString(info[1]) ? info[1] : JSON.stringify(info[1], null, 2) }</td>
              </tr>
            })}
            </tbody>
          </Table>
        </>}
      </div>
    </>;
  }
}