import React from 'react';
import classnames from 'classnames';
import { Badge } from 'reactstrap';
import { faIndent, faAlignJustify } from '@fortawesome/free-solid-svg-icons';

import style from './style.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default class StringBadge extends React.Component {
  render() {
    const { extracted, onClick } = this.props;

    return (
      <Badge
        className={classnames(style.StringBadge, extracted && style.extracted)}
        onClick={onClick}
      >
        <FontAwesomeIcon title={`Click to show ${extracted ? 'extracted' : ''} strings`} icon={extracted ? faIndent : faAlignJustify } />
      </Badge>
    );
  }
}