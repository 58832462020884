import React, { Component } from 'react';
import { connect } from 'react-redux';
import cloneDeep from 'clone-deep';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSlash } from '@fortawesome/free-solid-svg-icons';
import { faFileCode } from '@fortawesome/free-regular-svg-icons';

import {
  Container,
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader } from 'reactstrap';
import Dialog from 'react-bootstrap-dialog';
import download from 'downloadjs';
import Loading from '../Loading';
import Toolbar from '../../components/Toolbar';
import Toolbox from '../../components/Toolbox';

import Overview from './ReportSections/overview';
import Logs from './ReportSections/logs';
import Screenshots from './ReportSections/screenshots';
import Diagnostics from './ReportSections/diagnostics';
import Static from './ReportSections/static';
import DroppedFiles from './ReportSections/dropped';
import Dynamic from './ReportSections/Dynamics/index';
import Network from './ReportSections/network';

import { handleError } from '../../services/errorHandler';
import { fetchReportSection } from '../../services/report';
import { fetchSample } from '../../services/download';
import { sendRescan, reportIncorrectDetection, fixClassification } from '../../services/sandbox';

import { adminGroup, qaGroup } from '../../constants/users';
import { cleanStatus, maliciousStatus } from '../../constants/submissions';
import { statusSuccess, statusNotFound, statusProcessing, analysisCompleted } from '../../constants/api';
import { unknownStatus } from '../../constants/submissions';

import { faDownload,
  faBullhorn,
  faSlidersH,
  faRedo,
  faSpinner } from '@fortawesome/free-solid-svg-icons';

import style from './styles.scss';

const INITIAL_STATE = {
  section: 'overview',
  loading: false,
  platforms: [],
  currentPlatform: 0,
  status: null,
  classification: null,
  fixed_classification: null,
  sampleType: null,
  sampleExt: null,
  analysisCompleted: false,
  sampleDownloading: false,
  rescanInProgress: false,
  modalIncorrectDetection: {
    visible: false,
    comment: ''
  },
  modalFixClassification: {
    visible: false,
    tempFixed: null,
    tempClassification: null,
    rescanAfterFixedClassification: false
  },
  report: {
    overview: null,
    _static: null,
    intelligence: null,
    signature: null,
    comments: null,
    diagnostics: null,
    graph: null,
    dynamic: null,
    network: null,
    dropped: null,
    screenshot: null,
    logs: null
  }
};

class SandboxReport extends Component {
  constructor(props) {
    super(props);

    this.state = cloneDeep(INITIAL_STATE);

    this.tools = [];
    this.commentIncorrectDetection = React.createRef();
  }

  resetStatus = () => {
    window.location.reload();
  }

  componentDidMount = () => {
    this.loadSection(this.state.section);
  }

  componentDidUpdate(prevProps) {
    const { hash: prevHash } = prevProps.match.params;
    const { hash: newHash } = this.props.match.params;
    
    if(prevHash !== newHash) {
      this.resetStatus();
    }
  }

  renderSection(section) {
    switch(section) {
      case 'graph':
      return this.renderGraph();
      case 'dynamic':
        return this.renderDynamic();
      case '_static':
        return this.renderStatic();
      case 'network':
        return this.renderNetwork();
      case 'intelligence':
        return this.renderIntelligence();
      case 'dropped':
        return this.renderDropped();
      case 'screenshot':
        return this.renderScreenshot();
      case 'signature':
        return this.renderSignature();
      case 'comments':
        return this.renderComments();
      case 'logs':
        return this.renderLogs();
      case 'diagnostics':
        return this.renderDiagnostics();
      default:
      case 'overview':
        return this.renderOverview();
    }
  }

  renderGraph = () => {
    const { hash } = this.props.match.params;

    return <><h3>Sandbox Report</h3>
      Hash passed: {hash};
    </>;
  }

  renderOverview = () => {
    const { overview } = this.state.report;

    return <Overview
      data={overview}
    />;
  }

  renderScreenshot = () => {
    const { screenshot } = this.state.report;
    return <Screenshots data={screenshot} />;
  }

  renderDynamic = () => {
    const { hash } = this.props.match.params;
    const { currentPlatform, platforms } = this.state;
    const { dynamic, dropped } = this.state.report;

    return <Dynamic
      data={dynamic}
      dropped={dropped}
      hash={hash}
      platform={platforms[currentPlatform]}
    />
  }

  renderStatic = () => {
    const { sampleType, sampleExt, report } = this.state;
    const { _static } = report;

    return <Static
      data={_static}
      sampleType={sampleType}
      sampleExt={sampleExt}
    />
  }

  renderNetwork = () => {
    const { hash } = this.props.match.params;
    const { platforms, currentPlatform, report } = this.state;

    return <Network
      data={report.network}
      hash={hash}
      platform={platforms[currentPlatform]}
    />
  }

  renderIntelligence = () => {
    return null;
  }

  renderDropped = () => {
    const { hash } = this.props.match.params;
    const { currentPlatform, platforms } = this.state;
    const { dropped } = this.state.report;

    return <DroppedFiles data={dropped} hash={hash} platform={platforms[currentPlatform]} />
  }

  renderSignature = () => {
    return null;
  }

  renderComments = () => {
    return null;
  }

  renderLogs = () => {
    const { logs } = this.state.report;
    return <Logs data={logs} />
  }

  renderDiagnostics = () => {
    const { diagnostics } = this.state.report;
    return <Diagnostics data={diagnostics} hash={this.props.match.params.hash} />;
  }

  onToolbarIconClick = (section) => {
    const { loading } = this.state;

    if(!loading) {
      this.setState({
        section
      }, () => {
        this.loadSection(section);
      });
    }
  }

  loadSection = (section) => {
    const { platforms, currentPlatform, report } = this.state;
    const { hash } = this.props.match.params;
        
    if(!report[section]) {
      this.setState({
        loading: true
      }, () => {
        fetchReportSection(section, platforms[currentPlatform], hash)
        .then(res => {
          if(res.status === statusSuccess) {
            const { data } = res;
            const tempState = {
              ...this.state,
              status: statusSuccess,
              loading: false
            };

            if(section === 'dynamic' || section === 'dropped' || section === 'network') {
              tempState.report['network'] = {
                domains: data.dynamic.data.domains,
                ips: data.dynamic.data.ips
              };
              tempState.report['dropped'] = data.dynamic.data.dropped_files;
              tempState.report['dynamic'] = data.dynamic;
            } else if(section === 'overview') {
              tempState.platforms = Object.entries(data.generic.overall).map(i => i[0]);
              tempState.sampleType = data.generic.info.file_type.format;
              tempState.sampleExt = data.generic.info.file_type.ext;
              tempState.classification = data.generic.info.scan_result;
              tempState.fixed_classification = data.generic.info.fixed_classification;

              if(data.generic.info.analysis_status === analysisCompleted) {
                tempState.analysisCompleted = analysisCompleted;

                tempState.modalFixClassification = {
                  visible: false,
                  tempFixed: data.generic.info.fixed_classification,
                  tempClassification: data.generic.info.scan_result,
                  rescanAfterFixedClassification: false
                };
              }

              tempState.report[section] = data.generic;
            } else if(section === '_static') {
              tempState.report[section] = data.static;
            } else {
              tempState.report[section] = data;
            }

            this.setState(tempState);
          } else {
            const tempState = {
              loading: false
            }
            // we have to update the status of the report only if we fetched the main section
            if(section === 'overview') {
              tempState.status = res.status;
            }
            this.setState(tempState);
          }
        })
        .catch(err => {
          this.setState({
            loading: false
          }, () => {
            handleError('Error', <>Error retrieving report information<br />Section: <b>{section}</b> - Platform: <b>{platforms[currentPlatform] ? platforms[currentPlatform] : '---'}</b></>);
          })
        })
      })
    }
  }

  onPlatformClick = (currentPlatform) => {
    const { loading, report, section } = this.state;

    if(!loading) {
      this.setState({
        currentPlatform,
        report: {
          ...report,
          graph: null,
          dynamic: null,
          network: null,
          dropped: null,
          screenshot: null,
          logs: null
        }
      }, () => {
        this.loadSection(section);
      });
    }
  }

  renderIncorrectDetectionModal = () => {
    const { comment, visible } = this.state.modalIncorrectDetection;

    return (
      <Modal isOpen={visible} toggle={this.toggleReportIncorrectDetection}>
        <ModalHeader toggle={this.toggleReportIncorrectDetection}>Report incorrect detection</ModalHeader>
        <ModalBody>
          Do you think that the sample has an incorrect detection?<br />
          You can add a comment if you want:
          <textarea
            style={{ width: '100%', height: '150px', margin: '.5em 0', 'fontSize': '13px'}}
            ref={this.commentIncorrectDetection}
            onChange={evt => this.updateReportIncorrectDetectionComment(evt)}
            defaultValue={comment}
          >
          </textarea>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.sendIncorrectDetection}>Send alert</Button>
        </ModalFooter>
      </Modal>
    );
  }

  renderFixClassificationModal = () => {
    const { visible, tempFixed, tempClassification, rescanAfterFixedClassification } = this.state.modalFixClassification;
    
    return (
      <Modal isOpen={visible} toggle={this.toggleFixClassification}>
        <ModalHeader toggle={this.toggleFixClassification}>Fixed classification</ModalHeader>
        <ModalBody>
          <p className='mb-0'>Fixed classification:</p>
          <ButtonGroup className='mb-3'>
            <Button size="sm" color={tempFixed ? 'primary' : 'secondary'} onClick={() => this.changeTempFixed(true)} active={tempFixed}>YES</Button>
            <Button size="sm" color={!tempFixed ? 'primary' : 'secondary'} onClick={() => this.changeTempFixed(false)} active={!tempFixed}>NO</Button>
          </ButtonGroup>
          <p className='mb-0'>Classification result:</p>
          <ButtonGroup className='mb-3'>
            <Button size="sm" color={tempClassification === cleanStatus ? 'success' : 'secondary'} onClick={() => this.changetempClassification(cleanStatus)} active={tempClassification === cleanStatus}>Clean</Button>
            <Button size="sm" color={tempClassification === maliciousStatus ? 'danger' : 'secondary'} onClick={() => this.changetempClassification(maliciousStatus)} active={tempClassification === maliciousStatus}>Malicious</Button>
          </ButtonGroup>
          <p className='mb-0'>Rescan the sample:</p>
          <ButtonGroup>
            <Button size="sm" color={rescanAfterFixedClassification === true ? 'success' : 'secondary'} onClick={() => this.changeRescanAfterFixedClassification(true)} active={rescanAfterFixedClassification}>Yes</Button>
            <Button size="sm" color={rescanAfterFixedClassification === false ? 'danger' : 'secondary'} onClick={() => this.changeRescanAfterFixedClassification(false)} active={!rescanAfterFixedClassification}>No</Button>
          </ButtonGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.sendFixClassification}>Send</Button>
        </ModalFooter>
      </Modal>
    );
  }

  changeTempFixed = tempFixed => {
    const { modalFixClassification } = this.state;
    this.setState({
       modalFixClassification: {
        ...modalFixClassification,
        tempFixed
      }
    })
  }

  changetempClassification = tempClassification => {
    const { modalFixClassification } = this.state;
    this.setState({
       modalFixClassification: {
        ...modalFixClassification,
        tempClassification
      }
    })
  }

  changeRescanAfterFixedClassification = rescan => {
    const { modalFixClassification } = this.state;
    this.setState({
       modalFixClassification: {
        ...modalFixClassification,
        rescanAfterFixedClassification: rescan
      }
    })
  }

  toggleReportIncorrectDetection = e => {
    if(e) e.preventDefault();

    this.setState({
      modalIncorrectDetection: {
        comment: '',
        visible: !this.state.modalIncorrectDetection.visible
      }
    });
  }

  toggleFixClassification = e => {
    if(e) e.preventDefault();
    const { modalFixClassification, classification, fixed_classification } = this.state;
    this.setState({
      modalFixClassification: {
        visible: !modalFixClassification.visible,
        tempFixed: fixed_classification,
        tempClassification: classification,
        rescanAfterFixedClassification: false
      }
    });
  }

  updateReportIncorrectDetectionComment = event => {
    this.setState({
      inputValue: event.target.value
    });
  }

  sendIncorrectDetection = () => {
    const { classification } = this.state;
    const { sha512 } = this.state.report.overview.info;
    const comment = this.commentIncorrectDetection.current.value;

    this.setState({
      modalIncorrectDetection: {
        comment: '',
        visible: false
      }
    }, () => {
      reportIncorrectDetection(sha512, classification, comment).then(res => {
        if(res.status === statusSuccess) {
          handleError('Success', 'Message sent successfully', 'success');
        } else {
          handleError('Error', 'Error sending message');
        }
      });
    })
  }

  sendFixClassification = () => {
    const { modalFixClassification } = this.state;
    const { tempFixed, tempClassification, rescanAfterFixedClassification } = this.state.modalFixClassification;
    const { hash } = this.props.match.params;

    this.setState({
      modalFixClassification: {
        ...modalFixClassification,
        visible: false
      }
    }, () => {
      fixClassification(hash, tempFixed, tempClassification, rescanAfterFixedClassification).then(res => {
        if(res.status === statusSuccess) {
          this.setState({
            classification: tempClassification,
            fixed: tempFixed,
            rescan: false
          }, () => {
            handleError('Success', 'Classification set', 'success', () => { this.resetStatus(); });
          })
        } else {
          handleError('Error', 'Error setting classification');
        }
      })
    });
  }

  downloadSample = () => {
    const { hash } = this.props.match.params;
    this.setState({
      sampleDownloading: true
    }, () => {
      fetchSample(hash).then(response => {
        return response.blob();
      }).then(myBlob => {
        this.setState({
          sampleDownloading: false
        }, () => {
          return download(myBlob, `${hash}.deepviz`, 'application/octet-stream');
        })
      })
    })
  }

  startRescan = () => {
    this.dialog.show({
      title: 'Rescan',
      body: 'Do you want to start a rescan?',
      actions: [
        Dialog.CancelAction(),
        Dialog.Action(
          'Confirm',
          () => {
            const { hash } = this.props.match.params;
            this.setState({
              rescanInProgress: true
            }, () => {
              sendRescan(hash).then(res => {
                if(res.status === statusSuccess) {
                  handleError('Success', 'Rescan sent', 'success', () => { this.resetStatus(); });
                } else if (res.status === statusProcessing) {
                  handleError('Warning', 'Sample already in processing', 'warning');
                } else {
                  handleError('Error', 'Error starting rescan');
                }
              }).finally(() => {
                this.setState({
                  rescanInProgress: false
                });
              })
            })
          },
          'btn-primary'
        )
      ],
      bsSize: 'small',
      onHide: dialog => {
        dialog.hide();
      }
    });
  }

  render() {
    const {
      analysisCompleted,
      classification,
      platforms,
      currentPlatform,
      section,
      status,
      loading,
      sampleDownloading,
      rescanInProgress } = this.state;

    if(status === statusNotFound) return <p className={style.noResultFound}>
      <span className="fa-layers fa-fw">
        <FontAwesomeIcon icon={faFileCode} />
        <FontAwesomeIcon icon={faSlash} />
      </span>
      No result found
    </p>;
    if(status === statusProcessing) return <p style={{textAlign: 'center', marginTop: '3em'}}>Analysis in progress, please try later.</p>;

    this.tools = [];

    this.tools.push(
      !sampleDownloading ?
        { label: 'Download sample', icon: faDownload, onClick: this.downloadSample } :
        { label: 'Download sample', icon: faSpinner, spin: true, onClick: () => {} }
    );

    if(analysisCompleted && classification !== unknownStatus)
      this.tools.push({ label: 'Report incorrect detection', icon: faBullhorn, onClick: this.toggleReportIncorrectDetection });

    if([qaGroup, adminGroup].includes(this.props.auth.group_name) && analysisCompleted) {
      if(classification !== unknownStatus) {
        this.tools.push({ label: 'Fixed classification', icon: faSlidersH, onClick: this.toggleFixClassification })
      }

      this.tools.push(!rescanInProgress ?
        { label: 'Rescan', icon: faRedo, onClick: this.startRescan } :
        { label: 'Rescan', icon: faSpinner, spin: true, onClick: () => {} }
      );
    }

    return <div className={style.reportContainer}>
      <Toolbar
        currentPlatform={platforms[currentPlatform]}
        platforms={platforms}
        onIconClick={this.onToolbarIconClick}
        onPlatformClick={this.onPlatformClick}
        section={section}
        analysisCompleted={analysisCompleted}
      />
      <Container fluid className={style.sectionContainer}>
        {loading ? <Loading /> : <>
          {!!this.tools.length && <Toolbox tools={this.tools} />}
          <Dialog ref={component => { this.dialog = component }} />
          {this.renderIncorrectDetectionModal()}
          {this.renderFixClassificationModal()}
          {this.renderSection(section)}
          </>}
      </Container>
    </div>
  }
};


export default connect(
  state => ({
    auth: state.auth
  })
)(SandboxReport);