import React, {Component} from 'react';
import classnames from 'classnames';
import _ from 'underscore';
import TitleBanner from '../TitleBanner';
import { faLevelDownAlt } from '@fortawesome/free-solid-svg-icons';
import { faPlusSquare, faMinusSquare, faListAlt } from '@fortawesome/free-regular-svg-icons';
import DBadge from '../Badge';

import { Table  } from 'reactstrap';

import style from './style.scss';

export default class DomainContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hidden: true
    }
  }

  toggleVisibility = () => {
    this.setState(prevState => ({
      hidden: !prevState.hidden
    }));
  }

  render() {
    const { hidden } = this.state;
    const { data } = this.props;
    const { name, tld, sub_domains, rules, whois_info } = data;

    return <>
      <TitleBanner size='big' icon={hidden ? faPlusSquare : faMinusSquare} onClick={this.toggleVisibility}>
      {name}
      </TitleBanner>
      <div className={classnames({ 'd-none': hidden})}>
        {!_.isEmpty(rules) && <p className='mb-1'>
        <span className={style.sectionTitle}>Rules:</span>
          {rules.map((rule, idx) => <DBadge style={{marginLeft: '.5rem'}} key={idx} label={rule} type="result" value="unknown" />)}
        </p>}

        {!_.isEmpty(tld) && <p className='mb-1'> <span className={style.sectionTitle}>Tld:</span> {tld}</p>}

        {!_.isEmpty(sub_domains) && <>
          <TitleBanner icon={faLevelDownAlt}>Subdomains</TitleBanner>
          <Table size="sm" responsive bordered striped>
            <thead>
              <tr>
                <td className={style.title}>Name</td>
                <td className={style.title}>Urls</td>
                <td className={style.title}>Alias chain</td>
                <td className={style.title}>IPs</td>
              </tr>
            </thead>
            <tbody>
            {sub_domains.map((subdomain, idx) => <tr key={idx}>
              <td>{subdomain.name}</td>
              <td>{subdomain.urls.map((url, idx) => <p className='mb-0' key={idx}>{url}</p>)}</td>
              <td>{subdomain.alias_chain.map((alias, idx) => <p className='mb-0' key={idx}>{alias}</p>)}</td>
              <td>{subdomain.ips.map((ip, idx) => <p className='mb-0' key={idx}>{ip}</p>)}</td>
            </tr>)}
            </tbody>
          </Table>
        </>}

        {!_.isEmpty(whois_info) && <>
          <TitleBanner icon={faListAlt}>Whois information</TitleBanner>
          <Table size="sm" responsive bordered striped>
            <tbody>
            {Object.entries(whois_info).map((info, idx) => {
              return !!info[1] && <tr key={idx}>
                <td className={style.title}>{info[0].replace(/_/g, ' ')}</td>
                <td>{ _.isString(info[1]) ? info[1] : JSON.stringify(info[1], null, 2) }</td>
              </tr>
            })}
            </tbody>
          </Table>
        </>}
      </div>
    </>;
  }
}