import React from 'react';
import { connect } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'underscore';

import { Button,
  Col,
  Row, 
  ListGroup,
  ListGroupItem,
  Badge,
  Table } from 'reactstrap';
  
import ReportSection from '../../../components/ReportSection';
import DBadge from '../../../components/Badge';
import CategoryFieldset from '../../../components/CategoryFieldset';
import CategoryIcon from '../../../components/CategoryIcon';
import PlatformStatus from '../../../components/PlatformStatus';
import { rulesLevel } from '../../../constants/sandbox';
import { completedStatus } from '../../../constants/submissions';

import { localizeDate } from '../../../utils/dates';

import { faCircle,
  faFileMedicalAlt,
  faNetworkWired,
  faDesktop,
  faCogs,
  faUserSecret,
  faKey,
  faGlobe,
  faTerminal,
  faSearch } from '@fortawesome/free-solid-svg-icons';

import { faComments,
  faWindowRestore,
  faEnvelopeOpen } from '@fortawesome/free-regular-svg-icons';

import style from './style.scss';
import TitleBanner from '../../../components/TitleBanner';

const OverallSystemImpactStructure = {
  'User data': {
    'FtpData': faFileMedicalAlt,
    'SystemDocsData': faSearch,
    'ImData': faComments,
    'EmailData': faEnvelopeOpen,
    'SshData': faTerminal,
    'BrowserData': faGlobe,
    'PasswordManagerData': faKey
  },
  'System security': {
    'NetworkSecurity': faNetworkWired,
    'SystemSecurity': faDesktop
  },
  'System config': {
    'SystemAppearance': faWindowRestore,
    'SystemUsability': faCogs,
    'UserActivities': faUserSecret
  }
};

const INITIAL_STATE = {
  allPlatforms: [],
  rulesFilters: [],
  rulesPlatforms: [],
  rulesCategories: [],
  highImpactRules: null,
  mediumImpactRules: null,
  lowImpactRules: null,
  sampleDownloading: false,
  rawReportDownloading: false,
  fileInfector: false
};

class Overview extends React.Component {
  constructor(props) {
    super(props);

    this.state = INITIAL_STATE;
  }

  update() {
    const { data } = this.props;
    const oldState = this.state;

    let highImpactRules = 0,
        mediumImpactRules = 0,
        lowImpactRules = 0,
        rulesCategories = [],
        allPlatforms = [];

    // only if rules are not null and the couters are never been initialized
    if(data && data.rules && oldState.highImpactRules === null) {
      Object.entries(data.rules).forEach(rule => {
        // rule[0]: label, rule[1]: properties
        const { level } = rule[1];

        if(level === rulesLevel.LOW) {
          lowImpactRules++;
        } else if(level === rulesLevel.MEDIUM) {
          mediumImpactRules++;
        } else {
          highImpactRules++;
        }

        allPlatforms = _.union(allPlatforms, rule[1].platforms);
        rulesCategories = _.union(rulesCategories, rule[1].categories);
      });

      this.setState({
        fileInfector: data.info.file_infector,
        highImpactRules,
        mediumImpactRules,
        lowImpactRules,
        rulesCategories,
        allPlatforms
      });
    }
  }

  componentDidMount() {
    this.update();
  }

  componentDidUpdate() {
    this.update();
  }

  toggleFilter = filter => {
      const { rulesFilters } = this.state;

      const index = rulesFilters.indexOf(filter);

      if(index > -1) {
        rulesFilters.splice(index, 1);
      } else {
        rulesFilters.push(filter);
      }

      this.setState({
         rulesFilters
      });
  }

  renderGeneralInformation = () => {
    const { data } = this.props;
    
    if (data && data.info) {
      const { info } = data;

      if(info.analysis_status && info.analysis_status !== completedStatus) {
        delete info.fixed_classification;
        delete info.platforms;
        delete info.scan_result;
      }

      return (
        <ReportSection title='General information'>
          <Table striped size="sm" responsive bordered>
            <tbody>
            { Object.entries(info).map((i, v) => {
              const description = i[0] === 'analysis_status' ? <DBadge type="status" value={i[1]} /> :
                i[0] === 'scan_result' && i[1] ? <DBadge type="result" value={i[1]} /> : 
                i[0] === 'platforms' ? Object.entries(i[1]).map((platform, idx) => <PlatformStatus md5={'overview'} platform={platform[0]} status={platform[1]} key={idx} />) :
                i[0] === 'file_type' ? i[1].description :
                i[0] === 'first_seen_date' ? localizeDate(i[1]) :
                i[0] === 'last_scan' ? localizeDate(i[1]) :
                i[0] === 'filename' ? i[1].map(([filename, count], idx) => <span style={{display: 'block'}} key={idx}>{`${filename} [${count}]`}</span>) :
                i[0] === 'fixed_classification' ? i[1] ? <DBadge label="True" type="result" value="unknown" /> : <DBadge label="False" type="result" value="unknown" /> :
                i[0] === 'sha256' ? <a rel='noopener noreferrer' target='_blank' href={`https://www.virustotal.com/gui/file/${i[1]}`}>{i[1]}</a>
                : i[1];
  
              return i[0] !== 'file_infector' && <tr key={v}>
                <td className={style.title}>{i[0].replace(/_/g, " ")}</td>
                <td>{description}</td>
              </tr>;
            }) }
            </tbody>
          </Table>
        </ReportSection>
      );
    } else {
      return null;
    } 
  }

  renderOverallSystemImpact = () => {
    const { rulesCategories } = this.state;

    return <ReportSection title='Overall system impact'>
      <div style={{textAlign: 'center'}}>
        {Object.entries(OverallSystemImpactStructure).map((i, v) => {
          return (
            <CategoryFieldset title={i[0]} key={v}>
              {Object.entries(i[1]).map((category, v) => {
                return (
                  <CategoryIcon tag={category[0]} icon={category[1]} key={v} active={ rulesCategories.indexOf(category[0]) > -1 } />
                );
              })}
            </CategoryFieldset>
          );
        })}
      </div>
    </ReportSection>;
  }

  renderCharacteristicsAndBehaviours = () => {
    const {
      lowImpactRules,
      mediumImpactRules,
      highImpactRules,
      rulesFilters,
      rulesPlatforms
    } = this.state;

    const { data } = this.props;
    const filtersToPrint = [],
          filtersTemp = [],
          rulesToPrint = [];

    if(data && data.rules) {
      Object.entries(data.rules).forEach(rule => {
        // filling the rules to print
        if((_.intersection(rulesFilters, rule[1].filters).length || rulesFilters.length === 0) &&
          (_.intersection(rulesPlatforms, rule[1].platforms).length || rulesPlatforms.length === 0)) {
          rulesToPrint.push({
            label: rule[0],
            description: rule[1].description,
            level: rule[1].level
          });
        }

        // filling the filters array with label and counter
        rule[1].filters.forEach(filter => {
          if(_.intersection(rulesPlatforms, rule[1].platforms).length || rulesPlatforms.length === 0) {
            if(filtersTemp.hasOwnProperty(filter)) {
              filtersTemp[filter]++;
            } else {
              filtersTemp[filter] = 1;
            }
          }
        });
      });

      for(let k in filtersTemp) {
        filtersToPrint.push({
          label: k,
          count: filtersTemp[k]
        });
      }

      filtersToPrint.sort((a, b) => b.count - a.count);

      rulesToPrint.sort((a, b) => {
        if(a.level === b.level) return a.description > b.description ? 1 : -1;
        if(a.level === 'high') return -1;
        if(b.level === 'high') return 1;
        if(a.level === 'medium') return -1;
        return 1;
      });
    }

    return (
      <ReportSection title='Characteristics and behaviours'>
        <p className={style.triggeredRulesRecap}>
          The sample triggered <Badge className="mr-0" color="dark">{ highImpactRules + mediumImpactRules + lowImpactRules}</Badge> rules in total.<br />
          <Badge color="danger">{highImpactRules} high impact</Badge>
          <Badge color="warning">{mediumImpactRules} medium impact</Badge>
          <Badge color="secondary">{lowImpactRules} low impact</Badge>
        </p>
        {!!rulesToPrint.length && <Row className={style.rulesContainer}>
          <Col lg='10' md='12'>
            <ListGroup>
              {rulesToPrint.map(rule => {
                const {label, description, level} = rule;

                return <ListGroupItem key={label}>
                  <FontAwesomeIcon icon={faCircle} className={style[level]} />
                  <span className={style.ruleVerbose}>{description}</span>
                </ListGroupItem>;
              })}
            </ListGroup>
          </Col>
          <Col lg='2' md='12'>
            <p style={{marginBottom: 0, fontWeight: 'bold'}}>Rule filters</p>
            <p>Click on a label to filter the rules</p>
            <ul>
            {filtersToPrint.map(filter => {
              const {label, count} = filter;
              const isActive = rulesFilters.length === 0 || rulesFilters.indexOf(label) > -1;

              return <li key={label} className={style.filter} onClick={() => { this.toggleFilter(label)}}>
                <Badge pill color="secondary" className={style.filterCounter}>{count}</Badge>
                <Badge color={isActive ? 'primary' : 'secondary'} className={style.filterLabel}>{label}</Badge>
              </li>
            })}
            </ul>
          </Col>
        </Row>}
      </ReportSection>
    );
  }

  togglePlatform = platform => {
    const { data } = this.props;
    const rulesPlatforms = [];
    let rulesCategories = [];

    if(platform !== 'all') {
      rulesPlatforms.push(platform);
    }

    if(data && data.rules) {
      Object.entries(data.rules).forEach(rule => {
        if(_.intersection(rulesPlatforms, rule[1].platforms).length || rulesPlatforms.length === 0) {
          rulesCategories = _.union(rulesCategories, rule[1].categories);
        }
      });
    }

    this.setState({
      rulesPlatforms,
      rulesCategories
    });
  }

  renderToolsInfo = () => {
    const { data } = this.props;
    
    if ( data && data.tools) {
      const { tools } = data;

      return (
        <ReportSection title="Tools version info">
          <Table striped size="sm" responsive bordered>
            <tbody>
              <tr>
                <th>Tool</th>
                <th>Version</th>
                <th>Last available version
                </th>
              </tr>
            { Object.entries(tools).map((i, v) => {
              const { tool, platform, version, last_available_version } = i[1];

              return <tr key={i[0]}>
                <td>
                  { tool.replace(/^\w/, c => c.toUpperCase())} { platform ? "(" + platform.replace("_", " ").replace(/^\w/, c => c.toUpperCase()) + ")" : null }
                </td>
                <td>{version}</td>
                <td>{last_available_version}</td>
                </tr>;
            }) }
            </tbody>
          </Table>
        </ReportSection>
      );
    } else {
      return null;
    }
  };

  render() {
    const { allPlatforms, rulesPlatforms, fileInfector } = this.state;
    const { data } = this.props;

    const analysisCompleted = data && data.info && data.info.analysis_status && !['processing', 'pending', 'unsupported'].includes(data.info.analysis_status);

    return data && <>
        {this.renderGeneralInformation()}
        {
          fileInfector ? <TitleBanner textCenter={true} size='big'>File looks infected by <DBadge label={fileInfector} value='malicious' />.</TitleBanner> :
           (analysisCompleted && <div style={{background: '#f8f9fa', padding: '2em', marginBottom: '2rem'}}>
          { /* Platform selector will be print only if we have 2 platforms or more */ }
          {rulesPlatforms.length > 1 ? <div>
            <p style={{marginBottom: '0'}}>Select a platform:</p>
            <div className={style.overviewPlatformSelector}>
              <Button
                outline={!(rulesPlatforms.length === 0)}
                platform='all'
                size="sm"
                color="info"
                onClick={() => {this.togglePlatform('all')}}
              >
                All
              </Button>
              {allPlatforms.map(platform => {
                return <Button
                  key={platform}
                  size="sm"
                  color="info"
                  outline={rulesPlatforms.indexOf(platform) === -1}
                  onClick={() => {this.togglePlatform(platform)}}
                >
                  {platform.replace("_", " ")}
                </Button>
              })}
            </div>
          </div> : null}
          {this.renderOverallSystemImpact()}
          {this.renderCharacteristicsAndBehaviours()}
        </div>)
        }
        
        {analysisCompleted && this.renderToolsInfo()}
      </>
  }
}

export default connect(
  state => ({
    auth: state.auth
  })
)(Overview);
