import React, { Component } from 'react';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindows } from '@fortawesome/free-brands-svg-icons';
import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';

import { OSnames } from '../../constants/names';

import style from './style.scss';

library.add(
  faWindows,
  faCircle,
  faCaretLeft,
  faCaretRight
);

class PlatformSwitch extends Component {
  constructor(props) {
    super(props);

    this.internalState = {
      currentPlatform: null
    }
  }
  
  render() {
    const { currentPlatform, platforms, onPlatformClick } = this.props;
    return (
        <div className={style.container}>
          <div className={style.platformContainer}>
            <>
              <FontAwesomeIcon icon={faWindows} />
              <span className={style.platformLabel}>{OSnames[currentPlatform]}</span>
              <span className={style.labelContainer}>
                {platforms.map((p, i) => {
                  return p !== currentPlatform && // avoid to return the current platform
                    <div className={style.otherPlatform} key={i} onClick={() => onPlatformClick(i) }>
                      <FontAwesomeIcon icon={faWindows} />
                      <span>{OSnames[p]}</span>
                    </div>;
                })}
              </span>
            </>
          </div>
        </div>
    );
  }
};

export default PlatformSwitch;
