import React from 'react';
import style from './style.scss';

export default class Overview extends React.Component {
  render() {
    return <div style={{marginBottom: '2rem'}}>
      <p className={style.sectionTitle}>{this.props.title}</p>
      <hr />
      {this.props.children}
    </div>
  }
}