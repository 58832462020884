import React from 'react';
import { Table } from 'reactstrap';
import _ from 'underscore';

import CodeHighlighter from '../../../../../components/CodeHighlighter';
import FlagIcon from '../../../../../components/FlagIcon';
import ReportSection from '../../../../../components/ReportSection';
import PeElement from '../../../../../components/PeElement';

import style from '../../style.scss';

export default class PdfStatic10 extends React.Component {
  renderJS = data => data.map((d, idx) => {
    const {code, obfuscation, shellcode, exceptions, assembly_list} = d;

    return <Table size="sm" responsive bordered striped key={idx}>
      <tbody>
        <tr>
          <td className={style.title}>Code</td>
          <td><CodeHighlighter code={code} base64={true} /></td>
        </tr>
        <tr>
          <td className={style.title}>Obfuscation</td>
          <td><FlagIcon flag={obfuscation} /></td>
        </tr>
        <tr>
          <td className={style.title}>Shellcode</td>
          <td><FlagIcon flag={shellcode} /></td>
        </tr>
        {!!exceptions.length && <tr>
          <td className={style.title}>Exceptions</td>
          <td>{exceptions.map((exception, idx) => {
            return <p key={idx} className="mb-0">{exception}</p>
          })}</td>
        </tr>
        }
        {!!assembly_list.length && <tr>
          <td className={style.title}>Assembly list</td>
          <td>{assembly_list.map((assembly, idx) => <CodeHighlighter key={idx} code={assembly} />)}</td>
        </tr>
        }
      </tbody>
    </Table>
  });

  renderSWF = ({ code, compressed, shellcode, exceptions }) => 
    <Table size="sm" responsive bordered striped>
      <tbody>
      <tr>
        <td className={style.title}>Code</td>
        <td><CodeHighlighter code={code} base64={true} /></td>
      </tr>
      <tr>
        <td className={style.title}>Compressed</td>
        <td><FlagIcon flag={compressed} /></td>
      </tr>
      <tr>
        <td className={style.title}>Shellcode</td>
        <td><FlagIcon flag={shellcode} /></td>
      </tr>
      {!!exceptions.length && <tr>
        <td className={style.title}>Exceptions</td>
        <td>{exceptions.map((exception, idx) => {
          return <p key={idx} className="mb-0">{exception}</p>
        })}</td>
      </tr>
      }
      </tbody>
    </Table>

  renderAction = ({ action, type, data }, idx) =>
    <Table size="sm" responsive bordered striped key={idx}>
      <tbody>
        <tr>
          <td className={style.title}>Action</td>
          <td>{action}</td>
        </tr>
        <tr>
          <td className={style.title}>Type</td>
          <td>{type}</td>
        </tr>
        <tr>
          <td className={style.title}>Data</td>
          <td>{data}</td>
        </tr>        
      </tbody>
    </Table>

  render = () => this.props.data.map(({ javascript_list, pe, swf, actions }, idx) =>
    <ReportSection title={`Object #${idx + 1}`} key={idx}>
      {!!javascript_list.length && <ReportSection title="Javascript">{this.renderJS(javascript_list)}</ReportSection>}
      {!_.isEmpty(pe) && <ReportSection title="PE"><PeElement pe={pe} /></ReportSection>}
      {!_.isEmpty(swf) && <ReportSection title="SWF">{this.renderSWF(swf)}</ReportSection>}
      {!!actions.length && <ReportSection title="Actions">{actions.map((action, idx) => this.renderAction(action, idx))}</ReportSection>}
    </ReportSection>
  );
} 