import React from 'react';
import { ResponsiveContainer } from 'recharts';
import style from './style.scss';

export default class NoDataAvailable extends React.Component {
  render() {
    const { height } = this.props;

    return <ResponsiveContainer width='100%' height={height} className={style.NoDataAvailable}>
      <p className={style.NoDataAvailable}>No data available</p>
    </ResponsiveContainer>
  }
}