export const UPDATE_ADMIN_SUBMISSIONS_PAGE_SIZE = 'UPDATE_ADMIN_SUBMISSIONS_PAGE_SIZE';
export const UPDATE_ADMIN_SUBMISSIONS_TABLE_PAGE = 'UPDATE_ADMIN_SUBMISSIONS_TABLE_PAGE';
export const UPDATE_ADMIN_SUBMISSIONS_FILTERS = 'UPDATE_ADMIN_SUBMISSIONS_FILTERS';
export const UPDATE_ADMIN_SUBMISSIONS_SORTING = 'UPDATE_ADMIN_SUBMISSIONS_SORTING';

export const UPDATE_SANDBOX_SUBMISSIONS_PAGE_SIZE = 'UPDATE_SANDBOX_SUBMISSIONS_PAGE_SIZE';
export const UPDATE_SANDBOX_SUBMISSIONS_TABLE_PAGE = 'UPDATE_SANDBOX_SUBMISSIONS_TABLE_PAGE';
export const UPDATE_SANDBOX_SUBMISSIONS_FILTERS = 'UPDATE_SANDBOX_SUBMISSIONS_FILTERS';
export const UPDATE_SANDBOX_SUBMISSIONS_SORTING = 'UPDATE_SANDBOX_SUBMISSIONS_SORTING';

export const UPDATE_SANDBOX_USERS_PAGE_SIZE = 'UPDATE_SANDBOX_USERS_PAGE_SIZE';
export const UPDATE_SANDBOX_USERS_TABLE_PAGE = 'UPDATE_SANDBOX_USERS_TABLE_PAGE';
export const UPDATE_SANDBOX_USERS_FILTERS = 'UPDATE_SANDBOX_USERS_FILTERS';
export const UPDATE_SANDBOX_USERS_SORTING = 'UPDATE_SANDBOX_USERS_SORTING';

export const UPDATE_ADMIN_SAMPLES_PAGE_SIZE = 'UPDATE_ADMIN_SAMPLES_PAGE_SIZE';
export const UPDATE_ADMIN_SAMPLES_TABLE_PAGE = 'UPDATE_ADMIN_SAMPLES_TABLE_PAGE';
export const UPDATE_ADMIN_SAMPLES_FILTERS = 'UPDATE_ADMIN_SAMPLES_FILTERS';
export const UPDATE_ADMIN_SAMPLES_SORTING = 'UPDATE_ADMIN_SAMPLES_SORTING';

export const SET_INJECTED_PROCESS = 'SET_INJECTED_PROCESS';
export const UPDATE_SANDBOX_DATA_ON_LOGOUT = 'UPDATE_SANDBOX_DATA_ON_LOGOUT';

export const updateSandboxDataOnLogout = () => ({
  type: UPDATE_SANDBOX_DATA_ON_LOGOUT
})

export const updateAdminSubmissionsPageSize = size => ({
  type: UPDATE_ADMIN_SUBMISSIONS_PAGE_SIZE,
  size
})

export const updateAdminSubmissionsTablePage = page => ({
  type: UPDATE_ADMIN_SUBMISSIONS_TABLE_PAGE,
  page
})

export const updateAdminSubmissionsFilters = filters => ({
  type: UPDATE_ADMIN_SUBMISSIONS_FILTERS,
  filters
})

export const updateAdminSubmissionsSorting = sorting => ({
  type: UPDATE_ADMIN_SUBMISSIONS_SORTING,
  sorting
})

export const updateSandboxSubmissionsPageSize = size => ({
  type: UPDATE_SANDBOX_SUBMISSIONS_PAGE_SIZE,
  size
})

export const updateSandboxSubmissionsTablePage = page => ({
  type: UPDATE_SANDBOX_SUBMISSIONS_TABLE_PAGE,
  page
})

export const updateSandboxSubmissionsFilters = filters => ({
  type: UPDATE_SANDBOX_SUBMISSIONS_FILTERS,
  filters
})

export const updateSandboxSubmissionsSorting = sorting => ({
  type: UPDATE_SANDBOX_SUBMISSIONS_SORTING,
  sorting
})

export const updateSandboxUsersPageSize = size => ({
  type: UPDATE_SANDBOX_USERS_PAGE_SIZE,
  size
})

export const updateSandobxUsersTablePage = page => ({
  type: UPDATE_SANDBOX_USERS_TABLE_PAGE,
  page
})

export const updateSandboxUsersFilters = filters => ({
  type: UPDATE_SANDBOX_USERS_FILTERS,
  filters
})

export const updateSandboxUsersSorting = sorting => ({
  type: UPDATE_SANDBOX_USERS_SORTING,
  sorting
})

export const setInjectedProcess = hash => ({
  type: SET_INJECTED_PROCESS,
  hash
})


// SAMPLE PAGE

export const updateAdminSamplesPageSize = size => ({
  type: UPDATE_ADMIN_SAMPLES_PAGE_SIZE,
  size
})

export const updateAdminSamplesTablePage = page => ({
  type: UPDATE_ADMIN_SAMPLES_TABLE_PAGE,
  page
})

export const updateAdminSamplesFilters = filters => ({
  type: UPDATE_ADMIN_SAMPLES_FILTERS,
  filters
})

export const updateAdminSamplesSorting = sorting => ({
  type: UPDATE_ADMIN_SAMPLES_SORTING,
  sorting
})