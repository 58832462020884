import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'underscore';

import ReactTable from "react-table";
import "react-table/react-table.css";

import {
  Container,
  Form,
  FormGroup,
  Label,
  Input,
  Button
} from 'reactstrap';

import { updateSandobxUsersTablePage,
  updateSandboxUsersPageSize,
  updateSandboxUsersFilters,
  updateSandboxUsersSorting } from '../../actions/sandbox';
import { statusSuccess } from '../../constants/api';
import { fetchUsers, fetchGroupInfo, editUser } from '../../services/users';
import { handleError } from '../../services/errorHandler';
import { localizeDate } from '../../utils/dates';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faListOl, faEdit } from '@fortawesome/free-solid-svg-icons';

import style from './style.scss';

class UsersList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      pages: null,
      loading: true,
      groups: [],
      userInfo: null
    };

    this.fetchData = this.fetchData.bind(this);
  }

  fetchUser = userId => {
    fetchUsers({ user_id: parseInt(userId) }).then(res => {
      if(res.status === statusSuccess) {
        if(res.data.rows.length === 0) {
          handleError('Error', 'No user with this user id');
        } else {
          this.setState({
            userInfo: res.data.rows[0]
          });
        }
      } else {
        handleError('Error', 'Error retrieving user data');
      }
    })
  }

  componentDidMount() {
    const { userId } = this.props.match.params;

    fetchGroupInfo().then(res => {
      if(res.status === statusSuccess) {
        this.setState({
          groups: Object.keys(res.data)
        });
      } else {
        handleError('Error', 'Error retrieving group information');
      }
    })

    if(userId)
      this.fetchUser(userId);
  }

  componentDidUpdate(prevProps) {
    const { userId } = this.props.match.params;
    const { userId: prevUserId } = prevProps.match.params;

    if(userId && userId !== prevUserId) {
      this.fetchUser(userId);
    } else if(!userId && userId !== prevUserId) {
      this.setState({
        userInfo: null
      })
    }
  }

  fetchData(state) {
    const { pageSize, page, filtered, sorted } = state;
    const { updateSandboxUsersPageSize, updateSandobxUsersTablePage, updateSandboxUsersFilters, updateSandboxUsersSorting } = this.props;
    const { usersPageSize } = this.props.sandbox;

    const name = _.findWhere(filtered, {id: 'name'});
    const email = _.findWhere(filtered, {id: 'email'});

    if(name && name.value && name.value.length < 3) return;
    if(email && email.value && email.value.length < 3) return;

    updateSandboxUsersSorting(sorted);
    updateSandobxUsersTablePage(page + 1);
    updateSandboxUsersFilters(filtered);

    if(usersPageSize !== pageSize)
      updateSandboxUsersPageSize(pageSize);

    this.setState({ loading: true });

    fetchUsers({
      page_size: pageSize,
      page_count: page + 1,
    }, filtered, sorted).then(res => {
      if(res.status === statusSuccess) {
        this.setState({
          data: res.data.rows,
          pages: Math.ceil(res.data.total / state.pageSize)
        });
      } else {
        handleError('error', 'Error retrieving submission data');
      }
    }).catch(
      this.setState({
        data: [],
        pages: 1
      })
    ).finally(() => {
      this.setState({
        loading: false
      })
    });
  }

  saveUserDetails = () => {
    const { email, group_name } = this.state.userInfo;
    editUser({ email, group_name }).then(res => {
      if(res.status === statusSuccess) {
        handleError('Success', ' User updated successfully', 'success');
      } else {
        handleError('Error', ' Error updating user details');
      }
    }).finally(() => {
      this.setState({
        userInfo: null
      }, () => {
        this.props.history.push('/admin/users');
      })
    }
    );
  }

  render() {
    const { data, pages, loading, userInfo, groups } = this.state;
    const { usersPage, usersPageSize, usersFilters, usersSorting } = this.props.sandbox;

    return (
      <Container fluid={!userInfo}>
        <h3>{userInfo ? 'Edit user details': 'Manage users'}</h3>
        {userInfo ?
          <Form>
            <FormGroup>
              <Label for="userName">Name</Label>
              <Input type="text" name="userName" id="userName" disabled value={userInfo.name} />
            </FormGroup>
            <FormGroup>
              <Label for="userEmail">Email</Label>
              <Input type="text" name="userEmail" id="userEmail" disabled value={userInfo.email} />
            </FormGroup>
            <FormGroup>
              <Label for="userGroup">Group name</Label>
              <Input
                type="select"
                name="userGroup"
                id="userGroup"
                value={this.state.userInfo.group_name}
                onChange={e => this.setState({
                  userInfo: {
                    ...userInfo,
                    group_name: e.target.value
                  }
                })}
              >
                {groups.map((group, idx) => <option key={idx}>{group}</option>)}
              </Input>
            </FormGroup>
            <FormGroup>
              <Button color="secondary" outline className="mr-3" onClick={() => { this.props.history.push('/admin/users'); }}>Cancel</Button>
              <Button color="primary" onClick={this.saveUserDetails}>Save and go back</Button>
            </FormGroup>
          </Form> :
          <ReactTable
            columns={[
              {
                Header: "Id",
                accessor: "id",
                maxWidth: 55,
                resizable: false,
                filterable: false
              },
              {
                Header: "Name",
                accessor: "name",
                Filter: ({ filter, onChange }) =>
                  <input
                    onChange={event => onChange(event.target.value)}
                    className={style.inputFullHeight}
                    value={filter ? filter.value : ""}
                    title="Please insert at least 3 characters"
                  />
              },
              {
                Header: "Email",
                accessor: "email",
                Filter: ({ filter, onChange }) =>
                  <input
                    onChange={event => onChange(event.target.value)}
                    className={style.inputFullHeight}
                    value={filter ? filter.value : ""}
                    title="Please insert at least 3 characters"
                  />
              },
              {
                Header: "Subscription date",
                id: "date",
                accessor: d => localizeDate(d.date),
                filterable: false
              },
              {
                Header: 'Group',
                accessor: "group_name",
                maxWidth: 95,
                resizable: false,
                sortable: false,
                Filter: ({ filter, onChange }) =>
                <select
                  onChange={event => onChange(event.target.value)}
                  className={style.selectFullHeight}
                  value={filter ? filter.value : ""}
                >
                  <option value=""></option>
                  {groups.map(d => <option key={d} value={d}>{d}</option>)}
                </select>
              },
              {
                Header: "Options",
                id: "options",
                accessor: d => <>
                  <Link to={`/admin/submissions/${d.id}`}><FontAwesomeIcon icon={faListOl} title={`Show ${d.email}'s submissions`} style={{ marginRight: '.5em'}}/></Link>
                  <Link to={`/admin/users/${d.id}`}><FontAwesomeIcon icon={faEdit} title={`Edit ${d.email} details`} /></Link>
                </>,
                sortable: false,
                filterable: false,
                maxWidth: 60,
                className: 'centeredCell'
              }
            ]}
            manual
            filterable
            minRows="3"
            data={data}
            pages={pages}
            loading={loading}
            onFetchData={this.fetchData}
            defaultPageSize={usersPageSize}
            pageSizeOptions={[25, 50, 100]}
            className="-striped -highlight"
            defaultPage={usersPage - 1}
            defaultFiltered={usersFilters}
            defaultSorted={usersSorting}
            multiSort={false}
          />
        }
      </Container>
    );
  }
};

export default connect(
  state => ({
    sandbox: state.sandbox
  }),
  dispatch => ({
    updateSandobxUsersTablePage: data => dispatch(updateSandobxUsersTablePage(data)),
    updateSandboxUsersPageSize: data => dispatch(updateSandboxUsersPageSize(data)),
    updateSandboxUsersFilters: data => dispatch(updateSandboxUsersFilters(data)),
    updateSandboxUsersSorting: data => dispatch(updateSandboxUsersSorting(data))
  })
)(UsersList);
