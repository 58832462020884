import {combineReducers} from 'redux';
import { connectRouter } from 'connected-react-router'

import auth from './auth';
import sandbox from './sandbox';
import tools from './tools';

export default history => combineReducers({
  router: connectRouter(history),
  auth,
  sandbox,
  tools
});
