import React from 'react'
import { toast } from 'react-toastify';

import style from './style.scss';

export function handleError(title, error, type = 'error', onClose = () => {}) {
    const content = (
        <div>
            <p className={style.errorTitle}>{title}</p>
            <p className={style.errorBody}>{error}</p>
        </div>
    );
    toast(content, { type, onClose });
}
