import React from 'react';
import style from './style.scss';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSkullCrossbones } from '@fortawesome/free-solid-svg-icons';

library.add(
  faSkullCrossbones
);

class NotFoundView extends React.Component {
  render() {
    return (
      <div className={style.notFoundContainer}>
        <p className={style.notFoundCode}><FontAwesomeIcon icon={faSkullCrossbones} /> 404</p>
        <p className={style.notFoundMessage}>page not found</p>
      </div>
    );
  }
};

export default NotFoundView;