import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';

import { adminGroup, qaGroup } from '../../constants/users';

class UserMenu extends React.Component {
  renderAdmin = group_name => {
    return [qaGroup, adminGroup].includes(group_name) ?
      <>
        <DropdownItem divider />
        <Link to="/dashboard">
          <DropdownItem>Statistics</DropdownItem>
        </Link>
        <DropdownItem divider />
        <Link to="/admin/users/">
          <DropdownItem>Manage Users</DropdownItem>
        </Link>
        <Link to="/admin/samples/">
          <DropdownItem>Manage Samples</DropdownItem>
        </Link>
        <Link to="/admin/submissions/">
          <DropdownItem>Manage Submissions</DropdownItem>
        </Link>
        <DropdownItem divider />
        <Link to="/admin/servers/">
          <DropdownItem>Server status</DropdownItem>
        </Link>
      </> : null;
  }

  render() {
    const { group_name } = this.props.auth;

    return (
      <UncontrolledDropdown nav inNavbar style={{ minWidth: '55px'}}>
        <DropdownToggle nav caret>
          <FontAwesomeIcon
            icon={faUserCircle}
            size="lg"
          />
        </DropdownToggle>
        <DropdownMenu right>
          <Link to="/profile/">
            <DropdownItem>My Profile</DropdownItem>
          </Link>
          <Link to="/profile/apikey/">
            <DropdownItem>Api key</DropdownItem>
          </Link>
          <Link to="/profile/submissions/">
            <DropdownItem>My submissions</DropdownItem>
          </Link>
          {this.renderAdmin(group_name)}
          <DropdownItem divider />
          <Link to="/auth/logout/success">
            <DropdownItem>Logout</DropdownItem>
          </Link>
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  }
}

export default withRouter(connect(
  store => ({
    auth: store.auth
  })
)(UserMenu));