import React, { Component } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusSquare, faPlusSquare, faAlignJustify, faIndent, faListUl } from '@fortawesome/free-solid-svg-icons';
import BadgeHTTP from '../BadgeHttp';
import ConnectionEventIcon from '../ConnectionEventIcon';
import { isEmptyArray } from '../../utils/objects';
import style from './style.scss';

export default class TCPConnectionRows extends Component {
  constructor(props) {
    super(props);

    this.state = {
      opened: false
    }
  }

  toggleRow = () => {
    this.setState({
      opened: !this.state.opened
    })
  }

  render() {
    const { opened } = this.state;
    const { connection, loadDataConnection, unwrapExtractedString, showStringsInBuffer } = this.props;
    const { source_ip, source_port, destination_ip, destination_port, conn_id, protocol, ssl, data  } = connection;

    const sslString = ssl && ` - ssl: ${<strong>ssl.toString()</strong>}`;

    return <>
      <tr>
        <td className='centeredCell' style={{ verticalAlign: 'middle'}}>
          {!!data.length && <FontAwesomeIcon icon={opened ? faMinusSquare : faPlusSquare } onClick={this.toggleRow} className='cursorPointer'/>}
        </td>
        <td>
          <strong>connection</strong>
          {protocol && (
            <>
              <BadgeHTTP value={protocol} type="protocol" style={{ marginLeft: '5px'}}/>
              {sslString}
            </>
          )}
        </td>
        <td>{`${source_ip} : ${source_port}`}</td>
        <td>{`${destination_ip} : ${destination_port}`}</td>
        <td>&nbsp;</td>
        <td>&nbsp;</td>
        <td>&nbsp;</td>
        <td>&nbsp;</td>
      </tr>
      {data.map((conn, idx) => <tr key={idx} className={opened ? style.opened : style.closed}>
        <td className='centeredCell'>
          <FontAwesomeIcon
            icon={faAlignJustify}
            className='cursorPointer'
            title={'Click to show buffer data'}
            onClick={() => { loadDataConnection('tcp', conn_id, conn.event_type, conn.event_id); }}
          />
          {conn.extracted_strings && !isEmptyArray(conn.extracted_strings) && (
            <FontAwesomeIcon
              icon={faIndent}
              style={{marginLeft: '8px'}}
              title={'Click to show extracted strings'}
              className={[style.extractedString, 'cursorPointer'].join(" ")}
              onClick={() => { unwrapExtractedString(conn.extracted_strings) }}
            />
          )}
          <FontAwesomeIcon
            icon={faListUl}
            style={{marginLeft: '8px'}}
            title="Click to show strings in buffer"        
            className='cursorPointer'
            onClick={() => { showStringsInBuffer(conn.strings_in_buffer); }}
          />
        </td>
        <td>
          {<ConnectionEventIcon type={conn.event_type} />} {conn.method && <BadgeHTTP value={conn.method} type="method" />} {conn.status_code && <BadgeHTTP value={conn.status_code} type="status_code" />} 
        </td>
        <td>&nbsp;</td>
        <td>&nbsp;</td>
        <td>{conn.url}</td>
        <td>{conn.referer}</td>
        <td>{conn.user_agent}</td>
        <td>{conn.data_size}</td>
      </tr>)}
    </>;
  }
}