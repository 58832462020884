export const UPDATE_USER_ON_LOGIN = 'UPDATE_USER_ON_LOGIN';
export const UPDATE_USER_ON_LOGOUT = 'UPDATE_USER_ON_LOGOUT';
export const UPDATE_TOKEN = 'UPDATE_TOKEN';
export const UPDATE_LAST_CHECK = 'UPDATE_LAST_CHECK';

export const updateUserDataOnLogin = data => ({
  type: UPDATE_USER_ON_LOGIN,
  data
})

export const updateUserDataOnLogout = () => ({
  type: UPDATE_USER_ON_LOGOUT
})

export const updateToken = data => ({
  type: UPDATE_TOKEN,
  data
})

export const updateLastCheck = data => ({
  type: UPDATE_LAST_CHECK,
  data
})