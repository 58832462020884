import { authedPostRequest, switchHash } from '../utils/request';

export function fetchReportSection(section, platform, hash) {
  switch(section) {
    case 'screenshot':
      return fetchScreenshot(platform, hash);
    case 'logs':
      return fetchLogs(platform, hash);
    case 'overview':
      return fetchOverview(hash);
    case 'diagnostics':
      return fetchDiagnostics(hash);
    case 'dynamic':
    case 'dropped':
    case 'network':
      return fetchDynamic(platform, hash);
    case '_static':
    default:
      return fetchStatic(hash);
  }
}

const fetchDynamic = (platform, hash) => {
  const body = {
    ...switchHash(hash),
    output_filters: ["dynamic"],
    platform
  };

 return authedPostRequest('sandbox/report', { body });
}


const fetchStatic = hash => {
  const body = {
    ...switchHash(hash),
    output_filters: ["static"]
  };

 return authedPostRequest('sandbox/report', { body });
}

const fetchDiagnostics = hash => {
  const body = {
    ...switchHash(hash),
  };

 return authedPostRequest('sandbox/report/diagnostics', { body });
}
  
const fetchOverview = hash => {
  const body = {
    ...switchHash(hash),
  };

  return authedPostRequest('sandbox/report', { body });
}
  
const fetchScreenshot = (platform, hash) => {
  const body = {
    ...switchHash(hash),
    platform
  };

  return authedPostRequest('sandbox/report/screenshot', { body });
}

const fetchLogs = (platform, hash) => {
  const body = {
    ...switchHash(hash),
    platform
  };

  return authedPostRequest('sandbox/report/log', { body });
}