import React, { Component } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusSquare, faPlusSquare, faAlignJustify } from '@fortawesome/free-solid-svg-icons';

import style from './style.scss';

export default class TCPConnectionRows extends Component {
  constructor(props) {
    super(props);

    this.state = {
      opened: false
    }
  }

  toggleRow = () => {
    this.setState({
      opened: !this.state.opened
    })
  }

  render() {
    const { opened } = this.state;
    const { connection, loadDataConnection } = this.props;
    const { ip, connection_id, port, protocol, send, receive } = connection;

    return <>
      <tr>
        <td className='centeredCell' style={{ verticalAlign: 'middle'}}><FontAwesomeIcon icon={opened ? faMinusSquare : faPlusSquare } onClick={this.toggleRow} className='cursorPointer'/></td>
        <td><strong>connection</strong><br />(protocol: {protocol})</td>
        <td>{`${ip} : ${port}`}</td>
        <td>&nbsp;</td>
        <td>&nbsp;</td>
        <td>&nbsp;</td>
        <td>&nbsp;</td>
      </tr>
      {!!send && send.map((s, idx) => <tr key={idx} className={opened ? style.opened : style.closed}>
        <td className='centeredCell'><FontAwesomeIcon icon={faAlignJustify} className='cursorPointer' onClick={() => { loadDataConnection('tcp', connection_id, 'send', s.id); }} /></td>
        <td>send</td>
        <td>&nbsp;</td>
        <td>{s.url}</td>
        <td>{s.referer}</td>
        <td>{s.user_agent}</td>
        <td>{s.data_size}</td>
      </tr>)}

      {!!receive && receive.map((r, idx) => <tr key={idx}className={opened ? style.opened : style.closed}>
      <td className='centeredCell'><FontAwesomeIcon icon={faAlignJustify} className='cursorPointer' onClick={() => { loadDataConnection('tcp', connection_id, 'recv', r.id); }} /></td>
      <td>receive</td>
      <td>&nbsp;</td>
      <td>{r.url}</td>
      <td>{r.referer}</td>
      <td>{r.user_agent}</td>
      <td>{r.data_size}</td>
    </tr>)}
    </>;
  }
}