export const serviceName = 'Deepviz';

export const OSnames = {
  Windows7_32: 'Win7 32 bit',
  Windows7_64: 'Win7 64 bit',
  // Windows8_32: 'Win8 64 bit',
  // Windows8_64: 'Win8 64 bit',
  // Windows10_32: 'Win10 32 bit',
  // Windows10_64: 'Win10 64 bit',
}

export const SystemImpactCategories = {
  'FtpData': 'Access to FTP credentials',
  'SystemDocsData': 'Access to MRU and system recent activities',
  'ImData': 'Access to Instant Messaging credentials',
  'EmailData': 'Access to e-mail accounts and contacts',
  'SshData': 'Access to SSH and remote shell credentials',
  'BrowserData': 'Access to browser critical components',
  'PasswordManagerData': 'Access to password managers credentials',
  'NetworkSecurity': 'Compromise network security',
  'SystemSecurity': 'Compromise local security',
  'SystemAppearance': 'Changes system appearance',
  'SystemUsability': 'Changes system administrative tools configuration',
  'UserActivities': 'Intercept user activities'
}