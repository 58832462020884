import React from 'react';
import { Badge } from 'reactstrap';
import classnames from 'classnames';
import colors from '../../constants/colors';

export default class BadgeHTTP extends React.Component {
  render() {
    const { idx, value, className, onClick, type, style } = this.props;
    let backgroundColor = colors.DEFAULT_COLOR;

    if(type === 'method' && colors.HTTP_METHOD[value.toLowerCase()]) {
      backgroundColor = colors.HTTP_METHOD[value.toLowerCase()];
    } else if(type === 'status_code' && colors.HTTP_STATUS[value.charAt(0) + 'xx']) {
      backgroundColor = colors.HTTP_STATUS[value.charAt(0) + 'xx'];
    }

    return (
      !!value && <Badge
        className={classnames(value.replace(/\s/g, ''), className)}
        id={idx}
        onClick={onClick}
        style={{...style, backgroundColor: backgroundColor, color: 'white', verticalAlign: 'middle' }}
      >
        {value}
      </Badge>
    );
  }
}