import React, { Component } from 'react';
import { Tooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import { SystemImpactCategories } from '../../constants/names';
import style from './style.scss';

export default class CategoryIcon extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      tooltipOpen: false
    };
  }

  toggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }

  render() {
    const {tag, icon, active} = this.props;

    return (
      <>
        <div className={classNames(style.iconContainer, active ? style.active : '')}>
          <FontAwesomeIcon icon={icon} href="#" id={`Category_${tag}`} />
        </div>
        <Tooltip
          placement="bottom"
          isOpen={this.state.tooltipOpen}
          target={`Category_${tag}`}
          toggle={this.toggle}
          delay={{
            show: 0,
            hide: 50
          }}
        >
          {SystemImpactCategories[tag]}
        </Tooltip>
      </>
    );
  }
};