import {
  UPDATE_USER_ON_LOGIN,
  UPDATE_USER_ON_LOGOUT,
  UPDATE_TOKEN,
  UPDATE_LAST_CHECK
} from '../actions/auth';

const initialState = {
  id: null,
  name: null,
  email: null,
  group_name: null,
  token: null,
  last_check: 0
};

export default function auth(state = initialState, action) {
  switch (action.type) {
    case UPDATE_TOKEN:
      return {
        ...state,
        token: action.data.token
      }
    case UPDATE_USER_ON_LOGIN:
      return {
        ...state,
        id: action.data.id,
        name: action.data.name,
        email: action.data.email,
        group_name: action.data.group_name,
        token: action.data.token
      };
    case UPDATE_USER_ON_LOGOUT:
      return {
        ...initialState
      };
    case UPDATE_LAST_CHECK:
      return {
        ...state,
        last_check: action.data
      }
    default: {
      return state;
    }
  }
}
