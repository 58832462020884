import React, { Component, Fragment } from 'react';

import numeral from 'numeral';
import _ from 'underscore';

import {
  Row, Col, ListGroup, ListGroupItem, Container
} from 'reactstrap';
import PlatformIcon from '../../components/PlatformIcon';
import FullTitle from '../../components/FullTitle';
import VPNBadge from '../../components/VPNBadge';
import DBadge from '../../components/Badge';

import Loading from '../Loading';

import ReactTable from "react-table";
import "react-table/react-table.css";

import { localizeDate } from '../../utils/dates';
import { handleError } from '../../services/errorHandler';
import { fetchServerStatus } from '../../services/sandbox';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPowerOff,
  faSyncAlt,
  faExclamationCircle,
  faCheckCircle,
  faTimesCircle
} from '@fortawesome/free-solid-svg-icons';

import style from './style.scss';
import { isBoolean } from 'util';

library.add(
  faPowerOff,
  faSyncAlt,
  faExclamationCircle,
  faCheckCircle
);

class ServerStatus extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      report: null,
      sandbox: null,
      vpnContainer: null,
      rabbitmq: null
    }
  }

  componentDidMount() {
    this.setState({
      loading : true
    }, () => {
      fetchServerStatus().then(res => {
        if(res.status === 'success') {
          
          this.setState({
            ...res.data,
            loading: false
          });
        } else {
          handleError('Error', 'Error retrieving Server Status Data.');
        }
      }).catch(err => {
        handleError('Error', 'Error retrieving Server Status Data.');
      });
    })
  }

  sortMethod = (a, b) => {
    if(a === b) {
      return 0;
    } else if (a === '---') {
      return -1;
    } else if(b === '---') {
      return 1;
    } else {
      return a > b ? -1 : 1;
    }
  }

  renderSandbox = () => {
    const { sandbox } = this.state;

    return (!_.isEmpty(sandbox)) ?
      <div className={style.statusContainer}>
        <p>Sandbox</p>
        <ReactTable
          columns={[
            {
              Header: "Server",
              id: "server",
              accessor: d => d[0],
              maxWidth: 150
            },
            {
              Header: "Docker",
              id: "docker",
              accessor: d => d[1],
              maxWidth: 150
            },
            {
              Header: "Status",
              id: "status",
              maxWidth: 55,
              className: 'centeredCell',
              accessor: d => {
                if(d[2].updating) {
                  return <FontAwesomeIcon title="Status: Updating" icon={faSyncAlt} className={style.updating} />
                } else {
                  return <FontAwesomeIcon title={`Status: ${d[2].status}`} icon={faPowerOff} className={style[d[2].status.replace(/ /g, "")]} />
                }
              }
            },
            {
              Header: "Task",
              id: "task",
              accessor: d => d[2].task
            },
            {
              Header: "Time restore started",
              id: "timeRestoreStarted",
              accessor: d => localizeDate(d[2].time_restore_started),
              maxWidth: 150,
              sortMethod: this.sortMethod
            },
            {
              Header: "Platform",
              id: "platform",
              maxWidth: 80,
              className: 'centeredCell',
              accessor: d => {
                const platform = d[2].platform.split("_");
                return <PlatformIcon name={platform[0]} bit={platform[1]} />;
              }
            },
            {
              Header: "Restoring time",
              id: "restoringTime",
              accessor: d => d[2].restoring_time,
              maxWidth: 150
            },
            {
              Header: "Monitor",
              id: "monitor",
              accessor: d => d[2].monitor_version,
              maxWidth: 100
            },
            {
              Header: "Send config time",
              id: "sendConfigTime",
              accessor: d => d[2].send_config_time,
              maxWidth: 150
            }
          ]}
          minRows="0"
          data={sandbox}
          defaultPageSize={10}
          pageSizeOptions={[10, 25, 50]}
          className="-striped -highlight"
          getTrProps={() => ({
            style: {
              alignItems: 'center'
            }
          })}
          defaultSorted={[{
            id   : 'timeRestoreStarted',
            desc : true,
          }]}
        />
      </div> : null;
  }

  renderReport = () => {
    const { report } = this.state;

    return (!_.isEmpty(report)) ?
      <div className={style.statusContainer}>
        <p>Report</p>
        <ReactTable
          columns={[
            {
              Header: "Server name",
              id: "server",
              accessor: d => d[0],
              minWidth: 150,
              maxWidth: 150
            },
            {
              Header: "Docker name",
              id: "docker",
              accessor: d => d[1],
              minWidth: 150,
              maxWidth: 150
            },
            {
              Header: "Task",
              id: "task",
              accessor: d => d[2].task
            },
            {
              Header: "Task start time",
              id: "taskStartTime",
              accessor: d => localizeDate(d[2].start_time),
              minWidth: 150,
              maxWidth: 150,
              sortMethod: this.sortMethod
            },
            {
              Header: "Step",
              id: "step",
              accessor: d => d[2].step,
              minWidth: 300,
              maxWidth: 300
            },
            {
              Header: "Step start time",
              id: "stepStartTime",
              accessor: d => localizeDate(d[2].step_start_time),
              minWidth: 150,
              maxWidth: 150,
              sortMethod: this.sortMethod
            }
          ]}
          minRows="0"
          data={report}
          defaultPageSize={10}
          pageSizeOptions={[10, 25, 50]}
          className="-striped -highlight"
          defaultSorted={[{
            id   : 'taskStartTime',
            desc : true,
          }]}
        />
      </div> : null;
  }

  renderVpn = () => {
    const { vpnContainer } = this.state;

    return vpnContainer ?
      <div className={style.statusContainer}>
        <p>VPN</p>
        <Row>
        {Object.entries(vpnContainer).map((container) => {
          const containerLabel = container[0];
          const containerVPNs = container[1];

          return Object.entries(containerVPNs).map((vpn) => {
            const label = vpn[0];
            const status = vpn[1];
            return <VPNBadge containerLabel={containerLabel} label={label} status={status} key={vpn[0]} />
          });
        })}
        </Row>
      </div> : null;
  }

  renderRabbitmq = () => {
    const { rabbitmq } = this.state;
  
    let serviceStatus = '';
    if(rabbitmq && rabbitmq.status) {
      serviceStatus = rabbitmq.status === 'green' ?
      <FontAwesomeIcon icon={faCheckCircle} className={style.run} /> :
      rabbitmq.status === 'yellow' ?
      <FontAwesomeIcon icon={faExclamationCircle} className={style.updating} /> :
      <FontAwesomeIcon icon={faTimesCircle} className={style.shutoff} />;
    }

    return rabbitmq ?
      <div className={style.statusContainer}>
        <p>rabbitmq</p>
        <p>Service status: {serviceStatus}</p>
        <FullTitle title="Queues" />
        <ReactTable
          columns={[
            {
              Header: "Name",
              id: "name",
              accessor: "name"
            },
            {
              Header: "State",
              id: "state",
              accessor: d => <DBadge type="status" value={d.state === 'idle' ? d.state : 'success'} label={d.state} />
            },
            {
              Header: "Consumers",
              id: "consumers",
              accessor: "consumers"
            },
            {
              Header: "Messages",
              id: "messages",
              accessor: "messages"
            },
            {
              Header: "Messages ready",
              id: "messages_ready",
              accessor: "messages_ready"
            },
            {
              Header: "Messages unacknowledged",
              id: "messages_unacknowledged",
              accessor: "messages_unacknowledged"
            },
            {
              Header: "Avg egress rate",
              id: "avg_egress_rate",
              accessor: "avg_egress_rate"
            },
            {
              Header: "Avg ingress rate",
              id: "avg_ingress_rate",
              accessor: "avg_ingress_rate"
            },
            {
              Header: "Idle since",
              id: "idle_since",
              accessor: d => localizeDate(d.idle_since)
            },
          ]}
          minRows="0"
          data={rabbitmq.system_resources.queues}
          defaultPageSize={10}
          pageSizeOptions={[10, 25, 50]}
          className="-striped -highlight"
          getTrProps={() => ({
            style: {
              alignItems: 'center'
            }
        })}
      />
      <FullTitle title="Nodes" />
      {rabbitmq.system_resources.nodes.map((node, i) => {
        return <Fragment key={i}>
          <p className={style.nodeName}>Node #{i}</p>
          <Row>
          {Object.entries(node).map((nodeElement) => {
            return <Col xs="12" md="6" className={style.nodeProperties} key={nodeElement[0]}>
            <p>{nodeElement[0].replace("_", " ")}</p>
            <ListGroup>
              {Object.entries(nodeElement[1]).map((nodeValues) => {
                let value = '';

                if(isBoolean(nodeValues[1])) {
                  value = nodeValues[1] ?
                    <FontAwesomeIcon icon={faExclamationCircle} className={style.shutoff} /> : 
                    <FontAwesomeIcon title="No alarm detected" icon={faCheckCircle}  className={style.run} />;
                } else {
                  value = isNaN(nodeValues[1]) ? nodeValues[1] : numeral(nodeValues[1]).format('0,0');
                }

                return <ListGroupItem key={nodeValues[0]}>
                  {`${nodeValues[0].replace("_", " ")}:`} {value}
                </ListGroupItem>;
              })}
            </ListGroup>
            </Col>
          })}
          </Row>
        </Fragment>
      })}
      </div> : null;
  }

  render() {
    const { loading } = this.state;
    
    return (
      <Container fluid>
        {
          loading ? <Loading /> :
          <>
            {this.renderSandbox()}
            {this.renderReport()}
            {this.renderVpn()}
            {this.renderRabbitmq()}
          </>
        }
      </Container>
    );
  }
};

export default ServerStatus;
