import React from 'react';
import { Tooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default class ToolboxIcon extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      tooltipOpen: false
    };
  }

  toggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }

  render() {
    const { icon, label, onClick, spin } = this.props;

    return <>
      <div onClick={onClick} id={`Toolbox_${label.replace(/ /g, "_")}`}>
        <FontAwesomeIcon icon={icon} spin={spin} />
      </div>
      <Tooltip
        placement="bottom"
        isOpen={this.state.tooltipOpen}
        target={`Toolbox_${label.replace(/ /g, "_")}`}
        toggle={this.toggle}
        delay={{
          show: 0,
          hide: 50
        }}
      >
        {label}
      </Tooltip>
    </>
  }
}