import React from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { monoBlue } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import download from 'downloadjs';
import base64 from 'base-64';

import { parseCode } from '../../utils/objects';
import { maxCodeHighlightedLength } from '../../constants/sandbox';

export default class CodeHighlighter extends React.Component {
  downloadSnippet = code =>
    download(base64.decode(code), 'snippet.deepviz', 'text/plain')

  render() {
    const { code, base64 } = this.props;

    return code.length > maxCodeHighlightedLength ?
      <p className='mb-0 font-italic'>Extracted code is too long to display. Click <strong className='cursorPointer' onClick={() => this.downloadSnippet(code)}>here</strong> to download it.</p> :
      <SyntaxHighlighter
        codeTagProps={{ style: { wordBreak: 'break-all' }}}
        style={monoBlue}
        customStyle={{whiteSpace: 'pre-wrap', marginBottom: 0}}
        showLineNumbers
        language='text'
      >
        {parseCode(code, base64)}
      </SyntaxHighlighter>;
  }
}