import React from 'react';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindows } from '@fortawesome/free-brands-svg-icons';

import style from './style.scss';

library.add(
  faWindows
);

export default class PlatformIcon extends React.Component {
  render() {
    const {bit, name} = this.props;
    return (
      <div className={style.platformContainer}>
        <FontAwesomeIcon icon={faWindows} size="2x" className={style.icon} />
        <span className={style.platformBit}>{bit}</span>
        <span className={style.platformName}>{name}</span>
      </div>
    );
  }
}