import React from 'react';

import {
  Col
} from 'reactstrap';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe, faLock, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

import style from './style.scss';

library.add(
  faGlobe,
  faLock,
  faExclamationCircle
);

export default class VPNBadge extends React.Component {
  render() {
    let {containerLabel, label, status} = this.props;

    label = label.split("-")[0];
    return (
      <Col xs="12" md="6" lg="2">
        <div className={style.vpnContainer}>
          <div className={`${style.vpnIconContainer} ${style[status]}`} title={`VPN is ${status}`}>
            <FontAwesomeIcon icon={faGlobe} style={{ fontSize: '38px'}}/>
            { status === 'OK' ?
              <FontAwesomeIcon icon={faLock} className={`${style.vpnIconStatus}`} /> :
              <FontAwesomeIcon icon={faExclamationCircle} className={`${style.vpnIconStatus} ${style.vpnIconStatusDanger}`} />
            }
          </div>
          <p className={style.vpnContainerLabel}>{label}</p>
          <span className={style.containerLabel}>{containerLabel}</span>
        </div>
      </Col>
    );
  }
}