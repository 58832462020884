import React from 'react';
import { connect } from 'react-redux';

import { persistor } from '../../store';
import { updateUserDataOnLogout } from '../../actions/auth';
import { updateToolsDataOnLogout } from '../../actions/tools';
import { updateSandboxDataOnLogout } from '../../actions/sandbox';

import { oktaConfig } from '../../../config/okta';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandPaper } from '@fortawesome/free-solid-svg-icons';

import Loading from '../Loading';

import style from './style.scss';

class Logout extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loggedOut: false
    }
  }

  componentDidMount() {
    const { updateUserDataOnLogout, updateToolsDataOnLogout } = this.props;
    const { token } = this.props.auth;

    const { success } = this.props.match.params;

    if(success) {
      if(token) {
        updateUserDataOnLogout();
        updateToolsDataOnLogout();
        updateSandboxDataOnLogout();

        persistor.purge();
        window.location = oktaConfig.backendEntryPoint.logout + "?token=" + token;
        return null;
      } else {
        this.setState({
          loggedOut: true
        })
      }
    } else {
      updateUserDataOnLogout();
      updateSandboxDataOnLogout();

      window.location = oktaConfig.backendEntryPoint.login;
      return null;
    }
  }
  
  render() {
    const { loggedOut } = this.state;

    return loggedOut ? 
      <div className={style.loggedOutContainer}>
        <p className={style.loggedOutIcon}><FontAwesomeIcon icon={faHandPaper} /></p>
        <p>you've been logged out</p>
      </div> : <Loading />;
  }
};

export default connect(
  store => ({
    auth: store.auth,
    tools: store.auth
  }),
  dispatch => ({
    updateUserDataOnLogout: () => dispatch(updateUserDataOnLogout()),
    updateToolsDataOnLogout: () => dispatch(updateToolsDataOnLogout()),
    updateSandboxDataOnLogout: () => dispatch(updateSandboxDataOnLogout())
  })
)(Logout);