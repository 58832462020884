import React from 'react';
import { Table } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileDownload, faSpinner, faAlignJustify } from '@fortawesome/free-solid-svg-icons';
import download from 'downloadjs';

import { history } from '../../store/configureStore';
import { fetchSample } from '../../services/download';
import { statusNotFound } from '../../constants/api';
import { handleError } from '../../services/errorHandler';

import style from '../../containers/SandboxReport/ReportSections/style.scss';

export default class PeElement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDownloading: false
    }
  }

  downloadSample = hash => {
    this.setState({
      isDownloading: true
    }, () => {
      fetchSample(hash).then(response => {
        if(response && response.status ===  statusNotFound) {
          handleError('Error', 'Sample not found');
        } else {
          return response.blob();
        }
      }).then(myBlob => {
        this.setState({
          isDownloading: false
        }, () => {
          if(myBlob)
            return download(myBlob, `${hash}.deepviz`, 'application/octet-stream');
        })
      })
    })
  }

  render() {
    const { error, file_ext, md5, file_size, sha512 } = this.props.pe;
    const { idx } = this.props;

    return <Table size="sm" responsive bordered striped key={idx}>
    <thead>
      <tr>
        <td className={style.tableHeader}>Information</td>
        <td className={style.tableHeader}>Size</td>
        <td className={style.tableHeader}>Actions</td>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>
          {file_ext && <p className="mb-0"><strong>Extension:</strong> {file_ext}</p>}
          {md5 && <p className="mb-0"><strong>MD5:</strong> {md5}</p>}
          {error && <p className="mb-0"><strong>Error:</strong> {error}</p>}
        </td>
        <td>{file_size}</td>
        <td className={style.downloadDroppedFile} style={{ verticalAlign: 'middle'}}>
          {!error && <>
            <FontAwesomeIcon
            icon={faAlignJustify}
            title="Show report"
            onClick={() => {history.push(`/sandbox/report/${sha512}/`)}}
            />
            {this.state.isDownloading ?
              <FontAwesomeIcon icon={faSpinner} spin /> :
              <FontAwesomeIcon
                icon={faFileDownload}
                onClick={() => { this.downloadSample(sha512) }}
                title="Download file"
              />
            }
          </>}
        </td>
      </tr>
    </tbody>
  </Table>
  }
}