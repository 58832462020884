export const UPDATE_TOOLS_ON_LOGIN = 'UPDATE_TOOLS_ON_LOGIN';
export const UPDATE_TOOLS_ON_LOGOUT = 'UPDATE_TOOLS_ON_LOGOUT';
export const UPDATE_LAST_VISITED_PAGE= 'UPDATE_LAST_VISITED_PAGE';

export const updateLastVisitedPage = page => ({
  type: UPDATE_LAST_VISITED_PAGE,
  page
})

export const updateToolsDataOnLogin = data => ({
  type: UPDATE_TOOLS_ON_LOGIN,
  data
})

export const updateToolsDataOnLogout = () => ({
  type: UPDATE_TOOLS_ON_LOGOUT
})