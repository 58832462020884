import React from 'react';
import { Link } from 'react-router-dom';

import base64 from 'base-64';
import {
  InputGroup, Input, InputGroupAddon, Button,
  Container, Table
} from 'reactstrap';
import DBadge from '../../components/Badge';
import { fetchSimpleSearch } from '../../services/search';
import { localizeDate } from '../../utils/dates';

import styles from './style.scss';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { statusSuccess } from '../../constants/api';
import { cleanStatus, maliciousStatus, unknownStatus } from '../../constants/submissions';
import { handleError } from '../../services/errorHandler';
import TitleBanner from '../../components/TitleBanner';
import Loading from '../Loading';

library.add(
  faSearch
);


class SearchHome extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      inputValue: '',
      results: [],
      hasSearched: false,
      isLoading: false
    };
  }

  componentDidMount() {
    let { s } = this.props.match.params;
    if(s) {
      s = decodeURI(base64.decode(s));
      this.setState({ inputValue: s }, () => this.doSearch());
    }
  }

  componentDidUpdate(prevProps) {
    let { s } = this.props.match.params;
    if(s && s !== prevProps.match.params.s) {
      s = decodeURI(base64.decode(s));
      this.setState({ inputValue: s }, () => this.doSearch());
    }
  }

  doSearch = () => {
    const { inputValue } = this.state;
    
    if(inputValue) {
      this.setState({
        isLoading: true,
      }, () => {
        fetchSimpleSearch(inputValue).then(res => {
          if(res.status === statusSuccess) {
            this.setState({
              results: res.data
            })
          } else {
            handleError('Error', res.errmsg || 'Error retrieving search results')
          }
        }).finally(() => {
          this.setState({
            isLoading: false,
            hasSearched: true
          })
        });
      })
    } else {
      this.setState({
        results: []
      })
    }
  }

  checkInput = event => {
    if(event.key === 'Enter') this.doSearch();
  }

  updateInputValue = event => {
    this.setState({
      inputValue: event.target.value,
      hasSearched: false
    });
  }

  printResults = () => {
    const { results, inputValue, isLoading, hasSearched } = this.state;

    if(isLoading) {
      return <Loading />
    } else if(results.length > 0) {
      return (
        <Table striped>
          <thead>
            <tr>
              <th>Hash</th>
              <th>Result</th>
              <th>First submission</th>
              <th>Last submission</th>
            </tr>
          </thead>
          <tbody>
          {results.map((res, idx) =>
              <tr key={idx}>
                <td><Link to={`/sandbox/report/${res.md5}/`}>{res.md5}</Link></td>
                <td>
                  <DBadge
                    type="result"
                    value={res.result === 1 ? maliciousStatus : res.result === 0 ? cleanStatus : unknownStatus}
                    />
                </td>
                <td>{localizeDate(res.first_submission)}</td>
                <td>{localizeDate(res.last_submission)}</td>
              </tr>
          )}
          </tbody>
        </Table>
      );
    } else if(inputValue && hasSearched) {
      return <TitleBanner>No result for your search.</TitleBanner>
    } else {
      return null
    }
  }

  render() {
    return (
      <Container>
        <h3>Simple search</h3>
        <InputGroup size="sm" style={{ alignItems: "center" }} className={styles.searchBox}>
          <Input
            placeholder="Search"
            onChange={evt => this.updateInputValue(evt)}
            onKeyUp={this.checkInput}
            value={this.state.inputValue}
          />
          <InputGroupAddon addonType="append">
            <Button color="secondary" onClick={this.doSearch}>
              <FontAwesomeIcon icon={faSearch} />
            </Button>
          </InputGroupAddon>
        </InputGroup>
        {this.printResults()}
      </Container>
    );
  }
};

export default SearchHome;
