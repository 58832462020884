import React from 'react';
import { Table } from 'reactstrap';

import FlagIcon from '../../../../../components/FlagIcon';
import CodeHighlighter from '../../../../../components/CodeHighlighter';

import style from '../../style.scss';

export default class JsStatic10 extends React.Component {
  render() {
    const { data } = this.props;
    return data.map((d, idx) => {
      const {code, obfuscation, shellcode, exceptions, assembly_list} = d;

      return <Table size="sm" responsive bordered striped key={idx}>
        <tbody>
          <tr>
            <td className={style.title}>Code</td>
            <td>
              <CodeHighlighter code={code} base64={true} />
            </td>
          </tr>
          <tr>
            <td className={style.title}>Obfuscation</td>
            <td><FlagIcon flag={obfuscation} /></td>
          </tr>
          <tr>
            <td className={style.title}>Shellcode</td>
            <td><FlagIcon flag={shellcode} /></td>
          </tr>
          {!!exceptions.length && <tr>
            <td className={style.title}>Exceptions</td>
            <td>{exceptions.map((exception, idx) => {
              return <p className="mb-0" key={idx}>{exception}</p>
            })}</td>
          </tr>
          }
          {!!assembly_list.length && <tr>
            <td>Assembly list</td>
            <td>{assembly_list.map((assembly, idx) => <CodeHighlighter key={idx} code={assembly} />)}</td>
          </tr>
          }
        </tbody>
      </Table>
    });
  }
} 