import React from 'react';
import style from './style.scss';

export default class FullTitle extends React.Component {
  render() {
    const {title} = this.props;
    return (
      <p className={style.fullTitle}>
        {title}
      </p>
    );
  }
}