import React from 'react';
import base64 from 'base-64';
import style from './style.scss';
import FullTitle from '../../../components/FullTitle';
import ReportSection from '../../../components/ReportSection';

export default class Logs extends React.Component {
  render() {
    const { data } = this.props;

    if(data) {
      return <ReportSection title="Logs">
        <textarea className={style.logsContainer} readOnly value={base64.decode(data.toString())} />
      </ReportSection>
    } else {
      return <FullTitle title="No data available" />;
    }
  }
}