import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import download from 'downloadjs';
import ReactTable from "react-table";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import filesize from 'filesize';
import Flag from 'react-country-flag';

import "react-table/react-table.css";
import 'bootstrap-daterangepicker/daterangepicker.css';

import {
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  ButtonGroup,
  InputGroup, 
  Input, 
  InputGroupAddon,
  Col,
  Row
} from 'reactstrap';
import Dialog from 'react-bootstrap-dialog';

import { updateAdminSubmissionsTablePage,
  updateAdminSubmissionsFilters,
  updateAdminSubmissionsSorting } from '../../actions/sandbox';
import DBadge from '../../components/Badge';
import ResultRuleCounter from '../../components/ResultRuleCounter';

import { localizeDate } from '../../utils/dates';
import { submissionStatus,
  submissionResults,
  supportedFormats,
  supportedExtensions,
  cleanStatus,
  maliciousStatus,
  unsupportedStatus } from '../../constants/submissions';
import { maxTableElements } from '../../constants/sandbox';
import { adminGroup, qaGroup } from '../../constants/users';
import { statusProcessing, analysisCompleted, analysisFailed } from '../../constants/api';
import { fetchSubmissions } from '../../services/submissions';
import { fetchUsers } from '../../services/users';
import { fetchSample } from '../../services/download';
import { sendRescan, fixClassification } from '../../services/sandbox';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload,
  faSlidersH,
  faRedo,
  faUserAstronaut,
  faUserTie,
  faUserMd,
  faLaptopCode,
  faUserCheck,
  faMailBulk,
  faSearch,
  faTimes
} from '@fortawesome/free-solid-svg-icons';

import { faUser, faCalendar } from '@fortawesome/free-regular-svg-icons';

import { statusSuccess } from '../../constants/api';
import { handleError } from '../../services/errorHandler';

import style from './style.scss';

const deepvizLogo = require('../../../images/deepviz-logo.png');

class SandboxSubmissions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      currentPage: 0,
      pages: null,
      loading: false,
      userId: this.props.match.params.userId,
      userEmail: null,
      extraSearch: {
        currentKey: '',
        currentValue: ''
      },
      modalFixClassification: {
        visible: false,
        tempFixed: null,
        tempClassification: null,
        sha512: null,
        rescanAfterFixedClassification: false
      },
      currentExtra: {
        visible: false,
        data: null
      }
    };

    this.platformFilter = React.createRef();
    this.platformResult = React.createRef();
    this.formatFilter = React.createRef();
    this.extensionFilter = React.createRef();
    this.table = React.createRef();
  }

  componentDidMount() {
    const { userId } = this.props.match.params;

    if(userId) {
      this.setState({
        userId
      }, () => {
        fetchUsers({ user_id: parseInt(userId) }).then(res => {
          if(res.status === statusSuccess) {
            if(res.data.rows.length) {
              this.setState({
                userEmail: res.data.rows[0].email
              })
            }
          } else {
            handleError('error', 'Error retrieving user\'s submissions');
          }
        })
      })
    }
  }

  componentDidUpdate(prevProps) {
    const { userId: prevUserId } = prevProps.match.params;
    const { userId: newUserId } = this.props.match.params;
    
    if(prevUserId !== newUserId)
      window.location.reload();
  }

  fetchData = parameters => {
    const { currentPage, userId, extraSearch } = this.state;
    const { page, pageSize, sorted, filtered } = parameters;
    const {
      updateAdminSubmissionsFilters,
      updateAdminSubmissionsSorting } = this.props;

    // if the page settings changed just update those values without calling again the API
    if(currentPage !== page) {
      this.setState({ currentPage : page });
      updateAdminSubmissionsTablePage(page + 1);
      return;
    }

    updateAdminSubmissionsFilters(filtered);
    updateAdminSubmissionsSorting(sorted);

    this.setState({ loading: true });

    fetchSubmissions(
      userId,
      sorted,
      filtered,
      extraSearch
    ).then(res => {
      if(res.status === statusSuccess) {
        if (res.data.total > maxTableElements)
          res.data.total = maxTableElements;

        this.setState({
          pages: Math.ceil(res.data.total / pageSize),
          data: res.data.rows
        });
      } else {
        handleError('error', 'Error retrieving submission data');
      }
    }).catch(() => {
      this.setState({
        data: [],
        pages: 1
      });
    }).finally(() => {
      updateAdminSubmissionsTablePage(0);
      this.setState({
        currentPage: 0,
        loading: false
      })
    });
  }

  printGroup(group) {
    switch(group) {
      case 'edr': 
        return <FontAwesomeIcon icon={faLaptopCode} className={style.groupIcon} title="Submission from EDR" />;
      case 'cosmos':
          return <FontAwesomeIcon icon={faUserAstronaut} className={style.groupIcon} title="Submission from Cosmos" />;
      case 'admin':
          return <FontAwesomeIcon icon={faUserTie} className={style.groupIcon} title="Submission from Admin" />;
      case 'research':
          return <FontAwesomeIcon icon={faUserMd} className={style.groupIcon} title="Submission from Research" />;
      case 'sbx':
          return <img src={deepvizLogo.default} className={style.groupIcon} alt="Submission from Sbx" title="Submission from Sbx" />;
      case 'qa':
          return <FontAwesomeIcon icon={faUserCheck} className={style.groupIcon} title="Submission from QA" />;
      case 'mbet':
          return <FontAwesomeIcon icon={faMailBulk} className={style.groupIcon} title="Submission from MBET" />;
      default:
        return <FontAwesomeIcon icon={faUser} className={style.groupIcon} title="Submission from User" />;
    }
  }

  downloadSample = hash => {
    fetchSample(hash).then(response => {
      return response.blob();
    }).then(myBlob => {
      return download(myBlob, `${hash}.deepviz`, 'application/octet-stream');
    })
  }

  startRescan = hash => {
    this.dialog.show({
      title: 'Rescan',
      body: 'Do you want to start a rescan?',
      actions: [
        Dialog.CancelAction(),
        Dialog.Action(
          'Confirm',
          () => {
            sendRescan(hash).then(res => {
              if(res.status === statusSuccess) {
                handleError('Success', 'Rescan sent', 'success', () => {
                  this.setState(state => {
                    const newData = state.data.map(row => {
                      if(row.md5 !== hash) {
                        return row;
                      } else {
                        return {
                          ...row,
                          status: 'pending',
                          dynamic_analyses: [],
                          result: null
                        }
                      }
                    })
                    return {
                    data: newData
                  }});
                });
              } else if (res.status === statusProcessing) {
                handleError('Warning', 'Sample already in processing', 'warning');
              } else {
                handleError('Error', 'Error starting rescan');
              }
            }).finally(() => {
              this.setState({
                rescanInProgress: false
              });
            })
          },
          'btn-primary'
        )
      ],
      bsSize: 'small',
      onHide: dialog => {
        dialog.hide();
      }
    });
  }

  toggleFixClassification = e => {
    if(e) e.preventDefault();
    const { modalFixClassification } = this.state;
    this.setState({
      modalFixClassification: {
        visible: !modalFixClassification.visible,
        tempFixed: false,
        tempClassification: false,
        sha512: null,
        rescanAfterFixedClassification: false
      }
    });
  }

  toggleSampleSubmissionsList = e => {
    if(e) e.preventDefault();
    const { modalSampleSubmissionsList } = this.state;
    this.setState({
      modalSampleSubmissionsList: {
        ...modalSampleSubmissionsList,
        visible: !modalSampleSubmissionsList.visible
      }
    });
  }

  renderExtraInfo = () => {
    const { visible, data } = this.state.currentExtra;

    return (
      <Modal isOpen={visible} toggle={this.toggleExtraInfo} size="lg" style={{marginTop: '50px', maxWidth: '1200px', width: '80%', overflowX: 'auto'}}>
        <ModalHeader toggle={this.toggleExtraInfo}>Extra information</ModalHeader>
        <ModalBody>
          {data && Object.entries(data).map((e, i) => <p className={style.extraInfo}>{e[0]}: {e[1]}</p>)}
        </ModalBody>
      </Modal>
    );
  }

  renderExtraSearch = () => (
    <InputGroup size="sm" >
      <Input
        placeholder="Key"
        onKeyUp={this.checkInput}
        onChange={evt => this.updateInputCurrentKey(evt)}
        value={this.state.extraSearch.currentKey}
      />
      <Input
        placeholder="Value"
        onKeyUp={this.checkInput}
        onChange={evt => this.updateInputCurrentValue(evt)}
        value={this.state.extraSearch.currentValue}
      />
      <InputGroupAddon addonType="append">
        <Button color="secondary" onClick={this.updateTable}>
          <FontAwesomeIcon icon={faSearch} />
        </Button>
        &nbsp;
        <Button color="secondary" onClick={this.resetExtraSearchFields}>
          <FontAwesomeIcon icon={faTimes} />
        </Button>
      </InputGroupAddon>
    </InputGroup>
  )

  renderFixClassificationModal = () => {
    const { visible, tempFixed, tempClassification, rescanAfterFixedClassification } = this.state.modalFixClassification;

    return (
      <Modal isOpen={visible} toggle={this.toggleFixClassification}>
        <ModalHeader toggle={this.toggleFixClassification}>Fixed classification</ModalHeader>
        <ModalBody>
          <p className='mb-0'>Fixed classification:</p>
          <ButtonGroup className='mb-3'>
            <Button size="sm" color={tempFixed ? 'primary' : 'secondary'} onClick={() => this.changeTempFixed(true)} active={tempFixed}>YES</Button>
            <Button size="sm" color={!tempFixed ? 'primary' : 'secondary'} onClick={() => this.changeTempFixed(false)} active={!tempFixed}>NO</Button>
          </ButtonGroup>
          <p className='mb-0'>Classification result:</p>
          <ButtonGroup className='mb-3'>
            <Button size="sm" color={tempClassification === cleanStatus ? 'success' : 'secondary'} onClick={() => this.changetempClassification(cleanStatus)} active={tempClassification === cleanStatus}>Clean</Button>
            <Button size="sm" color={tempClassification === maliciousStatus ? 'danger' : 'secondary'} onClick={() => this.changetempClassification(maliciousStatus)} active={tempClassification === maliciousStatus}>Malicious</Button>
          </ButtonGroup>
          <p className='mb-0'>Rescan the sample:</p>
          <ButtonGroup>
            <Button size="sm" color={rescanAfterFixedClassification === true ? 'success' : 'secondary'} onClick={() => this.changeRescanAfterFixedClassification(true)} active={rescanAfterFixedClassification}>Yes</Button>
            <Button size="sm" color={rescanAfterFixedClassification === false ? 'danger' : 'secondary'} onClick={() => this.changeRescanAfterFixedClassification(false)} active={!rescanAfterFixedClassification}>No</Button>
          </ButtonGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.sendFixClassification}>Send</Button>
        </ModalFooter>
      </Modal>
    );
  }

  showExtraInfo = info => {
    this.setState({
      currentExtra: {
        visible: true,
        data: JSON.parse(atob(info))
      }
    });
  }

  toggleExtraInfo = e => {
    if(e) e.preventDefault();
    const { currentExtra } = this.state;

    this.setState({
      currentExtra: {
        visible: !currentExtra.visible,
        data: null
      }
    });
  }

  changeTempFixed = tempFixed => {
    const { modalFixClassification } = this.state;
    this.setState({
       modalFixClassification: {
        ...modalFixClassification,
        tempFixed
      }
    })
  }

  changetempClassification = tempClassification => {
    const { modalFixClassification } = this.state;
    this.setState({
       modalFixClassification: {
        ...modalFixClassification,
        tempClassification
      }
    })
  }

  changeRescanAfterFixedClassification = rescan => {
    const { modalFixClassification } = this.state;
    this.setState({
       modalFixClassification: {
        ...modalFixClassification,
        rescanAfterFixedClassification: rescan
      }
    })
  }

  sendFixClassification = () => {
    const { modalFixClassification } = this.state;
    const { tempFixed, tempClassification, rescanAfterFixedClassification, sha512 } = this.state.modalFixClassification;

    this.setState({
      modalFixClassification: {
        ...modalFixClassification,
        visible: false
      }
    }, () => {
      fixClassification(sha512, tempFixed, tempClassification, rescanAfterFixedClassification).then(res => {
        if(res.status === statusSuccess) {
          handleError('Success', 'Classification set', 'success', () => {
            this.setState(state => {
              const newData = state.data.map(row => {
                if(row.sha512 !== sha512) {
                  return row;
                } else {

                  if(rescanAfterFixedClassification) {
                    return {
                      ...row,
                      status: 'pending'
                    }
                  } else {
                    return {
                      ...row,
                      result: tempClassification,
                      fixed_classification: tempClassification
                    }                      
                  }
                }
              })
              return {
              data: newData
            }})
          });
        } else {
          handleError('Error', 'Error setting classification');
        }
      })
    });
  }

  checkInput = event => {
    if(event.key === 'Enter'){
      this.updateTable();
    }
  }

  updateInputCurrentKey(evt) {
    const event = evt.target.value;

    this.setState(prevState => ({
      extraSearch: {
        ...prevState.extraSearch,
        currentKey: event
      }
    }));
  }

  updateInputCurrentValue(evt) {
    const event = evt.target.value;

    this.setState(prevState => ({
      extraSearch: {
        ...prevState.extraSearch,
        currentValue: event
      }
    }));
  }

  updateTable = () => {
    const { currentKey, currentValue } = this.state.extraSearch;
    
    if(!currentKey || currentKey.trim().length === 0 || !currentValue || currentValue.trim().length === 0){
      handleError('Error', 'Key and Value must be both populated!');
    } else {
      this.fetchData(this.table.current.state);
    }
  }

  resetExtraSearchFields = () => {
    this.setState(prevState => ({
      extraSearch: {
        ...prevState.extraSearch,
        currentKey: '',
        currentValue: ''
      }
    }), () => {this.fetchData(this.table.current.state);})
  }

  render() {
    const { currentPage, data, loading, pages, userEmail } = this.state;
    const { adminSubmissionsPageSize,
      adminSubmissionsPage,
      adminSubmissionsFilters,
      adminSubmissionsSorting } = this.props.sandbox;
    const { group_name } = this.props.auth;
    return (
      <Container fluid>
        <Dialog ref={component => { this.dialog = component }} />
        {this.renderExtraInfo()}
        {this.renderFixClassificationModal()}

        <Row>
          <Col sm="7">
            <h3>Sandbox Submissions</h3>
          </Col>
          <Col sm="5">
            {this.renderExtraSearch()}
          </Col>
        </Row>
        
        {userEmail ? <h5>User: {userEmail}</h5> : null}
        <ReactTable
          columns={[
            {
              Header: "Filename",
              id: "filename",
              filterable: false,
              sortable: false,
              accessor: d => <Link to={`/sandbox/report/${d.sha512}/`}>{d.file_name}</Link>
            },
            {
              Header: "MD5",
              id: "md5",
              accessor: "md5",
              minWidth: 280,
              maxWidth: 280,
              filterable: false,
              sortable: false
            },
            {
              Header: "Size",
              id: "file_size",
              accessor: d => filesize(d.file_size),
              filterable: false,
              maxWidth: 100,
              minWidth: 100
            },
            {
              Header: "Format",
              id: "format",
              accessor: d => d.format === d.ext ? d.format : `${d.format} (${d.ext})`,
              className: 'centeredCell',
              maxWidth: 120,
              minWidth: 120,
              resizable: false,
              sortable: false,
              Filter:  ({ filter, onChange }) =>
              <>
                <select
                  className={style.doubleFilter}
                  onChange={event => onChange(`${event.target.value}|${this.extensionFilter.current.value}`)}
                  ref={this.formatFilter}
                  value={filter ? filter.value.split("|")[0] : ""}
                >
                  <option value="">FORMAT</option>
                  {supportedFormats.map(d => <option key={d} value={d}>{d}</option>)}
                </select>
                <select
                  className={style.doubleFilter}
                  onChange={event => onChange(`${this.formatFilter.current.value}|${event.target.value}`)}
                  ref={this.extensionFilter}
                  value={filter ? filter.value.split("|")[1] : ""}
                >
                  <option value="">EXTENSION</option>
                  {supportedExtensions.map(d => <option key={d} value={d}>{d}</option>)}
                </select>
              </>
            },
            {
              Header: "Timestamp",
              id: "timestamp",
              accessor: d => d.timestamp && localizeDate(d.timestamp),
              resizable: false,
              minWidth: 150,
              maxWidth: 150,
              Filter: ({ filter, onChange }) => <>
                <DateRangePicker
                  onApply={(_event, picker) => {
                    const startDate = picker.startDate.format('YYYY-MM-DD');
                    const endDate = picker.endDate.format('YYYY-MM-DD');

                    onChange(`${startDate}|${endDate}`);
                  }}

                  onCancel={() => onChange("")}
                >
                  <button className={style.timePickerButton}>
                    <FontAwesomeIcon
                      className={style.actionIcon}
                      icon={faCalendar}
                      title="Toggle filter"
                    />
                  </button>
                </DateRangePicker>
                {filter && <span className={style.resetRangeDate} onClick={() => onChange("")}>RESET</span>}
                <p className={style.rangeDate}>from: {filter ? filter.value.split("|")[0] : ""}</p>
                <p className={style.rangeDate}>to: {filter ? filter.value.split("|")[1] : ""}</p>
              </>
            },
            {
              Header: "Status",
              id: "status",
              resizable: false,
              sortable: false,
              className: 'centeredCell',
              minWidth: 120,
              maxWidth: 120,
              accessor: d => <DBadge type="status" value={d.status} />,
              Filter: ({ filter, onChange }) =>
                    <select
                      onChange={event => onChange(event.target.value)}
                      className={style.selectFullHeight}
                      value={filter ? filter.value : ""}
                    >
                      <option value=""></option>
                      {submissionStatus.map(d => {
                        if (d !== unsupportedStatus || [qaGroup, adminGroup].includes(group_name)) {
                          return <option key={d} value={d}>{d}</option>
                        } else {
                          return null;
                        }
                      })}
                    </select>
            },
            {
              Header: "Result",
              id: "result",
              accessor: d => d.result ? <ResultRuleCounter result={d.result} rules={d.rule_count} idx={d.md5} /> : '',
              Filter: ({ filter, onChange }) =>{
                    return <select
                      onChange={event => onChange(event.target.value)}
                      className={style.selectFullHeight}
                      value={filter ? filter.value : ""}
                    >
                      <option value=""></option>
                      {submissionResults.map(d => <option key={d} value={d}>{d}</option>)}
                    </select>},
              resizable: false,
              sortable: false,
              maxWidth: 100,
              minWidth: 100,
              className: 'centeredCell'
            },
            {
              Header: "Group",
              id: "group",
              accessor: d => this.printGroup(d.group),
              className: 'centeredCell',
              maxWidth: 55,
              sortable: false,
              filterable: false,
              resizable: false
            },
            {
              Header: "Country",
              id: "country",
              className: 'centeredCell',
              minWidth: 65,
              maxWidth: 65,
              sortable: false,
              filterable: false,
              resizable: false,
              accessor: d => d.geo_info && d.geo_info.country_code && d.geo_info.country_name &&
                <Flag code={d.geo_info.country_code} title={d.geo_info.country_name} svg styleProps={{
                  fontSize: '16px'
                }}/>
            },
            {
              Header: "Options",
              id: "options",
              accessor: d => <>
                <FontAwesomeIcon
                  className={style.actionIcon}
                  icon={faDownload}
                  onClick={() => this.downloadSample(d.md5)}
                  title="Download sample"
                />
                {d.status === analysisCompleted && <FontAwesomeIcon
                  className={style.actionIcon}
                  icon={faRedo}
                  onClick={() => this.startRescan(d.md5)}
                  title="Rescan sample"
                />}
                {(d.status === analysisFailed || d.status === analysisCompleted) && <FontAwesomeIcon
                  className={style.actionIcon}
                  icon={faSlidersH}
                  onClick={() => {
                    this.setState({
                      modalFixClassification: {
                        visible: true,
                        tempFixed: d.fixed_classification,
                        tempClassification: d.result,
                        sha512: d.sha512,
                        rescanAfterFixedClassification: false
                      }
                    });
                  }}
                  title="Fix sample classification"
                />      }          
              </>,
              filterable: false,
              sortable: false,
              resizable: false,
              className: 'centeredCell',
              minWidth: 150,
              maxWidth: 150
            }
          ]}
          manual
          minRows="3"
          data={data.slice(currentPage * adminSubmissionsPageSize, currentPage * adminSubmissionsPageSize + adminSubmissionsPageSize)}
          pages={pages}
          loading={loading}
          ref={this.table}
          onFetchData={this.fetchData}
          className="-striped -highlight"
          filterable
          defaultFiltered={adminSubmissionsFilters}
          defaultSorted={adminSubmissionsSorting}
          defaultPageSize={adminSubmissionsPageSize}
          pageSizeOptions={[adminSubmissionsPageSize]}
          defaultPage={adminSubmissionsPage - 1}
          showPageSizeOptions={false}
        />
        <p className={style.pleaseUseFilters}>Only the first 2.000 results are retrieved. Please use filters to narrow the result.</p>
      </Container>
    );
  }
};

export default connect(
  state => ({
    sandbox: state.sandbox,
    auth: state.auth
  }),
  dispatch => ({
    updateAdminSubmissionsFilters: data => dispatch(updateAdminSubmissionsFilters(data)),
    updateAdminSubmissionsSorting: data => dispatch(updateAdminSubmissionsSorting(data))
  })
)(SandboxSubmissions);
