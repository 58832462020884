import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import _ from 'underscore';
import filesize from 'filesize';

import ReactTable from "react-table";
import DateRangePicker from 'react-bootstrap-daterangepicker';

import {
  Container
} from 'reactstrap';

import "react-table/react-table.css";
import 'bootstrap-daterangepicker/daterangepicker.css';

import { updateSandboxSubmissionsFilters,
  updateSandboxSubmissionsSorting } from '../../actions/sandbox';
import DBadge from '../../components/Badge';
import ResultRuleCounter from '../../components/ResultRuleCounter';
import { statusSuccess } from '../../constants/api';
import { maxTableElements } from '../../constants/sandbox';
import { adminGroup, qaGroup } from '../../constants/users';
import { handleError } from '../../services/errorHandler';

import { localizeDate } from '../../utils/dates';
import { submissionStatus,
  submissionResults,
  supportedFormats,
  supportedExtensions,
  unsupportedStatus } from '../../constants/submissions';
import { fetchSubmissions } from '../../services/submissions';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';

import style from './style.scss';

class ProfileSubmissions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: true
    };

    this.fetchData = this.fetchData.bind(this);
    this.formatFilter = React.createRef();
    this.extensionFilter = React.createRef();
  }

  fetchData(state) {
    const { id } = this.props.auth;
    const { sorted, filtered } = state;
    const { updateSandboxSubmissionsFilters, updateSandboxSubmissionsSorting } = this.props;
    
    const filename = _.findWhere(filtered, {id: 'filename'});
    const md5 = _.findWhere(filtered, {id: 'md5'});

    if(filename && filename.value && filename.value.length < 3) return;
    if(md5 && md5.value && md5.value.length !== 32) return;

    updateSandboxSubmissionsFilters(filtered);
    updateSandboxSubmissionsSorting(sorted);

    this.setState({ loading: true });

    fetchSubmissions(
      id,
      sorted,
      filtered
    ).then(res => {
      if(res.status === statusSuccess) {
        if(res.data.total > maxTableElements)
          res.data.total = maxTableElements;

        this.setState({
          data: res.data.rows
        });
      } else {
        handleError('error', 'Error retrieving submission data');
      }
    }).catch(() => {
      this.setState({
        data: []
      });
    }).finally(() => {
      this.setState({
        loading: false
      })
    });
  }

  render() {
    const { data, loading } = this.state;
    const { submissionsFilters, submissionsSorting } = this.props.sandbox;
    const { group_name } = this.props.auth;

    return (
      <Container fluid>
        <h3>My submissions</h3>
        <ReactTable
          columns={[
            {
              Header: "Filename",
              id: "file_name",
              sortable: true,
              accessor: d => <Link to={`/sandbox/report/${d.sha512}/`}>{d.file_name}</Link>,
              
              Filter: ({ filter, onChange }) =>
                <input
                  onChange={event => onChange(event.target.value)}
                  className={style.inputFullHeight}
                  value={filter ? filter.value : ""}
                  title="Please insert at least 3 characters"
                />
            },
            {
              Header: "MD5",
              id: "md5",
              accessor: d => d.md5,
              minWidth: 280,
              maxWidth: 280,
              sortable: false,
              Filter: ({ filter, onChange }) =>
                <input
                  onChange={event => onChange(event.target.value)}
                  className={style.inputFullHeight}
                  value={filter ? filter.value : ""}
                  title="Please insert 32 characters"
                />
            },
            {
              Header: "Size",
              id: "file_size",
              accessor: d => filesize(d.file_size),
              filterable: false,
              maxWidth: 100,
              minWidth: 100
            },
            {
              Header: "Format",
              id: "format",
              accessor: d => d.file_type.format === d.file_type.ext ? d.file_type.format : `${d.file_type.format} (${d.file_type.ext})`,
              className: 'centeredCell',
              maxWidth: 120,
              minWidth: 120,
              resizable: false,
              sortable: false,
              Filter:  ({ filter, onChange }) =>
              <>
                <select
                  className={style.doubleFilter}
                  onChange={event => onChange(`${event.target.value}|${this.extensionFilter.current.value}`)}
                  ref={this.formatFilter}
                  value={filter ? filter.value.split("|")[0] : ""}
                >
                  <option value="">FORMAT</option>
                  {supportedFormats.map(d => <option key={d} value={d}>{d}</option>)}
                </select>
                <select
                  className={style.doubleFilter}
                  onChange={event => onChange(`${this.formatFilter.current.value}|${event.target.value}`)}
                  ref={this.extensionFilter}
                  value={filter ? filter.value.split("|")[1] : ""}
                >
                  <option value="">EXTENSION</option>
                  {supportedExtensions.map(d => <option key={d} value={d}>{d}</option>)}
                </select>
              </>
            },
            {
              Header: "Timestamp",
              id: "timestamp",
              accessor: d => d.timestamp && localizeDate(d.timestamp),
              resizable: false,
              minWidth: 150,
              maxWidth: 150,
              Filter: ({ filter, onChange }) => <>
                <DateRangePicker
                  onApply={(_event, picker) => {
                    const startDate = picker.startDate.format('YYYY-MM-DD');
                    const endDate = picker.endDate.format('YYYY-MM-DD');

                    onChange(`${startDate}|${endDate}`);
                  }}

                  onCancel={() => onChange("")}
                >
                  <button className={style.timePickerButton}>
                    <FontAwesomeIcon
                      className={style.actionIcon}
                      icon={faCalendar}
                      title="Toggle filter"
                    />
                  </button>
                </DateRangePicker>
                {filter && <span className={style.resetRangeDate} onClick={() => onChange("")}>RESET</span>}
                <p className={style.rangeDate}>from: {filter ? filter.value.split("|")[0] : ""}</p>
                <p className={style.rangeDate}>to: {filter ? filter.value.split("|")[1] : ""}</p>
              </>
            },         
            {
              Header: "Status",
              id: "status",
              resizable: false,
              sortable: false,
              className: 'centeredCell',
              minWidth: 120,
              maxWidth: 120,
              accessor: d => <DBadge type="status" value={d.status} />,
              Filter: ({ filter, onChange }) =>
                    <select
                      onChange={event => onChange(event.target.value)}
                      className={style.selectFullHeight}
                      value={filter ? filter.value : ""}
                    >
                      <option value=""></option>
                      {submissionStatus.map(d => {
                        if (d !== unsupportedStatus || [qaGroup, adminGroup].includes(group_name)) {
                          return <option key={d} value={d}>{d}</option>
                        } else {
                          return null;
                        }
                      })}
                    </select>
            },
            {
              Header: "Result",
              id: "result",
              accessor: d => d.result ? <ResultRuleCounter result={d.result} rules={d.rule_count} idx={d.md5} /> : '',
              Filter: ({ filter, onChange }) =>{
                    return <select
                      onChange={event => onChange(event.target.value)}
                      className={style.selectFullHeight}
                      value={filter ? filter.value : ""}
                    >
                      <option value=""></option>
                      {submissionResults.map(d => <option key={d} value={d}>{d}</option>)}
                    </select>},
              resizable: false,
              sortable: false,
              maxWidth: 100,
              minWidth: 100,
              className: 'centeredCell'
            }
          ]}
          manual
          minRows="3"
          data={data}
          loading={loading}
          onFetchData={this.fetchData}
          className="-striped -highlight"
          filterable
          defaultFiltered={submissionsFilters}
          defaultSorted={submissionsSorting}
          showPagination={false}
        />
        <p className={style.pleaseUseFilters}>Only the first 2.000 results are retrieved. Please use filters to narrow the result.</p>
      </Container>
    );
  }
};

export default connect(
  state => ({
    sandbox: state.sandbox,
    auth: state.auth
  }),
  dispatch => ({
    updateSandboxSubmissionsFilters: data => dispatch(updateSandboxSubmissionsFilters(data)),
    updateSandboxSubmissionsSorting: data => dispatch(updateSandboxSubmissionsSorting(data))
  })
)(ProfileSubmissions);
