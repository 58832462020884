import colors from '../styles/variables.scss';

export const RESULTS = {
  malicious: colors.dangerRed,
  clean: colors.successGreen,
  unknown: colors.mediumGray,
  uploaded: colors.darkGray
}

export const STATUS = {
  success: colors.successGreen,
  failed: colors.dangerRed,
  'not started': colors.mediumGray,
  critical:  colors.darkGray
}

export const EXTENSIONS = {
  "doc": colors.graphColor01,
  "xls": colors.graphColor02,
  "ppt": colors.graphColor03,
  "pdf": colors.graphColor04,
  "msi": colors.graphColor05,
  "exe32": colors.graphColor06,
  "exe64": colors.graphColor07,
  "dll32": colors.graphColor08,
  "dll64": colors.graphColor09,
  "wsf": colors.graphColor10,
  "js": colors.graphColor11,
  "rtf": colors.graphColor12,
  "xlsx": colors.graphColor13,
  "docx": colors.graphColor14,
  "pptx": colors.graphColor15
}

export const DEFAULT_COLOR = colors.darkGray;

export const HTTP_METHOD = {
  get: colors.getMethod,
  post: colors.postStatus,
  delete: colors.deleteStatus,
  put: colors.putStatus
}

export const HTTP_STATUS = {
  '1xx': colors.informationalResponseStatus,
  '2xx': colors.successStatus,
  '3xx': colors.redirectionStatus,
  '4xx': colors.clientErrorStatus,
  '5xx': colors.serverErrorStatus
}

export default {
  RESULTS,
  STATUS,
  EXTENSIONS,
  HTTP_METHOD,
  HTTP_STATUS,
  DEFAULT_COLOR
}