import React, {Component} from 'react';

import { Table } from 'reactstrap';
import classnames from 'classnames';
import { faPlusSquare, faMinusSquare } from '@fortawesome/free-regular-svg-icons';

import TitleBanner from '../../../../../components/TitleBanner';

import style from '../../style.scss';

export default class Registry extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hidden: false
    }
  }

  toggleVisibility = () => {
    this.setState(prevState => ({
      hidden: !prevState.hidden
    }));
  }

  renderRegistryKeyTable = registry => <Table size="sm" responsive bordered striped>
    <tbody>
      {registry.map((reg, idx) => <tr key={idx}>
        <td className={style.title}>Key</td>
        <td>{reg.key}</td>
      </tr>)}
    </tbody>
  </Table>

  renderRegistryTable = (activity, idx) => {
    const { key, value_name, value_type, value_data, value_data_size } = activity;

    return <Table size="sm" responsive bordered striped key={idx}>
      <tbody>
        <tr>
          <td className={style.title}>Key</td>
          <td className="containsLongWords">{key}</td>
        </tr>
        {!!value_name && <tr>
          <td className={style.title}>Name</td>
          <td className="containsLongWords">{value_name}</td>
        </tr>}
        {!!value_type && <tr>
          <td className={style.title}>Type</td>
          <td className="containsLongWords">{value_type}</td>
        </tr>}
        {!!value_data && <tr>
          <td className={style.title}>Data</td>
          <td className="containsLongWords">{value_data}</td>
        </tr>}
        {!!value_data_size && <tr>
          <td className={style.title}>Size</td>
          <td className="containsLongWords">{value_data_size}</td>
        </tr>}
      </tbody>
    </Table>
  }

  render() {
    const { hidden } = this.state;
    const { data } = this.props;

    return <>
      <TitleBanner
        size='big'
        icon={hidden ? faPlusSquare : faMinusSquare}
        onClick={this.toggleVisibility}
      >
        {data[0].replace(/_/g, ' ')} [{data[1].length}]
      </TitleBanner>
      <div className={classnames({ 'd-none': hidden})}>
        {data[0] === 'open_key' || data[0] === 'create_key' ?
        this.renderRegistryKeyTable(data[1]) : 
        data[1].map((reg, idx) => this.renderRegistryTable(reg, idx))}
      </div>
    </>
  }
}