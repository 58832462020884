import React from 'react';

import FullTitle from '../../../../../components/FullTitle';
import JsStatic10 from './1.0';

export default class JsStatic extends React.Component {
  render() {
    const { data, report_version } = this.props;

    if(!data) {
      return <FullTitle title="No data available" />;
    } else if(Array.isArray(data) && data.length === 0) {
      return <FullTitle title="No relevant data available" />;
    } else {
      switch(report_version) {
        case '1.0':
        default:
          return <JsStatic10 data={data} />;
      }
    }
  }
}