import _ from 'underscore';
import { authedRequest, authedPutRequest, authedPostRequest } from '../utils/request';

export const fetchGroupInfo = (groupName = null) =>
  authedRequest(groupName ? `account/group?group_name=${groupName}` : 'account/group')

export const editUser = user =>
  authedPutRequest(`account/user`, { body: user })

export const fetchUser = token => {
  const headers = new Headers({
    'Okta-token': token
  });

  return authedRequest('account/user', { headers });
}

export const fetchApiKey = () =>
  authedRequest('account/user/key');

export const renewApiKey = () => 
  authedPutRequest('account/user/key');

export const fetchUsers = (params, filtered = [], sorted = []) => {
  const id = _.findWhere(filtered, {id: 'id'});
  if(id) params.user_id = parseInt(id.value);

  const name = _.findWhere(filtered, {id: 'name'});
  if(name && name.value && name.value.length >= 3) params.name = name.value;

  const email = _.findWhere(filtered, {id: 'email'});
  if(email && email.value && email.value.length >= 3) params.email = email.value;

  const group_name = _.findWhere(filtered, {id: 'group_name'});
  if(group_name && group_name.value) params.group_name = group_name.value;

  if(sorted && sorted.length > 0) {
    params.sort_by = sorted[0].id;
    params.reverse = sorted[0].desc;
  }

  return authedPostRequest('account/user', { body: params })
}

export const checkUserAccount = () =>
  authedRequest('account/user/check');