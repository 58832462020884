import React from 'react';
import _ from 'underscore';
import { Container, Row, Col, Card, CardBody, CardTitle, CardText, Form, FormGroup, Label, Input } from 'reactstrap';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer, RadialBarChart, RadialBar, Area,
  ComposedChart, Bar, XAxis, YAxis, CartesianGrid, Line, LineChart } from 'recharts';
import NumericLabel from 'react-pretty-numbers';

import NoDataAvailable from '../../components/NoDataAvailable';
import Loading from '../../containers/Loading';
import { timeWindows } from '../../constants/sandbox';
import { statusSuccess } from '../../constants/api';
import colors from '../../constants/colors';
import { getStatsCounters, getStatsCharts } from '../../services/sandbox';
import { parseStats } from '../../utils/objects';

import style from './style.scss';

class CustomizedAxisTick extends React.Component {
  render () {
    const {x, y, payload} = this.props;
    
    const label = payload.value;
    const maxLength = 10;

    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">
          {label.length <= maxLength ? label :
            <>
              <tspan x="0" dy="1.2em">{label.substr(0, maxLength)}</tspan>
              <tspan x="0" dy="1.2em">{label.substr(maxLength + 1)}</tspan>
            </>
          }
        </text>
      </g>
    );
  }
}

class CustomSingleTooltip extends React.Component {
  render() {
    const { active } = this.props;

    if (active) {
      const { payload } = this.props; 

      return (
        <div className={style.customTooltip}>
          <p className="label" style={{color: payload[0].payload.fill}}>{`${payload[0].payload.name} : ${payload[0].value}`}</p>
        </div>
      );
    }

    return null;
  }
}

class CustomMultiTooltip extends React.Component {
  render() {
    const { active } = this.props;

    if (active) {
      const { payload } = this.props;
      return (
        <div className={style.customTooltip}>
        {payload[0] && <p><strong>{payload[0].payload.date}</strong></p>}
          {_.sortBy(payload, el => el.value * -1).map((entry, idx) => /* multiply by -1 is for descending order */
            <p key={idx} style={{color: entry.stroke ? entry.stroke : entry.fill}} className="label">{`${entry.dataKey} : ${entry.payload[entry.dataKey]}`}</p>)
          }
        </div>
      );
    }

    return null;
  }
}

export default class DashboardStatistics extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      timeWindow: 'All',
      resultOverTime: {},
      extOverTime: {},
      total: 0,
      pending: 0,
      unsupported: 0,
      processing: 0,
      classification: [],
      extension: [],
      analyses: []
    }
  }

  fetchData = () => {
    const { timeWindow } = this.state;

    this.setState({
      isLoading: true
    }, () => {
      Promise.all([
        getStatsCounters(timeWindow).then(res => {
          if(res.status === statusSuccess) {
            const { total, pending, processing, unsupported, classification, analyses, extension } = res.data;
            this.setState({
              total,
              pending,
              processing,
              unsupported,
              classification: parseStats(classification),
              analyses: parseStats(analyses),
              extension: parseStats(extension)
            })
          }
        }),
        getStatsCharts(timeWindow).then(res => {
          if(res.status === statusSuccess) {
            this.setState({
              resultOverTime: res.data.result,
              extOverTime: res.data.ext
            })
          }
        })
      ]).finally(() => {
        this.setState({
          isLoading: false
        })
      });
    })
  }

  componentDidMount() {
    this.fetchData();
  }

  updateTimeWindow = event => {
    this.setState({
      timeWindow: event.target.value
    }, this.fetchData)
  }

  render() {
    const { isLoading, timeWindow, total, pending, processing, classification, analyses, unsupported,
      extension, resultOverTime, extOverTime } = this.state;

    const dynamicWithColors = analyses.filter(entry => ['failed', 'critical'].includes(entry.name)).map(entry => ({
      ...entry,
      fill: colors.STATUS[entry.name]
    }))

    const analysesStatus = analyses.filter(entry => entry.name !== 'critical');

    return isLoading ? <Loading /> : <Container fluid className={style.dashboardContainer}>
      {/* time windows */}
      <Row>
        <Col className='text-center mb-3 mt-3'>
          <Form inline style={{ justifyContent: "center" }}>
            <FormGroup>
              <Label for="selectTimeWindow" className='mr-2'>Select a time window</Label>
              <Input type="select" name="selectTimeWindow" id="selectTimeWindow" value={timeWindow} onChange={this.updateTimeWindow}>
                {Object.entries(timeWindows).map(tw => <option key={tw[0]} value={tw[0]}>{tw[1]}</option>)}
              </Input>
            </FormGroup>
          </Form>
        </Col>
      </Row>
      {/* sample counter */}
      <Row className={style.rowGraph}>
        <Col sm="3">
          <Card>
            <CardBody>
              <CardTitle className={style.cardTitle}>Total sample</CardTitle>
                <CardText className={style.bigCounter}>
                  <NumericLabel>{total}</NumericLabel>
                </CardText>
            </CardBody>
          </Card>
        </Col>
        <Col sm="3">
          <Card>
            <CardBody>
              <CardTitle className={style.cardTitle}>Current processing</CardTitle>
                <CardText className={`${style.bigCounter} ${style.processing}`}>
                  <NumericLabel>{processing}</NumericLabel>
                </CardText>
            </CardBody>
          </Card>
        </Col>
        <Col sm="3">
          <Card>
            <CardBody>
              <CardTitle className={style.cardTitle}>Current pending</CardTitle>
              <CardText className={`${style.bigCounter} ${style.pending}`}>
                  <NumericLabel>{pending}</NumericLabel>
                </CardText>
            </CardBody>
          </Card>
        </Col>    
        <Col sm="3">
          <Card>
            <CardBody>
              <CardTitle className={style.cardTitle}>Current unsupported</CardTitle>
              <CardText className={`${style.bigCounter} ${style.unsupported}`}>
                  <NumericLabel>{unsupported}</NumericLabel>
                </CardText>
            </CardBody>
          </Card>
        </Col>              
      </Row>
      {/* pies */}
      <Row className={style.rowGraph}>  
        <Col sm="3">
          <Card>
            <CardBody>
              <CardTitle className={style.cardTitle}>Classification Result</CardTitle>
              {classification.length > 0 ? <ResponsiveContainer width='100%' height={200}>
                <PieChart>
                  <Pie
                    data={classification}
                    innerRadius="70%"
                    outerRadius="100%" 
                    dataKey='value'
                    minAngle={5}
                  >
                    {Object.entries(classification).map((entry, idx) => <Cell fill={colors.RESULTS[entry[1].name]} key={idx} />)}
                  </Pie>
                  <Tooltip content={<CustomSingleTooltip />} />
                </PieChart>
              </ResponsiveContainer> : <NoDataAvailable height={200} />}
            </CardBody>
          </Card>
        </Col>
        <Col sm="3">
          <Card>
            <CardBody>
              <CardTitle className={style.cardTitle}>Analysis Status</CardTitle>
              {analysesStatus.length > 0 ? <ResponsiveContainer width='100%' height={200}>
                <PieChart>
                  <Pie
                    data={analysesStatus}
                    innerRadius="70%"
                    outerRadius="100%"
                    dataKey='value'
                    minAngle={5}
                  >
                    {
                      Object.entries(analysesStatus).map((entry, idx) => { return <Cell fill={colors.STATUS[entry[1].name]} key={idx} /> } )
                    }
                  </Pie>
                  <Tooltip content={<CustomSingleTooltip />} />
                </PieChart>
              </ResponsiveContainer> : <NoDataAvailable height={200} />}
            </CardBody>
          </Card>
        </Col>
        <Col sm="3">
          <Card>
            <CardBody>
              <CardTitle className={style.cardTitle}>Failed analysis</CardTitle>
              {dynamicWithColors.length > 0 ? <ResponsiveContainer width='100%' height={200}>
                <RadialBarChart
                  innerRadius="50%"
                  outerRadius="100%"
                  barCategoryGap="5%"
                  barGap="5%"
                  data={dynamicWithColors}
                >
                  <RadialBar minAngle={5} label={{ position: 'insideStart', fill: '#fff' }} clockWise={true} dataKey='value' />
                  <Tooltip content={<CustomSingleTooltip />} />
                </RadialBarChart>
              </ResponsiveContainer> : <NoDataAvailable height={200} />}
            </CardBody>
          </Card>
        </Col>
        <Col sm="3">
          <Card>
            <CardBody>
              <CardTitle className={style.cardTitle}>Upload by file type</CardTitle>
              {extension.length > 0 ? <ResponsiveContainer width='100%' height={200}>
              <PieChart>
                  <Pie
                    data={extension}
                    innerRadius="70%"
                    outerRadius="100%" 
                    fill="#8884d8"
                    dataKey='value'
                    minAngle={5}
                  >
                    {
                      extension.map((entry, idx) =>
                        <Cell key={idx} fill={colors.EXTENSIONS[entry.name] ? colors.EXTENSIONS[entry.name] : colors.DEFAULT_COLOR} />
                      )
                    }
                  </Pie>
                  <Tooltip content={<CustomSingleTooltip />} />
                </PieChart>
              </ResponsiveContainer> : <NoDataAvailable height={200} />}
            </CardBody>
          </Card>
        </Col>
      </Row>
      {/* classification */}
      <Row className={style.rowGraph}>
        <Col>
          <Card>
            <CardBody>
              <CardTitle className={style.cardTitle}>Classification over time</CardTitle>
              {!_.isEmpty(resultOverTime) && resultOverTime.keys.length > 0 && resultOverTime.buckets.length > 0 ? <ResponsiveContainer width='100%' height={300}>
                <ComposedChart
                  data={resultOverTime.buckets}
                  margin={{top: 5, right: 30, left: 20, bottom: 5}}
                  maxBarSize={20}
                >
                  <XAxis height={50} dataKey="date" tick={<CustomizedAxisTick/>}/>
                  <YAxis />
                  <CartesianGrid strokeDasharray="3 3"/>
                  <Tooltip content={<CustomMultiTooltip />} />
                  <Area type='monotone' dataKey='uploaded' fill='#e7e7e7' stroke={colors.RESULTS.unknown}  connectNulls={true} strokeWidth={3} />
                  {
                    resultOverTime.keys.map((entry, idx) => {
                      return <Bar key={idx} type="monotone" dataKey={entry} fill={colors.RESULTS[entry]} />})
                  }
                    
                  </ComposedChart>
              </ResponsiveContainer> : <NoDataAvailable height={300} />}
            </CardBody>
          </Card>
        </Col>
      </Row>
      {/* extensions */}
      <Row className={style.rowGraph}>
        <Col>
          <Card>
            <CardBody>
              <CardTitle className={style.cardTitle}>File type over time</CardTitle>
              {!_.isEmpty(extOverTime) && extOverTime.keys.length > 0 && extOverTime.buckets.length > 0 ? <ResponsiveContainer width='100%' height={300}>
                <LineChart data={extOverTime.buckets} margin={{top: 5, right: 30, left: 20, bottom: 5}}>
                <XAxis height={50} dataKey="date" tick={<CustomizedAxisTick/>}/>
                  <YAxis />
                  <CartesianGrid strokeDasharray="3 3"/>
                  <Tooltip content={<CustomMultiTooltip />} />
                  {
                    extOverTime.keys.map((entry, idx) =>
                      <Line
                        connectNulls={true}
                        key={idx}
                        type="monotone"
                        dataKey={entry}
                        stroke={colors.EXTENSIONS[entry] ? colors.EXTENSIONS[entry] : colors.DEFAULT_COLOR}
                        dot={false}
                        strokeWidth={2}
                      />
                    )
                  }
                  </LineChart>
              </ResponsiveContainer> : <NoDataAvailable height={300} />}
            </CardBody>
          </Card>
        </Col>        
      </Row>
    </Container>
  }
}