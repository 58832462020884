import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle  } from '@fortawesome/free-regular-svg-icons';

import style from './style.scss';

export default class FlagIcon extends React.Component {
  render() {
    const {flag} = this.props;

    return <FontAwesomeIcon
      icon={flag ? faCheckCircle : faTimesCircle}
      className={flag ? style.trueIcon : style.falseIcon}
      />;
  }
}