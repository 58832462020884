import React from 'react';
import style from './style.scss';

export default class CategoryFieldset extends React.Component {
  render() {
    return <fieldset className={style.categoryContainer}>
      <legend>{this.props.title}</legend>
      {this.props.children}
    </fieldset>
  }
}