import React from 'react';
import { Table } from 'reactstrap';

import ReportSection from '../../../../../components/ReportSection';
import PeElement from '../../../../../components/PeElement';
import FlagIcon from '../../../../../components/FlagIcon';
import CodeHighlighter from '../../../../../components/CodeHighlighter';
import style from '../../style.scss';

export default class RtfStatic10 extends React.Component {
  renderSwfList = (swf_list) => {
    return !!swf_list.length && <ReportSection title="Swf list">
    {swf_list.map((swf, idx) => {
      return <Table size="sm" responsive bordered striped key={idx}>
      <tbody>
        <tr>
          <td className={style.title}>Compressed</td>
          <td><FlagIcon flag={swf.compressed} /></td>
        </tr>
        <tr>
          <td className={style.title}>Shellcode</td>
          <td><FlagIcon flag={swf.shellcode} /></td>
        </tr>
        <tr>
          <td className={style.title}>Code</td>
          <td><CodeHighlighter key={idx} code={swf.code} /></td>
        </tr>
        {!!swf.exceptions.length && <tr>
            <td className={style.title}>Exceptions</td>
            <td>{swf.exceptions.map((exception, idx) => {
              return <p key={idx} className="mb-0">{exception}</p>
            })}</td>
          </tr>
        }
      </tbody>
    </Table>
    })}

  </ReportSection>
  }

  render() {
    const {ole_list, pe_list, swf_list } = this.props.data;

    return <>
      {!!ole_list.length && <ReportSection title="Ole list">{ole_list.map((ole, idx) => <PeElement pe={ole} idx={idx} />)}</ReportSection>}
      {!!pe_list.length && <ReportSection title="PE list">{pe_list.map((pe, idx) => <PeElement pe={pe} idx={idx} />)}</ReportSection>}
      {this.renderSwfList(swf_list)}
    </>
  }
} 