import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router'
import { PersistGate } from 'redux-persist/integration/react'

import { persistor, store } from './store';

import { oktaConfig } from '../config/okta';
import { history } from './store/configureStore';

import MainNavBar from './components/MainNavbar/index';
import Loading from './containers/Loading';
import Home from './containers/Home';
import Login from './containers/Login';
import Logout from './containers/Logout';

import Profile from './containers/Profile';
import ProfileApiKey from './containers/Profile/apiKey';
import ProfileSubmissions from './containers/ProfileSubmissions';

import ApiDoc from './containers/ApiDoc';

import SandboxHome from './containers/SandboxHome';
import SandboxReport from './containers/SandboxReport';
import ServerStatus from './containers/ServerStatus';
import UsersList from './containers/UsersList';
import SandboxSamples from './containers/SandboxSamples';

import DashboardStatistics from './containers/Statistics';
import SearchHome from './containers/SearchHome';
import NotFoundView from './containers/NotFoundView';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { checkUserAccount } from './services/users';
import { updateLastCheck } from './actions/auth';
import { lastCheckTimeout } from './constants/index';
import SandboxSubmissions from './containers/SandboxSubmissions';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      authenticated: null
    }

    this.subState = {
      interval: null
    }
  }

  ProtectedRoute = ({ restricted, ...props }) => {
    const { authenticated } = this.state;
    
    if(authenticated === null) {
      return <Loading />
    } else if (authenticated === false) {
      return <Redirect to="/auth/login" />
    } else {
      const { auth } = store.getState();

      if(Date.now() - auth.last_check >=  lastCheckTimeout) {
        store.dispatch(updateLastCheck(Date.now()))
        checkUserAccount();
      }

      if (restricted && !['qa', 'admin'].includes(auth.group_name)) {
        return <Redirect to="/404" />
      } else {
        return <Route {...props} />
      }
    }
  }

  async checkAuthentication() {
    const { auth, _persist } = store.getState();

    if(!_persist.rehydrated) {
      if(!this.subState.interval) {
        this.subState.interval = setInterval(
          () => {
            this.checkAuthentication();
          },
          500);
      }
    } else {
      clearInterval(this.subState.interval);
      
      this.setState({
        authenticated: !!auth.token
      }, () => this.forceUpdate());
    }
  }

  async componentDidMount() {
    this.checkAuthentication();
  }

  render() {
    return (
      <Provider store={store}>
        <PersistGate
          loading={null}
          persistor={persistor}
          onBeforeLift={this.onBeforeLift}
        >
        <ConnectedRouter history={history}>
          <>
            <MainNavBar />
            <main role="main">
              <Switch>
                <this.ProtectedRoute path='/' exact component={ Home } />

                <this.ProtectedRoute path='/profile/' exact component={ Profile } />
                <this.ProtectedRoute path='/profile/apikey/' exact component={ ProfileApiKey } />
                <this.ProtectedRoute path='/profile/submissions/' exact component={ ProfileSubmissions } />

                <this.ProtectedRoute path='/sandbox/' exact component={ SandboxHome } />
                <this.ProtectedRoute path='/sandbox/report/:hash([0-9a-fA-F]{32})/' exact component={ SandboxReport } />
                <this.ProtectedRoute path='/sandbox/report/:hash([0-9a-fA-F]{40})/' exact component={ SandboxReport } />
                <this.ProtectedRoute path='/sandbox/report/:hash([0-9a-fA-F]{64})/' exact component={ SandboxReport } />
                <this.ProtectedRoute path='/sandbox/report/:hash([0-9a-fA-F]{128})/' exact component={ SandboxReport } />
                
                <this.ProtectedRoute path='/dashboard' exact component={ DashboardStatistics } />

                <this.ProtectedRoute path='/admin/users/:userId([0-9]+)?' restricted={true} exact component={ UsersList } />
                <this.ProtectedRoute path='/admin/samples/:userId([0-9]+)?' restricted={true} exact component={ SandboxSamples } />
                <this.ProtectedRoute path='/admin/submissions/:userId([0-9]+)?' restricted={true} exact component={ SandboxSubmissions } />
                <this.ProtectedRoute path='/admin/servers/' restricted={true} exact component={ ServerStatus } />
                
                <this.ProtectedRoute path='/search/:s?' exact component={ SearchHome } />
                
                <Route path='/auth/login' exact component={() => { window.location = oktaConfig.backendEntryPoint.login; return null; } } />
                <Route path='/auth/login/:token([0-9a-fA-F]{96})' component={ Login } />
                <Route path='/auth/logout/:success?' exact component={Logout} />

                <Route path='/apidoc/' exact component={ ApiDoc } />

                <Route path="404" component={ NotFoundView }/>
                <Route component={ NotFoundView } />
              </Switch>
            </main>
            <ToastContainer
              autoClose={5000}
              newestOnTop={true}
            />
          </>
        </ConnectedRouter>
        </PersistGate>
      </Provider>
    );
  }
}

export default App;