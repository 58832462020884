import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';

import ToolbarIcon from '../ToolbarIcon';
import PlatformSwitch from '../PlatformSwitch';
import { analysisCompleted } from '../../constants/api';
import { adminGroup, qaGroup } from '../../constants/users';
import style from './style.scss';

import {
  faExpandArrowsAlt,
  // faBrain,
  // faComments,
  faNetworkWired,
  faStream,
  // faFingerprint,
  faInfoCircle,
  // faProjectDiagram,
  faLaptopMedical,
  faFileDownload
} from '@fortawesome/free-solid-svg-icons';

import { faFileAlt, faImages } from '@fortawesome/free-regular-svg-icons';

let staticSections = [
  {
    label: 'Diagnostics',
    section: 'diagnostics',
    icon: faLaptopMedical
  },
  {
    label: 'Static Analysis',
    section: '_static',
    icon: faStream
  },
  // {
  //   label: 'Threat Intelligence',
  //   section: 'intelligence',
  //   icon: faBrain
  // },
  // {
  //   label: 'Signature',
  //   section: 'signature',
  //   icon: faFingerprint
  // },
  // {
  //   label: 'Comments',
  //   section: 'comments',
  //   icon: faComments
  // },
];

const dynamicSections = [
  // {
  //   label: 'Graph',
  //   section: 'graph',
  //   icon: faProjectDiagram,
  //  public: true
  // },
  {
    label: 'Dynamic Analysis',
    section: 'dynamic',
    icon: faExpandArrowsAlt,
    public: true
  },
  {
    label: 'Network Activity',
    section: 'network',
    icon: faNetworkWired,
    public: true
  },
  {
    label: 'Dropped Files',
    section: 'dropped',
    icon: faFileDownload,
    public: true
  },
  {
    label: 'Screenshots',
    section: 'screenshot',
    icon: faImages,
    public: true
  },
  {
    label: 'Logs',
    section: 'logs',
    icon: faFileAlt,
    public: false
  }
];

class Toolbar extends Component {
  render() {
    const {
      auth,
      currentPlatform,
      platforms,
      onIconClick,
      onPlatformClick,
      section: currentSection,
      analysisCompleted: isAnalysisCompleted } = this.props;
    
    let staticSectionsToPrint = [];
    let dynamicSectionsToPrint = [];

    if(analysisCompleted !== isAnalysisCompleted) {
      staticSectionsToPrint = _.filter(staticSections, s => s.section !== '_static');
    } else {
      staticSectionsToPrint = staticSections;
    }

    if(![qaGroup, adminGroup].includes(auth.group_name)) {
      dynamicSectionsToPrint = _.filter(dynamicSections, s => s.public);
    } else {
      dynamicSectionsToPrint = dynamicSections;
    }

    return (
        <div className={style.toolbar}>
          <ToolbarIcon
            label='Overview'
            icon={faInfoCircle}
            section='overview'
            onClick={onIconClick}
            selected={currentSection === 'overview'}
          />

          <hr />
          
          {staticSectionsToPrint.map(s => {
            return <ToolbarIcon
              key={s.label}
              label={s.label}
              icon={s.icon}
              section={s.section}
              onClick={onIconClick}
              selected={currentSection === s.section}
              />
          })}

          {analysisCompleted === isAnalysisCompleted && <>
            <hr />
            <PlatformSwitch
              platforms={platforms}
              currentPlatform={currentPlatform}
              onPlatformClick={onPlatformClick}
            />
            {dynamicSectionsToPrint.map(s => {
              return <ToolbarIcon
                key={s.label}
                label={s.label}
                icon={s.icon}
                section={s.section}
                onClick={onIconClick}
                selected={currentSection === s.section}
              />
            })}
          </>}
        </div>
    );
  }
};


export default connect(
  state => ({
    auth: state.auth
  })
)(Toolbar);